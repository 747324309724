import { LOGOUT,
  ADD_SIGNUP_SUCCESS, ADD_SIGNUP_STARTED, ADD_SIGNUP_FAILURE, SET_TOKEN, SET_USER, SET_QUESTION_N_ANSWERS
} from './user.types';

import { userService } from '../_services/userService';
import { sendToBucket } from '../_services/uploader';

export const signup = (merchantInfo) => {
    return dispatch => {
        dispatch(addSignupStarted());
        return userService.signup(merchantInfo)
        .then(res => {
            dispatch(addSignupSuccess(res.data));
            // console.log('Data then:', res);
            return res;
        }, err => {
            dispatch(addSignupFailure(err.response.data));
            // console.log('Data err', err.response.data);
            return err.response.data;
        });
    };    
};

export const verification = (phone) => {
    return dispatch => {
        // dispatch(addSignupStarted());
        return userService.verify(phone)
        .then(res => {
            // dispatch(addSignupSuccess(res.data));
            // console.log('Data then:', res);
            return res;
        }, err => {
            // dispatch(addSignupFailure(err.response.data));
            // console.log('Data err', err.response.data);
            return err.response.data;
        });
    };    
};

export const verify_otp = (code, phone) => {
    return dispatch => {
        // dispatch(addSignupStarted());
        return userService.verify_otp(code, phone)
        .then(res => {
            // dispatch(addSignupSuccess(res.data));
            // console.log('Data then:', res);
            return res;
        }, err => {
            // dispatch(addSignupFailure(err.response.data));
            // console.log('Data err', err.response.data);
            return err.response.data;
        });
    };    
};

export const forgotPassword = (data) => {
  return dispatch => {
    return userService.forgot_password(data)
    .then(res => {
      return res;
    }, err => {
      console.log('error:', err.response);
      return err.response;
    });
  };    
};

export const reset_password = (data) => {
  return dispatch => {
    return userService.reset_password(data)
    .then(res => {
      return res;
    }, err => {
      console.log('error:', err.response);
      return err.response;
    });
  };    
};

export const verify_agent = (code) => {
  return dispatch => {
    return userService.verify_agent(code)
    .then(res => {
      // console.log('res:', res);
      return res;
    }, err => {
      console.log('error:', err.response);
      return err;
    });
  };    
};

export const get_logo = (code) => {
  return dispatch => {
    return userService.getLogo(code)
    .then(response => {
      // console.log('response:', response);
      return response;
    }, error => {
      console.log('error:', error);
      return error;
    })
  };    
};

export const get_profile_pic = (code) => {
  return dispatch => {
    return userService.getProfilePic(code)
    .then(response => {
      return response;
    }, error => {
      console.log('error:', error);
      return error;
    })
  };    
};

export const resent_email_code = () => {
  return dispatch => {
    return userService.resend_verification_email()
    .then(response => {
      return response;
    }, error => {
      console.log('error:', error);
      return error;
    })
  };    
};

export const upload_pic = (type, file, code) => {
    return () => {
        return sendToBucket(file)
        .then(res => {
            if (type === 'logo') {
              userService.upload_profile_logo({company_code: code, logo_url: res.Location})
              .then(response => {
                console.log('response:', response);
                return response;
              }, error => {
                console.log('error:', error);
                return error;
              })
            }

            return res;
        }, err => {
            console.log('Data err', err);
            return err;
        });
    };    
};

export const signin = (forname, password) => {
    return dispatch => {
        dispatch(addSignupStarted());
        
        return userService.login(forname, password)
        .then(res => {
            dispatch(setToken(res.data.data.token));
            dispatch(setUserDetails(res.data.data));
            localStorage.setItem('token', res.data.data.token);
            localStorage.setItem('loginCount', res.data.data.login_count);

            return res;
        }, err => {
          // console.log('err', err.response.data);
            // dispatch(addSignupFailure(err.response.data));
            if (err.response !== undefined)  return err.response.data;
            else return err;
        });   
    };
};

export const userDetails = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      userService.getUserDetails()
      .then(response => {
        // console.log('User Details:', response.data.data.user_details);
        // dispatch(addSignupSuccess('DATA'));
        dispatch(setUserDetails(response.data.data.user_details));
        // return response;
        resolve(response);
      })
      .catch(error => {
        console.log('User Details:', error.response?.data)
        // return error.response.data;
        reject(error.response?.data);
      })
  }); 
  }
};

export const updateProfile = (info, CODE) => {
  return dispatch => {
    return userService.updateProfile(info, CODE)
    .then(response => {
      // resolve(response);
      return response;
    }, err => {
      // reject(error);
      if (err.response !== undefined)  return err.response.data;
      else return err;
    })
    // return new Promise((resolve, reject) => {
    // }); 
  }
};
export const requestOTP = (params={}, method) => {
  return () => {
    return userService.request_otp(params, method)
    .then(response => {
      return response;
    }, err => {
      console.log('Error on then:', err)
      if (err.response !== undefined)  return err.response.data;
      else return err;
    })
    .catch((error) => {
      console.log('Caught Error:', error)
      return error;
    })
  }
};

export const changePassword = (info, CODE) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      userService.changePassword(info, CODE)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error.response);
      })
  }); 
  }
};

export const get_Ad_Images = (params) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      userService.getAdImages(params)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        // console.log('User Details:', error.response.data)
        reject(error);
      })
  }); 
  }
}

export const logout = () => {
  return dispatch => {
    return userService.logout()
    .then(res => {
      dispatch({
        type: LOGOUT
      });
      localStorage.clear();
      return res;
    }, err => {
      return err.response.data;
    });
};    
}

export const setToken = token => ({
  type: SET_TOKEN,
  payload: token
});

const setUserDetails = user => ({
  type: SET_USER,
  payload: user
})

const addSignupSuccess = signup => ({
    type: ADD_SIGNUP_SUCCESS,
    payload: signup
});
  
const addSignupStarted = () => ({
  type: ADD_SIGNUP_STARTED
});

const addSignupFailure = error => ({
  type: ADD_SIGNUP_FAILURE,
  payload: {
    error
  }
});

export const get_questions = (answers) => {
  return dispatch => {
      return userService.questions(answers)
      .then(res => {
        return res;
      }, err => {
          dispatch(addSignupFailure(err.response.data));
          return err.response.data;
      });
  };    
};

export const save_user_answers = (answers) => {
  return dispatch => {
      return userService.save_answers(answers)
      .then(res => {
        return res;
      }, err => {
          dispatch(addSignupFailure(err.response.data));
          return err.response.data;
      });
  };    
};

export const setAnswers = qna => ({
  type: SET_QUESTION_N_ANSWERS,
  payload: qna
})

import React from "react";
import { Skeleton } from "antd";
import Slider from "react-slick";
import SavedCard from "./TemplateCard";

const CardList = ({
  loadingCards,
  cards,
  loading_cards,
  settings,
  selectable=false,
  checked=false,
  disabled=false,
  getCards=()=>{}
}) => {

  const handleCheck = (item, check) => {
    getCards(item.id, check);
  };
  
  return (
    <>
      {!loadingCards ? (
        <Slider {...settings}>
          {cards?.map((card, index) => (
            <SavedCard 
              key={index} 
              card={card} 
              custom="w-40 h-24"
              selectable={selectable}
              checked={checked}
              disabled={disabled}
              handleCheck={handleCheck}
            />
          ))}
        </Slider>
      ) : (
        <div className="flex items-center justify-between">
          {loading_cards.map((_, index) => (
            <Skeleton.Avatar
              shape="square"
              loading={true}
              active
              className="w-40 h-24 rounded"
              key={index}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default CardList;

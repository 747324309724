import { combineReducers } from 'redux';
import userReducer from '../store/user.reducer';
import cardsReducer from '../store/cards/cards.reducer';
import merchantReducer from '../store/merchants/reducer';
import reportsReducer from '../store/reports/reducer';
import dashboardReducer from '../store/dashboard/reducer';
import generalReducer from '../store/general/reducer';

const rootReducer = combineReducers({
    // Add more store here
    user: userReducer,
    general: generalReducer,
    cards: cardsReducer,
    merchants: merchantReducer,
    reports: reportsReducer,
    dashboard: dashboardReducer
});

export default rootReducer;
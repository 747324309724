import React, { useState, useEffect } from 'react'
import { Row, Col, Card, Skeleton, Progress } from 'antd';

import GiftCard from '../components/gift_card';
import pkg from '../assets/images/package.png';

// Graph
import SalesOverview from '../components/pieChart';

const gridStyle = {
    width: '100%',
    textAlign: 'center',
};

export default function Sales () {
    const [loading, setloading] = useState(true);

    useEffect(() => {
      setTimeout(() => {
        setloading(false)
      }, 2000);
    }, [loading])
  
    // Dummy cards
    const cards = [
      {
        id: '104344',
        amount: '25',
        img: pkg
      },
      {
        id: '83006',
        amount: '50',
        img: pkg
      },
      {
        id: '900',
        amount: '100',
        img: pkg
      },
      {
        id: '42937',
        amount: '150',
        img: pkg
      },
      {
        id: '94211',
        amount: '200',
        img: pkg
      },
      {
        id: '773884',
        amount: '250',
        img: pkg
      },
    ]
  
    const info = [
      {
        title: 'total sales',
        value: '350, 897',
        percentage: '3.48%',
        period: 'This month'
      },
      {
        title: 'New User',
        value: '350, 897',
        percentage: '3.48%',
        period: 'This month'
      },
      {
        title: 'In-Store Card Use',
        value: '350, 897',
        percentage: '3.48%',
        period: 'This month'
      },
      {
        title: 'Cash-Out',
        value: '350, 897',
        percentage: '3.48%',
        period: 'This month'
      },
    ];

const progressInfo = [
    {
        background: 'bg-red-500',
        value: 98
    },
    {
        background: 'bg-orange-500',
        value: 75
    },
    {
        background: 'bg-pink-500',
        value: 50
    },
    {
        background: 'bg-gray-500',
        value: 81
    },
    {
        background: 'bg-green-500',
        value: 90
    },
    {
        background: 'bg-purple-500',
        value: 43
    },
]

    return (
        <>
      {/* Gift Cards */}
      <Row className="mx-0 pb-4">
        <Col span={24} className="px-4 py-3 bg-cards rounded-large">
          <h1 className="uppercase font-bold mb-2 text-xs">Gift Cards sold</h1>
          <div className="flex flex-wrap">
            {cards.map((card, index) => (
              <GiftCard info={card} key={index} />
            ))}
          </div>
        </Col>
      </Row>

      {/* Dashboard Data  - Component this very soon*/}
      <Row gutter={16} className="dashboard mx-0">
        {info.map((item, index) => (
          <Col span={6} key={index} className="p-0 pr-2">
            <Card className="rounded-lg">
              <Skeleton paragraph={{ rows: 1 }} loading={loading} active>
                <Card.Grid style={gridStyle} className="p-4">
                  <div className="flex justify-between text-xs">
                    <div className="pb-4 uppercase leading-none text-left">
                      <h2 className="text-gray-500">{item.title}</h2>
                      <div className="text-2xl font-bold">{item.value}</div>
                    </div>
                    <div className="pt-4 text-gray-400 text-right leading-tight">
                      <div className="text-base">{item.percentage}</div>
                      <div>{item.period}</div>
                    </div>
                  </div>
                </Card.Grid>
              </Skeleton>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Graph */}
      <Row className="mx-0 my-8">
        <Col span={8} className="pr-4">
          <div className="flex flex-col p-3 bg-white rounded-lg">
            <div className="leading-none text-left border-b border-gray-200 w-full">
              <h2 className="text-gray-500">SALES OVERVIEW</h2>
              <SalesOverview width={316} />
            </div>
          </div>
        </Col>
        <Col span={16}>
            <div className="mb-2 bg-white px-8 py-6 rounded">
              <h1 className="uppercase font-bold text-sm">Monitor Card Sales</h1>
                <div className="mt-4">
                    {progressInfo.map((item, index) => (
                      <div className="flex items-center justify-center my-3" key={index}>
                        <div className={`w-8 h-8 mr-4 rounded ${item.background }`} />
                        <Progress percent={item.value} strokeWidth={10} strokeColor={{ '0%': '#108ee9','100%': '#87d068' }} />
                      </div>
                    ))}
                </div>
            </div>              
        </Col>
      </Row>
    </>
    )
}
import { SET_MERCHANTS, SET_FILTERS, ADD_SETUP_STARTED, ADD_SETUP_COMPLETED } from './types';
import { merchantService } from '../../_services/merchantService';

export const Initiate_sign_up = (info) => {
    return dispatch => {
        dispatch(addSetUpStarted())
        return merchantService.Initiate_sign_up(info)
        .then(res => {
            dispatch(completedSetUp())
            return res;
        }, err => {
            console.log('err', err);
            dispatch(completedSetUp())
            return err;
        });
    };
};
export const Edit_Merchant = (info) => {
    return dispatch => {
        dispatch(addSetUpStarted())
        return merchantService.EditMerchant(info)
        .then(res => {
            dispatch(completedSetUp())
            return res;
        }, err => {
            console.log('err', err);
            dispatch(completedSetUp())
            return err;
        });
    };
};
export const Delete_Merchant = (id) => {
    return dispatch => {
        dispatch(addSetUpStarted())
        return merchantService.DeleteMerchant(id)
        .then(res => {
            dispatch(completedSetUp())
            return res;
        }, err => {
            console.log('err', err);
            dispatch(completedSetUp())
            return err;
        });
    };
};

export const Complete_sign_up = (info) => {
    return dispatch => {
        return merchantService.Complete_sign_up(info)
        .then(res => {
            console.log('Completed Card Payload:', res);
            return res;
        }, err => {
          console.log('err', err);
            // dispatch(addSignupFailure(err.response.data));
            return err;
        });   
    };
};

export const getMerchants = (filters={}) => {
    return dispatch => {
        return merchantService.getMerchants(filters)
        .then(res => {
            // console.log('Merchants:', res.data);
            dispatch({
                type: SET_MERCHANTS,
                payload: res.data.data
            });
            return res.data.data;
        }, err => {
          console.log('err', err);
            return err;
        });   
    };
};
export const change_merchant_status = (info, type) => {
    return dispatch => {
        return merchantService.changeMerchantStatus(info, type)
        .then(res => {
            return res;
        }, err => {
          console.log('err', err);
            return err;
        });   
    };
};

export const setFilters = (type, data) => {    
    return {
        type: SET_FILTERS,
        payload: { type, data }
    }
};

const addSetUpStarted = () => ({
    type: ADD_SETUP_STARTED
});

const completedSetUp = () => ({
    type: ADD_SETUP_COMPLETED
});

import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Row, Col, Input, AutoComplete, message } from 'antd';
import { SearchOutlined, UserOutlined } from '@ant-design/icons';
import primeLogo from '../assets/images/logo-white.png';
import logoutImg from '../assets/images/logout.png';
import { useDispatch } from 'react-redux';
import { setToken, logout } from "../store/user.actions";

const Head = () => {
  const [time, setTime] = useState('');
  const dispatch = useDispatch();
  const renderTitle = (title) => (
    <span>
      {title}
      <a style={{ float: 'right' }} href="https://www.google.com/search?q=antd" target="_blank" rel="noopener noreferrer">
        more
      </a>
    </span>
  );

  const renderItem = (title, count) => ({
    value: title,
    label: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {title}
        <span>
          <UserOutlined /> {count}
        </span>
      </div>
    ),
  })

  const options = [
    {
      label: renderTitle('Branches'),
      options: [
        renderItem('Lapaz', 100), 
        renderItem('Achimota', 54)
      ]
    }
  ];

  const prefix = (
    <SearchOutlined
      className="text-sm pr-3"
      style={{
        color: '#1F2C4F'
      }}
    />
  );

  const history = useHistory();

  const signout = () => {
    dispatch(logout())
    dispatch(setToken(""));
    message.success('Logged Out');
    history.push('/');
  }

  useEffect(() => {
    const today = (new Date()).toString().split(' ').splice(1,3).join(' ');
    setTime(today)
  }, [])

  return (
    <>
      <Row gutter={16} className="mx-0 h-full">
        <Col span={3}>
          <Link to='/'>
            <img src={primeLogo} className="h-20 mx-auto" alt="" />
          </Link>
        </Col>
        <Col span={18} className="flex flex-col justify-center items-center leading-normal">
          {/* <AutoComplete
            dropdownClassName="search"
            dropdownMatchSelectWidth={500}
            className="sm:w-2/5"
            options={options}
          >
            <Input
              className="rounded-full"
              prefix={prefix}
              size="large" 
              placeholder="Search" />
          </AutoComplete> */}
          <h2 className="text-secondary text-lg m-0 tracking-widest leading-snug">
            FRANCHISE MANAGER DASHBOARD
          </h2>
        </Col>
        <Col span={3} className="flex flex-col justify-center items-end text-white">
          <div className="flex items-center pb-2 cursor-pointer" onClick={() => { signout() }}>
            <img src={logoutImg} className="w-5 img--sec" alt="logout" />
            {/* <LogoutOutlined className="text-2xl" /> */}
            <span className="capitalize leading-normal pl-2 text-secondary">Log out</span>
          </div>
          <span className="leading-snug font-normal">{time}</span>
        </Col>
      </Row>
    </>
  )
}

export default Head;

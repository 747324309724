import React, { useState } from 'react';
import { EventEmitter } from '../../events.js';
import uploadIcon from '../../assets/images/upload.png';
import { sendToBucket } from '../../_services/uploader';

import { LoadingOutlined } from '@ant-design/icons';

import { Card, Upload } from 'antd';
const { Dragger } = Upload;

const Uploader = ({ 
    children,
    showButton=true,
    disabled = false, 
    uploadImage=()=>{}, 
    classStyle,
    uploadText="Drag and Drop file",
    bg="", 
    isThumbnail = false,
    thumbBack 
}) => {
    const [loading, setloading] = useState(false);

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            callback(reader.result)
        });
        reader.readAsDataURL(img);
    }; 

    const props = {
        name: 'file',
        multiple: false,
        action: null,
        showUploadList: false,
        beforeUpload: file => {
            setloading(true);
            getBase64(file, (imageUrl) => {
                file.preview = imageUrl;

                sendToBucket(file)
                .then((response) => {
                    uploadImage(response.Location)
                    setloading(false);
                })
                .catch(err => {
                    setloading(false);
                    console.log('Error:', err);
                })
            });       
            
            return false;
        },        
        onPreview: file => {
            if (file.preview) return true
            else return false;         
        }        
    };
    
    return (
        <>
            {isThumbnail ? 
                <Dragger {...props} disabled={disabled || false} onChange={uploadImage}>
                    <div className={`${thumbBack} w-full h-full flex--basic text-black border border-gray-500`}>
                        Banner image thumbnail
                    </div>
                </Dragger>
            :
                <Card className={`${bg ? '' : 'w-full'} flex--basic relative rounded-large shadow-md hover:shadow-lg bg-white ${classStyle}`}
                    style={{background: `url(${bg ? bg: ''})`}}
                >
                    <Dragger {...props} disabled={disabled || false} onChange={uploadImage}>
                        {!bg && 
                            <h1 className="font-bold text-primary text-base ant-upload-text mb-4 p-1 rounded">
                                {uploadText}
                            </h1>
                        }
                        {showButton && 
                            <button className={`${disabled ? 'opacity-50 cursor-not-allowed' : ''} ${bg ? 'opacity-25 hover:opacity-75' : ''} font-bold rounded-full bg-primary text-white px-10 py-2 outline-none`}>
                                BROWSE
                            </button>
                        }
                        {loading &&
                            <div className="pt-1">
                                <LoadingOutlined style={{ fontSize: 40 }} spin />
                            </div>
                        }
                        {children}
                    </Dragger>
                </Card>
            }
        </>
    );
};

export default Uploader;

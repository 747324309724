import React from 'react';

const Thumbnail = ({ background, bgType="" }) => {
    return (
        <div className={`${background} ${bgType && 'bg-cover bg-no-repeat rounded object-cover h-16'} w-full py-2 text-center flex items-center text-xs border border-gray-500 mb-2`}
            style={{background: `url(${bgType})`}}
        >
            <span className={`${bgType && 'hidden'}`}>Banner image thumbnail</span>
        </div>             
    )
};

export default Thumbnail;

import React from 'react';
import { Col, Card } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

const SelectTemplate = ({ publishedList=0 }) => {
    return (
        <>
            <Col span={24} className="flex justify-center">
                <Card className="rounded-lg border border-primary w-2/5 px-10 py-20 my-16">
                    <div className="flex flex-col items-center w-full">
                        <h2 className="text-3xl font-bold text-primary">Congratulations</h2>
                        <CheckCircleOutlined className="text-5xl py-4" />
                        <div className="font-semibold text-base">
                            <span>{publishedList  < 1 ? 'An' : publishedList}</span>
                            <span className="pl-2">e-Gift Card(s) published</span>
                        </div>
                    </div>
                </Card>
            </Col>
        </>        
    )
};

export default SelectTemplate;
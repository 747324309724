import { config } from '../redux/config';
import { authHeader } from '../_helpers/auth-header';
import axios from 'axios';
import utils from '../utils/services'

export const generalService = {
    get_resources,get_fund_request_list,show_single_request,cancel_fund_request,
    request_fund_request, add_bank_account,get_bank_details, 
    get_supported_banks, get_bank_branch, get_supported_momo_networks, get_coupons,
    add_coupon, remove_coupon, remove_coupon_code
};

function get_resources(filters) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let query = utils.createQueryFromObject(filters);
    let url = `${config.apiUrl}/merchants/merchant-resources`;
    if (query) url = url + `?${query}`

    return new Promise((resolve, reject) => {
        axios(url, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}
function get_fund_request_list(filters) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let query = utils.createQueryFromObject(filters);
    let url = `${config.apiUrl}/merchants/fund-requests?`;
    if (query) url = url + `${query}`

    return new Promise((resolve, reject) => {
        axios(url, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}
function get_bank_details() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    // let query = utils.createQueryFromObject();
    let url = `${config.apiUrl}/merchants/bank-accounts/current`;
    // if (query) url = url + `${query}`

    return new Promise((resolve, reject) => {
        axios(url, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}
function get_bank_branch(bank) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    // let query = utils.createQueryFromObject();
    let url = `${config.apiUrl}/merchants/bank-accounts/supported-banks/branches?bank=${bank}`
    // if (query) url = url + `${query}`

    return new Promise((resolve, reject) => {
        axios(url, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}
function get_supported_banks() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    // let query = utils.createQueryFromObject();
    let url = `${config.apiUrl}/merchants/bank-accounts/supported-banks`;
    // if (query) url = url + `${query}`

    return new Promise((resolve, reject) => {
        axios(url, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}
function get_supported_momo_networks() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    // let query = utils.createQueryFromObject();
    let url = `${config.apiUrl}/merchants/bank-accounts/supported-momo-providers`;
    // if (query) url = url + `${query}`

    return new Promise((resolve, reject) => {
        axios(url, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}
function show_single_request(request_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let url = `${config.apiUrl}/fund-requests/${request_id}`;

    return new Promise((resolve, reject) => {
        axios(url, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}
function cancel_fund_request(request_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    let url = `${config.apiUrl}/fund-requests/${request_id}/cancel`;

    return new Promise((resolve, reject) => {
        axios(url, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}
function request_fund_request(amount) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        data: amount
    };

    let url = `${config.apiUrl}/merchants/fund-requests`;

    return new Promise((resolve, reject) => {
        axios(url, requestOptions)
        .then(res => {
            console.log('Response', res)
            resolve(res);
        })
        .catch(err => {
            console.log('Rejected:', err.response.data)
            reject(err.response ? err.response.data : err);
        })
    });
}
function add_bank_account(amount) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        data: amount
    };

    let url = `${config.apiUrl}/merchants/bank-accounts/current?`;

    return new Promise((resolve, reject) => {
        axios(url, requestOptions)
        .then(res => {
            console.log('Response', res)
            resolve(res);
        })
        .catch(err => {
            console.log('Rejected:', err.response.data)
            reject(err.response ? err.response.data : err);
        })
    });
}
function get_coupons(id="") {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let url = "";

    if (id) url = `${config.apiUrl}/managers/coupons/tag/${id}`;
    else url = `${config.apiUrl}/managers/coupons/list`;

    return new Promise((resolve, reject) => {
        axios(url, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            console.log('Error:', err);
            reject(err.response ? err?.response?.data : err);
        })
    });
}
function add_coupon(params, type="Add", id) {
    console.log('Params:', params);

    const requestOptions = {
        method: type === 'Add' ? 'POST' : 'PUT',
        headers: authHeader(),
        data: params
    };

    let url = type === 'Add' ? `${config.apiUrl}/managers/coupons?` 
                        : `${config.apiUrl}/managers/coupons/tag/${id}`;

    return new Promise((resolve, reject) => {
        axios(url, requestOptions)
        .then(res => {
            console.log('Response', res)
            resolve(res);
        })
        .catch(err => {
            console.log('Rejected:', err?.response?.data)
            reject(err.response ? err?.response?.data : err);
        })
    });
}
function remove_coupon(tag) {
    const requestOptions = {
        method: "DELETE",
        headers: authHeader()
    };

    let url = `${config.apiUrl}/managers/coupons/tag/${tag}?`;

    return new Promise((resolve, reject) => {
        axios(url, requestOptions)
        .then(res => {
            console.log('Response', res)
            resolve(res);
        })
        .catch(err => {
            console.log('Rejected:', err?.response?.data)
            reject(err.response ? err?.response?.data : err);
        })
    });
}
function remove_coupon_code(tag="", params={}) {
    const requestOptions = {
        method: "DELETE",
        headers: authHeader(),
        // params
    };

    let query = utils.createQueryFromObject(params);
    let url = `${config.apiUrl}/managers/coupons/tag_codes/${tag}`;
    if (query) url = url + `?${query}`    

    return new Promise((resolve, reject) => {
        axios(url, requestOptions)
        .then(res => {
            console.log('Response', res)
            resolve(res);
        })
        .catch(err => {
            console.log('Rejected:', err?.response?.data)
            reject(err.response ? err?.response?.data : err);
        })
    });
}

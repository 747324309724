import React, { useState } from 'react';
import { motion } from "framer-motion"
import { Col, Card, Divider, Skeleton, Image } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
// import { EventEmitter } from '../../events.js';

// Components
import CustomUpload from "../../components/misc/customUpload"

import { connect, useDispatch } from "react-redux";
import { setSetlectedTemplate, getTemplates, setTemplateInfo } from "../../store/cards/cards.actions";

const SelectTemplate = (props) => {
    const dispatch = useDispatch();
    // const [loading, setloading] = useState(true);

    const markTemplate = (template, category) => {
        props.setSetlectedTemplate(category, template)
        if (category.cat_id) {
            dispatch(setTemplateInfo('card_category_id', category.cat_id))
        }
        
        // setTimeout(() => {
        //     window.scrollTo(0,9999);
        // }, 1000);
    };

    return (
        <>
            <Col span={24} className="pt-4">
                <Card className="rounded-large shadow-lg">
                    <h1 className="text-xl text-primary font-bold text-center">My eGift Cards Libary</h1>

                    <div className="flex flex-col w-full">
                        <>
                            <Divider orientation="left">Custom</Divider>   
                            <CustomUpload 
                                markTemplate={markTemplate}
                            />                                    
                        </>

                        {props.templates?.map((template, index) => (
                            <div key={index}>
                                {(index > 0 && template?.gallery?.length > 0) && 
                                    <>
                                        <Divider orientation="left">{template.category}</Divider>

                                        <div className="flex flex-wrap w-full">
                                            {template.gallery.map((temp, index) => (
                                                <div 
                                                    className={`${temp.selected ? 'bg-gray': ''} w-1/3 cursor-pointer relative`} 
                                                    onClick={() => {markTemplate(temp, template)}} 
                                                    style={{
                                                        // height: '15rem'
                                                    }} 
                                                    key={index}
                                                >
                                                    {temp.selected && <CheckCircleTwoTone twoToneColor="#88F270" className="absolute right-0 top-0 p-5 text-4xl text-white" style={{zIndex: '8'}} />}
                                                    
                                                    <motion.div
                                                        whileHover={{ scale: 1.05, transition: { duration: .3 } }} 
                                                        className="p-4 bg-transparent" 
                                                        style={{width: '100%', height: 'inherit'}}
                                                    >
                                                        <Image
                                                            preview={false}
                                                            className="w-full shadow-lg hover:shadow-xl rounded-lg"
                                                            src={temp.image}
                                                            placeholder={
                                                                <Skeleton.Avatar className="w-full h-full" active={true} size="large" shape="square" />
                                                            }
                                                        />                                                                                                  
                                                    </motion.div>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                }
                            </div>
                        )).reverse()}
                    </div>
                </Card>
            </Col>
        </>        
    )
};

const mapStateToProps = state => {
    return {
      templates: state.cards.templates,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setSetlectedTemplate: (template, category) => dispatch(setSetlectedTemplate(template, category)),
        getTemplates: () => dispatch(getTemplates())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectTemplate);

import { config } from '../redux/config';
import { authHeader } from '../_helpers/auth-header';
import axios from 'axios';
import utils from '../utils/services'

export const merchantService = {
    Initiate_sign_up,
    Complete_sign_up,
    EditMerchant,
    DeleteMerchant,
    getMerchants,
    changeMerchantStatus,
    approvedMerchant
};

function Initiate_sign_up(info) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        data: info 
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/managers/merchants/initiate-signup`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            console.log('Rejected:', err.response?.data)
            reject(err.response ? err.response?.data : err);
        })
    });
}
function EditMerchant(info) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        data: info 
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/managers/merchants/${info.id}`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            console.log('Rejected:', err.response.data)
            reject(err.response ? err.response.data : err);
        })
    });
}
function DeleteMerchant(ID) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/managers/merchants/${ID}`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            console.log('Rejected:', err.response.data)
            reject(err.response ? err.response.data : err);
        })
    });
}
function Complete_sign_up(info) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        data: info 
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/managers/merchants/complete-signup?include_single_merchant_tests`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}
function changeMerchantStatus(info, type) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        data: info 
    };

    let url = `${config.apiUrl}/managers/merchants/${info.id}`;

    switch (type) {
        case 'suspend':
            url = url +'/suspend'
            break;
        case 'activate':
            url = url +'/approve'
            break;
        case 'review':
            url = url +'/review'
            break;
    
        default:
            break;
    }

    return new Promise((resolve, reject) => {
        axios(url, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}
function approvedMerchant(info) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        data: info 
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/managers/merchants/${info.id}/approve`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}

function getMerchants(filters={}) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let query;

    let url = `${config.apiUrl}/managers/merchants`;
    if (utils.present(filters)) {
        console.log('Filters:', filters);
        query = utils.createQueryFromObject(filters);
    }
    if (query) url = url + `?${query}`     

    return new Promise((resolve, reject) => {
        axios(url, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}

import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form, Input, Select, Button, message, DatePicker, Tooltip } from 'antd';
import { RadiusUprightOutlined } from "@ant-design/icons";
import backIcon from '../assets/images/back.svg';

// Redux Actions
import { useDispatch } from 'react-redux';
import { signup, verify_agent, get_questions, get_Ad_Images } from "../store/user.actions";

// Components
import WelcomePages from "../components/WelcomePage";
import PickLocation from "../components/PickLocation";

const { Option } = Select;

const SignUp = () => {
	const [form] = Form.useForm();
	const history = useHistory();
	const [formData, setFormData] = useState({});
	const dispatch = useDispatch();
	const [qnas, setqnas] = useState([
		{
			id: 1,
			question: 'In what city/town were you born?',
			answer: ''
		},
		{
			id: 2,
			question: 'What is the name of your best friend in High school?',
			answer: ''
		},
		{
			id: 3,
			question: 'In what year did you meet your spouse?',
			answer: ''
		},
		{
			id: 4,
			question: 'What is your Mother’s Maiden Name?',
			answer: ''
		},
		{
			id: 5,
			question: 'Where did you grow up?',
			answer: ''
		},
	]);
	const [current, setCurrent] = useState(0);
	const [selectedItems, setselectedItems] = useState([]);
	const filteredOptions = qnas.filter(o => !selectedItems.includes(o.question));
	const [loading, setloading] = useState(false);
	const [CheckAgent, setCheckAgent] = useState(false);
	const [background, setbackground] = useState("");
	const [franchiseCode, setFranchiseCode] = useState("");
	const [franchiseeCode, setFranchiseeCode] = useState("");
	const [Images, setImages] = useState([]);
	const [showMap, setshowMap] = useState(false);
	const [location, setlocation] = useState({
        address: "",
        latitude: "",
        longitude: ""		
	});

    function handleChangeLocation (info){
		form.setFieldsValue({ location: info.address })

		setlocation({
			address: info.address,
			latitude: `${info.lat}`,
			longitude: `${info.lng}`
		});		
		
        setshowMap(false);
    };	

	const onFinish = () => {
		setloading(true);

		form.validateFields(["password", "pin", "verify_option"])
		// Make request here via store

		let signUpForm = Object.assign({}, formData);
		let formFieldsData = form.getFieldsValue();

		signUpForm = {...signUpForm, ...formFieldsData}

		console.log('SignUp Form:', signUpForm);
		console.log('Form Values:', form.getFieldsValue());

		if (location) {
			// if (!signUpForm.location) signUpForm.location = location?.address
			signUpForm.cordinates = location
		}

		signUpForm.company = {
			name: signUpForm.business_name,
			tin_number: signUpForm.tin_number,
			branch: signUpForm.location || location?.address,
			website_url: signUpForm.website,
			email: signUpForm.email,
			address: signUpForm.address,
			logo_url: signUpForm.logo_url,
			business_registration_number: signUpForm.company_registration_number,
			date_of_incorporation: signUpForm.date_of_incoperation,
			type: "franchise"
		};

		// Set both franchise & franchisee codes
		if (franchiseCode) {
			signUpForm.agent_ref = franchiseCode
			signUpForm.franchise_code = franchiseCode
		}
		if (franchiseeCode) signUpForm.franchisee_code = franchiseeCode
		if (signUpForm.franchise_code) signUpForm.agent_ref =  signUpForm.franchise_code

		dispatch(signup(signUpForm))
		.then(res => {
			setloading(false);
			console.log('Status:', res.status)

			switch (res.status) {
				case 201:
					message.success("Signup successful", 5);
					setTimeout(() => {
						// moveNext('five')
						// getQuestions()
						history.push('/signin');
					}, 2000);					
				break;
				case 400:
					message.error(res.error_msg, 5);					
				break;
				case 404:
					message.error(res.error_msg, 5);					
				break;
			
				default:
					message.info("An error occured, please try again")
					break;
			}
		}, err => {
			console.log('Error State', err);
		})
	};

	const handleInput = (val) => {
		if (Object.keys(val)[0] === 'date_of_incoperation') {
			return
		} else {
			setFormData({
				...formData,
				[Object.keys(val)[0]]: Object.values(val)[0]
			});	
		}
	};

	const moveNext = async (stage) => {
		try {
			switch (stage) {
				case 'one':
					if (franchiseeCode) 
						setCurrent(current+1)
					else {
						// setCurrent(current+1)
						try {
							await form.validateFields(["franchise_code"]);
							setCheckAgent(true);

							if (!franchiseeCode) {
								dispatch(verify_agent(form.getFieldValue("franchise_code")))
								.then(res => {
									setCheckAgent(false);
									const { status } = res;
		
									switch (status) {
										case 200:
											setCurrent(current+1);
											break;
										case 404:
											message.error("error");
											break;
									
										default:
											// console.log('status', res.response.data.error_msg)
											message.error(res.response.data.error_msg);
											break;
									}
								}, err => {
									const { status } = err.response;
									const { error, errors } = err.response.data
									console.log('errors:', errors);	
									
									switch (status) {
										case 401:
											message.error(error);
											break;
										// case 422:
										// 	Object.values(errors).map(val => {
										// 		message.error(val[0])
										// 	})
										// 	break;
										case 452:
											message.error(error)
											break;
										case 450:
											message.error(error.message);
											break;
									
										default:
											break;  
									} 							
								})
							}
						} catch (errorInfo) {
							console.log('Failed:', errorInfo);
						}
					}
					break;
				case 'two':
					// setCurrent(current+1);
					try {
						await form.validateFields(["business_name", "address", "bus_tel"]);
						setCurrent(current+1);
					} catch (errorInfo) {
						console.log('Failed:', errorInfo);
					}
					break;
				case 'three':
					// setCurrent(current+1);
					try {
						await form.validateFields(["tin_number"]);
						setCurrent(current+1);
					  } catch (errorInfo) {
						console.log('Failed:', errorInfo);
					}
					break;
				case 'four':
					// setCurrent(current+1);
					try {
						await form.validateFields();
						setCurrent(current+1);
					} catch (errorInfo) {
						console.log('Failed:', errorInfo);
					}
					break;
				case 'five':
					// setCurrent(current+1);
					try {
						await form.validateFields(["answer_1", "answer_2", "answer_3"]);
						setCurrent(current+1);
					} catch (errorInfo) {
						console.log('Failed:', errorInfo);
					}
					break;
				default:
				break;
			}
		} catch (error) {
			console.error(error)
		}
	};
	
	const movePrevious = () => {
		console.log('Current:', current)
		if (current - 1 < 0) {
			return
		} else {
			setCurrent(current-1)
		}
	};

	const handleQuestionSelect = (e) => {
		// setselectedItems([...selectedItems, e])
		setselectedItems(prev => {
			return [...prev, e]
		})
	};

	const saveAndProceed = async () => {
		try {
			await form.validateFields()
			
			let data = Object.assign({}, form.getFieldsValue());

			console.log('Data:', data);
			// console.log('Merged!', {...data, })
			setloading(true);

			setTimeout(() => {
				setloading(false);
			}, 2000);
			
			history.push('/signin');
		} catch (error) {
			console.log('An error occured, please try again')
		}		
	};

	const getPageQueryParams = () => {
		var result = {};
		var params = (window.location.search.split('?')[1] || '').split('&');
		for(var param in params) {
			if (params.hasOwnProperty(param)) {
				var paramParts = params[param].split('=');
				result[paramParts[0]] = decodeURIComponent(paramParts[1] || "");
			}
		}
		return result;
	};

	// Form stages
	const stageOne = [
		{
			label: "Agent/Franchise Code",
			name: "franchise_code",
			required: true
		}
	];
	const stageTwo = [
		{
			label: "Name of Business",
			name: "business_name",
			required: true
		},
		{
			label: "Business Address",
			name: "address",
			required: true
		},
		{
			label: "Business Description",
			name: "description",
			type: "textArea",
			required: true
		},
		{
			label: "Business Tel",
			name: "telephone",
			type: "number",
			required: true
		},
		{
			label: "Business Location",
			name: "location",
			of_type: "map",
			required: true
		}
	];
	const stageThree = [
		{
			label: "Date of Incoperation",
			placeholder: "DD/MM/YYYY",
			name: "date_of_incoperation",
			of_type: "date",
			required: false
		},
		{
			label: "Company Tin Number",
			name: "tin_number",
			required: true,
			validation: true
		},
		{
			label: "Company Registration Number",
			name: "company_registration_number",
			required: false
		},
		{
			label: "Website",
			name: "website",
			required: false
		}
	];
	const stageFour = [
		{
			label: "First Name",
			name: "first_name",
			of_type: 'input',
			required: true
		},
		{
			label: "Surname",
			name: "surname",
			of_type: 'input',
			required: true
		},
		{
			label: "Username",
			name: "username",
			of_type: 'input',
			required: true
		},
		{
			label: "Gender",
			name: 'gender',
			of_type: 'select',
			required: true,
			values: [
				{
					label: 'Male',
					value: 'male'
				},
				{
					label: 'Female',
					value: 'female'
				},
				{
					label: 'Rather Not Say',
					value: 'rather_not_say'
				}
			]
		},
		{
			label: "Email",
			name: "email",
			of_type: 'input',
			type: "email",
			required: true
		},
		{
			label: "Position",
			name: "position",
			of_type: 'input',
			required: true
		}
	];

	const getQuestions = () => {
		dispatch(get_questions())
		.then((res) => {
			console.log('Questions:', res.data);
		})
	};

    function onChange(dates, dateStrings) {
        console.log('dates', dates)
        console.log('dateStrings', dateStrings)
		setFormData({ ...formData, date_of_incoperation: dateStrings });	
    };

	useEffect(() => {
		switch (current) {
			case 0:
				// setbackground(require('../assets/images/signupStages/1.jpg'));
				setbackground(Images.length > 0 ? Images[0].url : '');
				break;
			case 1:
				// setbackground(require('../assets/images/signupStages/2.jpg'));
				setbackground(Images.length > 1 ? Images[1].url : '');
				break;
			case 2:
				// setbackground(require('../assets/images/signupStages/3.jpg'));
				setbackground(Images.length > 2 ? Images[2].url : '');
				break;
			case 3:
				// setbackground(require('../assets/images/signupStages/4.jpg'));
				setbackground(Images.length > 3 ? Images[3].url : '');
				break;
			case 4:
				// setbackground(require('../assets/images/signupStages/5.jpg'));
				setbackground(Images.length > 4 ? Images[4].url : '');
				break;
		
			default:
				break;
		}	
	}, [current, Images]);

	useEffect(() => {
		if (getPageQueryParams().franchise_code) {
			form.setFieldsValue({ 
				agent_ref: getPageQueryParams().franchise_code,
				franchise_code: getPageQueryParams().franchise_code,
				franchisee_code: getPageQueryParams().franchisee_code
			})

			setFranchiseeCode(getPageQueryParams().franchisee_code)
			setFranchiseCode(getPageQueryParams().franchise_code);
		}

		// Fetch Ad Images
        dispatch(get_Ad_Images({'ad_types[]': 'sign_up'}))
        .then((response) => {
            setImages(response.data.data.images)
        }, () => {
            console.log('Error occured fetching images')
		})		
		
		// get franchise_code
		console.log('Franchise_code:', form.getFieldsValue());
	}, []);

	return (
		<WelcomePages 
			type="static" 
			single={background}
			current={current}
		>
			<div className="w-5/6 md:w-7/12 bg-white rounded-large px-8 py-6 text-center shadow--custom">
				<div className="font-bold text-lg uppercase pb-6 relative">
					{current !== 0 &&
						<div className="flex items-center justify-center absolute top-0 left-0 cursor-pointer w-8 h-8 rounded-full shadow-xl hover:shadow-lg" 
							onClick={movePrevious}>
							<img src={backIcon} className="w-4" alt=""/>
						</div>
					}
					{current < 5 ?
						<div>
							<div>Sign up</div>
							<div className="text-xs opacity-50  capitalize pt-1">
								{current === 0 && 'Agent Code Verification' }
								{(current === 1 || current === 2)  && "Company's Info" }
								{current === 3 && 'Personal Info' }
								{current === 4 && 'Password SetUp' }
							</div>
						</div>
					:
						<span>Security Questions</span>
					}
				</div>

			<Form
				name="signup"
				form={form}
				className="w-full signup"
				layout="vertical"
				onFinish={onFinish}
				onValuesChange={handleInput}
			>
				{current === 0 && 
					<>
						{stageOne.map((stage, index) => (
							<Form.Item
								key={index}
								name={stage.name}
								rules={[
									{
										required: stage.required,
										message: `${stage.required ? `Please input your ${stage.label}` : ''}`,
									}
								]}
							>
								<Input placeholder={stage.label}
									type={stage.type}							 
								/>
							</Form.Item>
						))}
						<div className="py-2">
							<Button
								type="primary"
								className="bg-primary w-full uppercase font-semibold"
								shape="round"
								size="large"
								loading={CheckAgent}
								onClick={() => { moveNext('one') }}
							>
								Verify & Start
							</Button>
						</div> 
					</>
				}
				{current === 1 && 
					<>
						{stageTwo.map((stage, index) => (
							<Form.Item  
								key={index}
								name={stage.name}
								rules={[
									{
										required: stage.required,
										message: `${stage.required ? `Please input your ${stage.label}` : ''}`,
									}
								]}
								required={stage.required}										
							>
								{stage.of_type === 'map' ?
                                    <Input className="h-8 mr-4 px-0 w-full cursor-pointer" 
										onClick={() => { setshowMap(true) }}
										suffix={
											<Tooltip title="Pick Location">
												<RadiusUprightOutlined onClick={() => { setshowMap(true) }} className="pl-2 bg-white cursor-pointer border-0" />
											</Tooltip>
										} 
										prefix={
											<RadiusUprightOutlined className="cursor-pointer border-0 hidden" />
										} 
										placeholder={stage.label} 
										type={stage.type}
										readOnly
									/>
								:
									<Input placeholder={stage.label} type={stage.type} />
								}
							</Form.Item>
						))}
						<div className="py-2">
							<Button
								type="primary"
								className="bg-primary w-full uppercase font-semibold"
								shape="round"
								size="large"
								onClick={() => { moveNext('two') }}
							>
								Next
							</Button>
						</div> 

						<PickLocation 
							showMap={showMap} 
							setshowMap={setshowMap} 
							handleChangeLocation={handleChangeLocation} 
						/>
					</>
				}
				{current === 2 && 
					<>
						{stageThree.map((stage, index) => (
							<>
								{stage.of_type === 'date' ?
									<Form.Item  
										key={index}
										name={stage.name} 
										rules={[
											{
												required: stage.required,
												message: `${stage.required ? `Please input your ${stage.label}` : ''}`,
											}											
										]}										
									>
										<DatePicker 
											className="w-full"
											format="DD-MM-YYYY"
											placeholder={stage.placeholder? `${stage.label} (${stage.placeholder})` : stage.label} 
											onChange={(e,y) => {onChange(e, y)}}
										/>		
									</Form.Item>

								:

									<Form.Item  
										key={index}
										name={stage.name} 
										rules={[
											{
												required: stage.required,
												message: `${stage.required ? `Please input your ${stage.label}` : ''}`,
											},
											({ getFieldValue }) => ({
												validator(rule, value) {
													// console.log('value:', value)
													// console.log('stage name:', stage.name)
													// console.log('isValidationTrue:', stage.validation)
												if (stage.validation) {
													if (getFieldValue('tin_number').length === 11) {
														return Promise.resolve();
													}
														return Promise.reject('Your TIN number is not accurate');
													}
												}
											})											
										]}											
									>
										<Input 
											placeholder={stage.placeholder? `${stage.label} (${stage.placeholder})` : stage.label} 
										/>
									</Form.Item>
								}							
							
							</>
						))}
					
						<div className="py-2">
							<Button
								type="primary"
								className="bg-primary w-full uppercase font-semibold"
								shape="round"
								size="large"
								onClick={() => { moveNext('three') }}
							>
								Next
							</Button>
						</div>
					</>
				}
				{current === 3 && 
					<>
						{stageFour.map((stage, index) => (
							<>
								{stage.of_type === 'select' &&
									<Form.Item  
										key={index}
										name={stage.name} 
										rules={[
											{
												required: stage.required,
												message: `${stage.required ? `Please input your ${stage.label}` : ''}`,
											}											
										]}											
									>
										<Select 
											placeholder={stage.label} 
											type={stage.type}
										>
											{stage?.values?.map((val, index) => (
												<Option value={val.value} key={index}>{val?.label}</Option>
											))}
										</Select>
									</Form.Item>
								}							

								{stage.of_type === 'input' &&
									<Form.Item  
										key={index}
										name={stage.name} 
										rules={[
											{
												required: stage.required,
												message: `${stage.required ? `Please input your ${stage.label}` : ''}`,
											}											
										]}											
									>
										<Input 
											placeholder={stage.label} 
											type={stage.type} 
										/>
									</Form.Item>
								}
							</>
						))}
						<div className="py-2">
							<Button
								type="primary"
								className="bg-primary w-full uppercase font-semibold"
								shape="round"
								size="large"
								onClick={() => { moveNext('four') }}											
							>
								Next
							</Button>
						</div>
					</>
				}								
				{current === 4 && 
					<>
						<Form.Item  
							name="password"
							rules={[
								{
									required: true,
									message: 'Please input your password',
								}								
							]}
						>
							<Input.Password
								placeholder="Password"
								type="password"
								className="tracking-wider"
							/>
						</Form.Item>
						<Form.Item  
							name="confirmed_password" 
							dependencies={["password"]}
							rules={[
								{
									required: true,
									message: 'Please confirm your password',
								},
								({ getFieldValue }) => ({
									validator(rule, value) {
									if (!value || getFieldValue('password') === value) {
										return Promise.resolve();
									}
									return Promise.reject('The two passwords do not match!');
									},
								})															
							]}
						>
							<Input.Password
								placeholder="Confirm Password"
								type="password"
							/>
						</Form.Item>
						<Form.Item  
							name="pin"
							rules={[
								{
									required: true,
									message: 'Please input your PIN',
								}								
							]}
						>
							<Input.Password
								placeholder="PIN"
								type="password"
								
							/>
						</Form.Item>
						<Form.Item  
							name="confirmed_pin" 
							dependencies={["pin"]}
							rules={[
								{
									required: true,
									message: 'Please confirm your PIN ',
								},
								({ getFieldValue }) => ({
									validator(rule, value) {
										if (!value || getFieldValue('pin') === value) {
										return Promise.resolve();
										}
						
										return Promise.reject('The PINs do not match!');
									},
								}),														
							]}
						>
								<Input.Password
									placeholder="Confirm PIN"
									type="password"											/>
						</Form.Item>
						<Form.Item  
							name="verify_option" 
							rules={[
								{
									required: true,
									message: 'Please select how to get verified',
								}								
							]}
						>
							<Select
								placeholder="Select verification medium"
								allowClear
							>
								<Option value="email">Email</Option>
								<Option value="sms">Phone</Option>
							</Select>
						</Form.Item>

						<div className="py-2">
							<Button
								htmlType="submit"
								// onClick={() => { moveNext('five') }}
								type="primary"
								className="bg-primary w-full uppercase font-semibold"
								shape="round"
								size="large"
								loading={loading}
							>
								Done
							</Button>
						</div>
					</>
				}
				{current === 5 &&
					<>
						<Form.Item  
							name="question_1"
							// className="mb-1"
							rules={[
								{
									required: true,
									message: 'Please select a question',
								}								
							]}
						>
							<Select
								placeholder="Question 1"
								onChange={handleQuestionSelect}
							>
								<Option>Choose first question</Option>
								{filteredOptions.map((qna, index) => (
									<Option value={qna.question} key={index}>{qna.question}</Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item  
							name="answer_1"
							rules={[
								{
									required: true,
									message: 'Please an answer for the selected question',
								}								
							]}									
							// className="mb-1"
						>
							<Input placeholder="Answer" />
						</Form.Item>								
						<Form.Item  
							name="question_2" 
							// className="mb-1"
							rules={[
								{
									required: true,
									message: 'Please select a question',
								}								
							]}
						>
							<Select
								placeholder="Question 2"
								onChange={handleQuestionSelect}
							>
								<Option>Choose second question</Option>
								{filteredOptions.map((qna, index) => (
									<Option value={qna.question} key={index}>{qna.question}</Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item  
							name="answer_2"
							rules={[
								{
									required: true,
									message: 'Please an answer for the selected question',
								}								
							]}									
							// className="mb-1"
						>
							<Input placeholder="Answer" />
						</Form.Item>								
						<Form.Item  
							name="question_3" 
							// className="mb-1"
							rules={[
								{
									required: true,
									message: 'Please select a question',
								}								
							]}
						>
							<Select
								placeholder="Question 3"
								onChange={handleQuestionSelect}
							>
								<Option>Choose third question</Option>
								{filteredOptions.map((qna, index) => (
									<Option value={qna.question} key={index}>{qna.question}</Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item  
							name="answer_3"
							rules={[
								{
									required: true,
									message: 'Please an answer for the selected question',
								}								
							]}									
							// className="mb-1"
						>
							<Input placeholder="Answer" />
						</Form.Item>
						
						<div className="py-4">
							<Button
								// htmlType="submit"
								onClick={() => { saveAndProceed() }}
								type="primary"
								className="bg-primary w-full uppercase font-semibold"
								shape="round"
								size="large"
								loading={loading}
							>
								Save
							</Button>
						</div>															
					</>
				}
			</Form>

				<div className="flex--basic pt-2">
					<Link to="/">
						<span className="px-2 hover:underline">Welcome</span>
					</Link>
					|
					<Link to="/signin">
						<span className="px-2 hover:underline">Sigin</span>
					</Link>		
				</div>
			</div>
		</WelcomePages>
	)
};

export default SignUp;

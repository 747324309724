import React, { useState, useEffect } from "react";
import { Form, Input, Tooltip, Button, notification } from "antd";
import { RadiusUprightOutlined } from "@ant-design/icons";
import utils from "../../utils/services"

import PickLocation from "../../components/PickLocation";

import { useDispatch } from "react-redux";
import { Initiate_sign_up, Edit_Merchant } from "../../store/merchants/actions";

const AddMerchant = ({
  setVisible = () => {},
  fetchMerchants = () => {},
  mode = "ADD",
  updateData = {},
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [showMap, setshowMap] = useState(false);
  const [loading, setloading] = useState(false);
  const [info, setinfo] = useState({
    address: "",
    latitude: "",
    longitude: "",
  });
  const [formData, setformData] = useState({})

  function handleChangeLocation(info) {
    if (info.address) {
      form.setFieldsValue({ branch: info.address });
    }

    setinfo({
      address: info.address,
      latitude: `${info.lat}`,
      longitude: `${info.lng}`,
    });

    setshowMap(false);
  }

  const handleValueChange = (val) => {
    setformData({
    	...formData,
    	[Object.keys(val)[0]]: Object.values(val)[0]
    });
  };

  const onFinish = async (values) => {
    console.log("Received values of form: ", values);

    try {
      await form.validateFields();

      let data = Object.assign({}, values);
      data.signup_completion_url = "https://merchant.primeegiftcard.com/signup";
      data.cordinates = info;

      if (!data.email) delete data.email;

      console.log("All available Fields:", data);

      setloading(true);

      if (mode === "ADD") {
        dispatch(Initiate_sign_up(data)).then(
          (response) => {
            console.log("Signup:", response);
            switch (response.status) {
              case 200:
              case 201:
                notification.success({
                  message: "Branch Created",
                  description:
                    "A temporal password has been sent to the phone number for login.",
                });

                fetchMerchants();
                setVisible(false);
                break;
              case 401:
                notification.error({
                  message: response.error_msg,
                });
                break;
              case 400:
                notification.error({
                  message: response.error_msg,
                });
                break;
              case 500:
                notification.error({
                  message: response.error_msg,
                });
                break;

              default:
                break;
            }
            setloading(false);
          },
          (error) => {
            console.log("Error in then:", error);
            notification.error({
              message: "Error creating merchant",
              description: "An error occured",
            });
            setloading(false);
          }
        );
      } else {
        data.id = updateData.id;
        dispatch(Edit_Merchant(data)).then(
          (response) => {
            console.log("Signup:", response);
            switch (response.status) {
              case 200:
                notification.success({
                  message: "Merchant Updated",
                  description: "Merchant details updated successfully",
                });
                fetchMerchants();
                setVisible(false);
                break;
              case 400:
                notification.error({
                  message: response.error_msg,
                });
                break;
              case 500:
                notification.error({
                  message: response.error_msg,
                });
                break;

              default:
                break;
            }
            setloading(false);
          },
          (error) => {
            console.log("Error in then:", error);
            notification.error({
              message: "Error creating merchant",
              description: "An error occured",
            });
            setloading(false);
          }
        );
      }
    } catch (error) {
      console.log("Error:", error);
      notification.error({
        message: "Error creating merchant",
        description: "An error occured",
      });
      setloading(false);
    }
  };

  useEffect(() => {
    if (utils.present(updateData) || mode !== "ADD") {
      setinfo(updateData.cordinates);

      form.setFieldsValue({
        name: updateData?.name,
        telephone: updateData?.telephone?.includes('+') ? updateData?.telephone?.substring(1) : updateData?.telephone,
        branch: updateData?.branch,
        email: updateData?.email,
      });
    }

    // if (mode === 'ADD') {
    //   form.resetFields();
    // }
  }, [mode, updateData]);

  return (
    <>
      <Form
        name="addmerchant"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={handleValueChange}
      >
        <Form.Item
          label="Merchant name"
          style={{ marginBottom: 0 }}
          className=""
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Merchant name is required",
              },
            ]}
          >
            <Input placeholder="Name" />
          </Form.Item>
        </Form.Item>
        <Form.Item label="Location/Branch" className="mt-1">
          <Form.Item
            name="branch"
            noStyle
            rules={[{ required: true, message: "Location/Branch is required" }]}
          >
            <Input
              placeholder="Location"
              addonAfter={
                <Tooltip title="Show Map">
                  <RadiusUprightOutlined
                    onClick={() => {
                      setshowMap(true);
                    }}
                    className="cursor-pointer"
                  />
                </Tooltip>
              }
              onClick={() => {
                setshowMap(true);
              }}
              readOnly
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          label="Merchant E-mail"
          style={{ marginBottom: 0 }}
          className="mt-1"
        >
          <Form.Item
            name="email"
          >
            <Input type="email" placeholder="E-mail" />
          </Form.Item>
        </Form.Item>
        <Form.Item className="mt-1" style={{ marginBottom: 0 }}>
          <Form.Item
            label="Merchant Phone"
            name="telephone"
            rules={[{required: true,message: "Phone e-mail is required" }]}
          >
            <Input type="number" placeholder="Phone" />
          </Form.Item>
        </Form.Item>
        <div className="py-2 flex--basic">
          <Button
            htmlType="submit"
            type="primary"
            className="bg-primary w-1/2 h-8 flex--basic uppercase font-semibold"
            shape="round"
            size="small"
            loading={loading}
          >
            {mode === "ADD" ? "Set-up Merchant" : "Update Merchant"}
          </Button>
        </div>
      </Form>

      <PickLocation
        showMap={showMap}
        setshowMap={setshowMap}
        handleChangeLocation={handleChangeLocation}
      />
    </>
  );
};

export default AddMerchant;

import React, { useState, useEffect, notification } from 'react';
import actioncable from "actioncable";
import { config } from '../redux/config';
import { Row, Col, Card, Skeleton } from 'antd';

// Components
import ReportTable from "../components/misc/ReportTable";
import TransactionTable from "../components/misc/TransactionTable";

import { useDispatch, useSelector } from "react-redux";
import * as ReportActions from '../store/reports/actions';
import { getMerchants } from "../store/merchants/actions";

const gridStyle = {
    width: '100%',
    textAlign: 'center',
  };

const Reports = () => {
    const dispatch = useDispatch();
    const { merchants } = useSelector((state) => state.merchants);
    const [loading, setloading] = useState(true);
    const [info, setInfo] = useState([
        {
          title: 'LAST DEPOSIT',
          id: 'last_deposit',
          value: '0',
          percentage: '0.00',
          period: '01/02/2021'
        },
        {
          title: 'TOTAL DEPOSIT THIS MONTH',
          id: 'deposits',
          value: '0',
          percentage: '0.00',
          period: '01/02/2021'
        },
        {
          title: 'CASHED-OUT DEPOSIT',
          id: 'kick_back',
          value: '0',
          percentage: '0.00',
          period: '01/02/2021'
        },
        {
          title: 'GIVE BACK PROMO',
          id: 'give_back',
          value: '0',
          percentage: '0.00',
          period: '01/02/2021'
        },
    ]);
    // Functions
    const fetchStats = () => {
        // Get dashboard stats data
        setloading(true)
        dispatch(ReportActions.Reports_Stats())
        .then((response) => {
          console.log('Reports Stats:', response);
          setloading(false);
          // Map new values
          let newArr = info.slice();
          for (const prop in response.data?.data) {
            console.log('Prop:', prop)
            newArr.forEach((item) => {
              if (item.id === prop) {
                if (prop === 'give_back') {
                    item.value = response?.data?.data[prop].amount
                    item.percentage = response?.data?.data[prop].comparison_percentage
                }
                else if (prop === 'kick_back') item.value = response?.data?.data[prop].amount
                else item.value = response?.data?.data[prop]
              }
            })
          }
    
          setInfo([...newArr])
        }, (error) => {
          console.log('Error:', error);
          setloading(false);
        });
    };

    const handleSocketCalback = () => {
      // Show notification with a download option
    };
    
    useEffect(() => {
      setTimeout(() => {
        setloading(false)
      }, 2000);
    }, [loading])

    useEffect(() => {
      dispatch(getMerchants({}))
      fetchStats()
    }, [])

    return (
        <>
            <div className="text-center pb-4">
                <h1 className="font-bold mb-1 text-lg leading-snug">
                    Report
                </h1>
                <div className="font-normal text-sm">
                    <p>View or download reports of brand transfers and sales report for your records</p>
                </div>
            </div>            

            <Row gutter={16} className="dashboard mx-0 my-4">
                {info.map((item, index) => (
                    <Col span={6} key={index} className="p-0 pr-2">
                        <Card className="rounded-lg">
                            <Skeleton paragraph={{ rows: 1 }} loading={loading} active>
                                <Card.Grid style={gridStyle} className="p-4 h-24">
                                <div className="flex justify-between text-xs">
                                    <div className="pb-4 uppercase leading-none text-left">
                                    <h2 className="text-text">{item.title}</h2>
                                    <div className="text-2xl font-bold">¢{item.value}</div>
                                    </div>
                                    <div className="text-text flex flex-col justify-end text-right leading-tight">
                                        {index === 3 && <div className="text-base">{item.percentage}%</div>}
                                        <div>{item.period}</div>
                                    </div>
                                </div>
                                </Card.Grid>
                            </Skeleton>          
                        </Card>
                    </Col>
                ))}
            </Row>
      
            <Row className="mx-0 my-4">
                <Col span={12} className="">
                    <div className="flex flex-col py-3 bg-white rounded-lg mr-3">
                      <ReportTable 
                        merchants={merchants}
                      />
                    </div>
                </Col>
                
                <Col span={12} className="">
                    <div className="flex flex-col py-3 bg-white rounded-lg">
                      <TransactionTable
                        merchants={merchants}
                      />
                    </div>
                </Col>

            </Row>            
        </>
    )
}

export default Reports;
import React, { useState } from 'react';
import { motion } from "framer-motion"
import { Skeleton, Image } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';

import uploadIcon from '../../assets/images/placeholder.jpg';
import Uploader from '../../components/misc/uploader';


const CustomUpload = ({
    markTemplate=()=>{}
}) => {
    const [selected, setselected] = useState(false);
    const [promoImg, setpromoImg] = useState(
        // require('../../assets/images/placeholder.jpg')
    );

    const template_l = {
        id: 1,
        type: "custom",
        category: "custom",
        title: "",
        amount: 0,
        image: "",
        selected: "true",
        default_color: "#FFF",
        font_weight: "bold",
        // card_category_id: "9999",
        // logo: "",
        disable_image_upload: false,
        is_promotion: null,
        promotion_type: null,
        promotion_title: null,
        expiry_date: null,
        logo_position: ["bottom", "right"],
        description: "",
        promotion_start: null,
        promotion_description: null,
        promotion_terms: null,
        web_design_props: {
            logo_position: ["bottom", "right"],
            default_color: "#fff",
            font_weight: "normal"         
        },
        state: ''
    };

    const categroy_l = {
        // cat_id: "",
        category: "Custom",
        gallery: [
            {
                id: 1,
                amount: "0",
                picture: promoImg,
                selected: false,
                // logo: "",
                default_color: '#fff',
                font_weight: 'normal',
                disable_image_upload: false,
                logo_position: ['bottom', 'right'],
                is_promotion: null,
                promo_type: null,
                promotion_title: null,
                promotion_start: null,
                description: "",
                expiry_date: null,
                promo_description: null,
                promo_terms: null             
            }
        ]        
    };

    const getUploadedImage = (ImageURL) => {
        // console.log('Uploaded Image:', ImageURL);
        setpromoImg(ImageURL);

        template_l.image = ImageURL;
        markTemplate(template_l, categroy_l)
        // setvisible(true);
        // dispatch(setTemplateInfo('card_background', ImageURL));
    };    

    return (
        <div className={`${selected ? "bg-gray" : ''} w-1/3 cursor-pointer relative`}
            onClick={() => { setselected(!selected) }} 
            style={{height: "15rem"}}
        >
            {/* {selected && (
                <CheckCircleTwoTone
                twoToneColor="#88F270"
                className="absolute right-0 top-0 p-5 text-4xl text-white"
                style={{ zIndex: "8" }}
                />
            )} */}

            <motion.div
                whileHover={{ scale: 1.05, transition: { duration: .3 } }}
                className="p-4 bg-transparent"
                style={{ width: "100%", height: "inherit" }}
            >
                    <Uploader 
                        uploadText="Upload card design"
                        disabled={false}
                        uploadImage={getUploadedImage}
                        classStyle={`${promoImg ? 'my-1 bg-cover bg-center bg-no-repeat': ''} h-48`}
                        bg={promoImg}
                        // showButton={false}
                    >
                        {/* <Image
                            preview={false}
                            className="w-full"
                            width={200}
                            height={200}
                            src={uploadIcon}
                            placeholder={
                                <Skeleton.Avatar
                                    className="w-full"
                                    active={true}
                                    size="large"
                                    shape="square"
                                />
                            }
                        /> */}
                </Uploader>                
            </motion.div>
        </div>
    )
};

export default CustomUpload;

import React, { useState, useEffect } from 'react';
import { Form,Table } from 'antd';
import moment from "moment";

import { useDispatch } from "react-redux";
import * as ReportActions from '../../store/reports/actions';

// Components 
import Filter from "../../components/misc/ReportFilter";

const ReportTable = ({
    merchants=[]
}) => {
    const dispatch = useDispatch();
    const [transactions, settransactions] = useState();
    const [loading, setloading] = useState(false);
    const [filters, setfilters] = useState({});
      
    const setTransactionFilters = (payload) => {
        setfilters(payload)
    };

    const columns = [
    {
        title: 'Type',
        dataIndex: 'transaction_type',
        key: 'transaction_type',
        render: transaction_type => <p className="capitalize">{transaction_type || '-'}</p>
    },
    // {
    //     title: 'Location',
    //     dataIndex: 'location',
    //     key: 'location',
    //     render: location => location || '-'
    // },
    {
        title: 'Date',
        dataIndex: 'created_at',
        key: 'created_at',
        render: date => moment(date).format("MMM Do, YYYY")
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: amount => <p><b>GH¢</b> {amount || '-'}</p>
    }
    ];

    const fetchTransactions = (reportFilters) => {
        // Get dashboard stats data
        setloading(true)
        dispatch(ReportActions.Get_Transastions(reportFilters))
        .then((response) => {
          settransactions(response?.data?.data?.transactions);
          setloading(false);
        }, (error) => {
          console.log('Error:', error);
          setloading(false);
        });
    };    

    useEffect(() => {
        let defFilter = {
            'transaction_types[]': 'deposit'
        }
        console.log('Merged:', {...filters, ...defFilter})

        fetchTransactions({...filters, ...defFilter});     
    }, [filters]);

    return (
        <section className="w-full">
            <Filter 
                title={<div className="text-sm py-1">Reports</div>}
                showType={false}
                merchants={merchants}
                setLocalFilters={setTransactionFilters}
            />
            <div className="pt-10 pr-4 reportTable">
                <h1 className="text-primary font-bold text-base pb-2 pl-4">Withdrawal</h1>
                <Table 
                    loading={loading}
                    columns={columns} 
                    dataSource={transactions} 
                />
            </div>
        </section>
    )
}

export default ReportTable

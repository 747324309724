import { config } from '../redux/config';
import { authHeader } from '../_helpers/auth-header';
import axios from 'axios';
import utils from '../utils/services'

export const cardService = {
    createCard,
    publishMultipleCards,
    getCards,
    listCategories,
    updateCard,
    fetchAllTemplates,
    getGiveBackCampaigns,
    deleteCard,
    changeChangeCardStateardState    
};

function createCard(info, mode="ADD") {
    const requestOptions = {
        method: mode === "ADD" ? 'POST' : 'PUT',
        headers: authHeader(),
        data: info 
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/cards`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}

function deleteCard(id, ID) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/cards/${ID}/remove?card_id=${id}`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}

function changeChangeCardStateardState(id, ID, cardState) {
    const data = {
      'status': cardState,
      'card_id': id
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        data: data
        // data: {card_id: 1,
        //  status:   draft 
        // }
    };

    // let query = utils.createQueryFromArray(cardIDs);
    let url = `${config.apiUrl}/cards/${ID}/change_cards_state`;
    // if (query) url = url + `?${query}`;   

    return new Promise((resolve, reject) => {
        axios(url, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}

function publishMultipleCards(cardIDs, mode="ADD", ID) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        data: cardIDs
    };

    // let query = utils.createQueryFromArray(cardIDs);
    let url = `${config.apiUrl}/cards/${ID}/publish_cards`;
    // if (query) url = url + `?${query}`;    

    return new Promise((resolve, reject) => {
        axios(url, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}

function getCards(filters) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let query = utils.createQueryFromObject(filters);
    let url = `${config.apiUrl}/cards/list`;
    if (query) url = url + `?${query}`;

    return new Promise((resolve, reject) => {
        axios(url, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}

function listCategories() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/cards/categories `, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}

function getGiveBackCampaigns() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/merchants/give-back-campaigns`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}

function fetchAllTemplates() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/cards/template_categories`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}

function updateCard(formData) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        data: formData
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/users/merchant/signup`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            console.log('Rejected:', err)
            reject(err);
        })
    });
}


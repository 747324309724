import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

// Style import
import './assets/styles/custom.less';

// Layouts
import SiteLayout from './components/layouts/siteLayout';
import AppLayout from './components/layouts/appLayout';


// Components
// import SocialShare from "./components/misc/SocialShare";

// Pages
import SignIn from './pages/signin';
import Verification from './pages/verification';
import SignUp from './pages/signup';
import Welcome from './pages/welcome';
import Dashboard from './pages/dashboard';
import Sales from './pages/Sales';
import AddCard from './pages/AddCard';
import Promotions from './pages/promotions';
import Coupons from './pages/Coupons';
import NewCoupons from './pages/Coupons/new';
import EditCoupons from './pages/Coupons/id';
import Merchants from './pages/Merchants';
import Reports from './pages/Reports';
import FundRequests from './pages/FundRequest';
import Profile from './pages/Profile';
import ADCampaign from './pages/ADCampaign';
import AddOns from './pages/AddOns';
import Success from './pages/success';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';

// Redux Actions
// import { useSelector } from 'react-redux';
// import { user } from "./store/user.actions";

const App = () => {
  // const { user } = useSelector(state => state.user);
  // const location = useLocation();
  // console.log('Route?', Route)
  
  useEffect(() => {
    window.scrollTo(0, 0)
    // console.log('Router:', Router)
  }, [])

  return (
    <div className="App">
      {/* <SocialShare></SocialShare> */}
      <TransitionGroup>
        <CSSTransition classNames="fade" timeout={5000}>
          <Router>
            <Switch>
              {/* Welcome pages - sign_in & sign_up */}
              <SiteLayout exact path="/" component={Welcome} />
              <SiteLayout path="/signin" component={SignIn} />
              <SiteLayout path="/verification" component={Verification} />
              <SiteLayout path="/forgot-password" component={ForgotPassword} />
              <SiteLayout path="/reset-password" component={ResetPassword} />
              <SiteLayout path="/signup" component={SignUp} />
              <SiteLayout path="/success" component={Success} />

              {/* App View */}
              <AppLayout path="/dashboard" component={Dashboard} />
              <AppLayout path="/sales" component={Sales} />
              <AppLayout path="/add-card" component={AddCard} />
              <AppLayout path="/cards" component={Promotions} />
              <AppLayout path="/coupons" component={Coupons} />
              <AppLayout path="/coupon/new" component={NewCoupons} />
              <AppLayout path="/coupon/:id" component={EditCoupons} />
              <AppLayout path="/merchants" component={Merchants} />
              <AppLayout path="/reports" component={Reports} />
              <AppLayout path="/fund-request" component={FundRequests} />
              <AppLayout path="/ad-campaign" component={ADCampaign} />
              <AppLayout path="/notifications" component={AddOns} />
              <AppLayout path="/profile" component={Profile} />
            </Switch>
          </Router>
        </CSSTransition>
      </TransitionGroup>
    </div>
  )
};


export default App;
import { config } from '../redux/config';
import { authHeader } from '../_helpers/auth-header';
import axios from 'axios';
import utils from '../utils/services'

// const config = {
//     apiUrl: 'https://api.primeegiftcard.com/prime.sika/v1'
// }

export const userService = {
    login,
    logout,
    signup,
    getUserDetails,
    updateProfile,
    getAdImages,
    verify,
    verify_otp,
    verify_agent,
    forgot_password,
    reset_password,
    upload_profile_logo,
    getLogo,
    getProfilePic,
    changePassword,
    save_answers,
    resend_verification_email,
    questions,
    request_otp
};

function login(forname, password) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        data: { 
            forname, 
            password,
            user_type: "merchant"
        }
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/users/login`, requestOptions)
        // axios(`${config.apiUrl}/users/merchant/login`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}

function logout() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        data: {
            user_type: "merchant"
        }
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/users/logout`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}

function getUserDetails() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/users/merchant/details`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}

function updateProfile(info, CODE) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        data: info
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/accounts/merchant/profile?company_code=${CODE}`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}

function request_otp(params = {}, method = 'POST') {
    const requestOptions = {
        method,
        headers: authHeader(),
        data: params
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/managers/merchants/otps`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}

function changePassword(info, CODE) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        data: info
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/accounts/merchant/profile/change-password?company_code=${CODE}`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}

function getAdImages(params) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let query = utils.createQueryFromObject(params);
    let url = `${config.apiUrl}/merchants/marketing/images`;
    if (query) url = url + `?${query}`    

    return new Promise((resolve, reject) => {
        axios(url, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}

function signup(formData) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        data: formData
    }

    let url = "";

    if (formData.franchisee_code) url = `${config.apiUrl}/managers/merchants/complete-signup`;
    else url = `${config.apiUrl}/users/merchant/signup`;

    return new Promise((resolve, reject) => {
        axios(url, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            console.log('Rejected:', err.response.data)
            reject(err);
        })
    });
}

function verify(phone) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        data: phone
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/users/customer/resend-verification`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            console.log('Rejected:', err.response.data)
            reject(err);
        })
    });
}

function verify_otp(code, phone) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        data: phone
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/users/merchant/${code}/verify`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            console.log('Rejected:', err.response.data)
            reject(err);
        })
    });
}

function verify_agent(code) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl_open}/users/agent/${code}/check`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}

function resend_verification_email () {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/managers/merchants/resend-code`, requestOptions)
        .then(res => {
            resolve(res.data);
        })
        .catch(err => {
            reject(err?.response?.data);
        })
    });
}

function forgot_password(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        data: data
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/users/merchant/forgotten`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}

function reset_password(info) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        data: info
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/users/reset-password`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}

function upload_profile_logo(info) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        data: info
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/accounts/merchant/profile/logo`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}

function getLogo(code) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        params: { company_code: code }
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/accounts/merchant/profile/logo`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}
function getProfilePic(code) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        params: { company_code: code }
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/accounts/merchant/profile/profile-pic`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}

function save_answers(answers) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        data: answers
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/questions/merchant/answer-question`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}

function questions() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/questions/merchant/list`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}

// https://api.primeegiftcard.com/prime.sika/v1/accounts/merchant/profile/logo?company_code=790331347249


import * as types from './types';

const INITIAL_STATE = {
    transactions: [],
    meta: {},
    filters: {
        // page: 1,
        // limit: 10,
        // from: '',
        // to: '',
        // search_string: '',
        // branches: [],
        // statuses: []
    }    
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {    
        case types.GET_TRANSACTIONS:
           return {
                ...state, transactions: action.payload
           };

        case types.SET_TRANSACTION:
           return {
                ...state, 
                transactions: action.payload.transactions,
                meta: action.payload
           };

        case types.SET_FILTERS:
           let newFilters = Object.assign({}, state.filters);
           newFilters[action.payload.type] = action.payload.data

          return {
               ...state, 
               filters: newFilters
           }; 
              
        case types.SET_BULK_FILTERS:
           let nonEmptyObj = {};
           
           for (const property in action.payload.data) {
               if (action.payload.data[property] !== undefined && action.payload.data[property] !== null && action.payload.data[property] !== "" ) {
                   nonEmptyObj[property] = action.payload.data[property]
               }
           }

       return {
           ...state, 
           filters: nonEmptyObj
       };                  

        default: return state;
    }

};

export default reducer;


import React, { useState, useEffect } from "react";
import { Row, Col, Card, Skeleton, Button, Progress, Form, Select, Input, DatePicker, Modal, Space } from "antd";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cookies from "js-cookie";
import moment from 'moment';

// import GiftCard from '../components/gift_card';
// import pkg from '../assets/images/package.png';
import SavedCard from "../components/TemplateCard";
import CardList from "src/components/CardList";

import { useDispatch, useSelector } from "react-redux";
import { getCard } from "../store/cards/cards.actions";
import { setAnswers, get_questions, save_user_answers } from "../store/user.actions";
import { getMerchants } from "../store/merchants/actions";
import { setFilters, getStats, getMonthlySales, getSalesOverview, getCardSalesOverview, getAvailableFunds } from "../store/dashboard/actions";

// Graph
import Overview from "../components/LineGraph";
import SalesOverview from "../components/pieChart";

const gridStyle = {
  width: "100%",
  textAlign: "center",
};

const { Option } = Select;

export default function Dashboard() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { cards } = useSelector(state => state.cards);
  const { data: userInfo, qna } = useSelector(state => state.user);
  const { filters } = useSelector(state => state.dashboard);
  const { merchants } = useSelector((state) => state.merchants);
  const [loading, setloading] = useState(true);
  const [loadingCards, setloadingCards] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedItems, setselectedItems] = useState([]);
  const [qnas, setqnas] = useState([]);
  const [finalData, setfinalData] = useState([]);
  const [info, setInfo] = useState([
    {
      id: 'total_sales',
      title: "total sales",
      value: "0",
      percentage: "0.00%",
      period: "This month",
    },
    {
      id: 'new_users',
      title: "New User",
      value: "0",
      percentage: "0.00%",
      period: "This month",
    },
    {
      id: 'in_store_card_use',
      title: "In-Store Card Use",
      value: "0",
      percentage: "0.00%",
      period: "This month",
    },
    {
      id: 'cashed_out',
      title: "Cash-Out",
      value: "0",
      percentage: "0.00%",
      period: "This month",
    }
  ]);
  const [purchaseGraph, setpurchaseGraph] = useState([]);
  const [cardsSummary, setcardsSummary] = useState({});
  const [cardsPurchase, setcardsPurchase] = useState({});
  const [availableFunds, setAvailableFunds] = useState()
  const loading_cards = [{},{},{},{},{}];
  const [slider, setslider] = useState(1);
  const [min, setmin] = useState(0);
  const [max, setmax] = useState(10);  
  const filteredOptions = qnas.filter(o => !selectedItems.includes(o.ask));
  const [settings, setSettings] = useState({
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slider,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: cards.length < 4 ? cards.length : 4,
        },
      },
      {
        breakpoint: 433,
        settings: {
          slidesToShow: cards.length < 2 ? cards.length : 2,
        },
      },
    ]
  });

  const onChange = () => {
  };
  
	const handleQuestionSelect = (e) => {
    setselectedItems(prev => {
			return [...prev, e]
    })
    
    setIDs(e)
	};
    
  const onFinish = async () => {
		try {
			await form.validateFields()

      let data = form.getFieldsValue();
      console.log('Form data:', data)
      let formData = [];

      for (let index = 0; index < 3; index++) {
        formData.push(
          { 
            'question_id': Object.keys(finalData[index])[0], 
            'answer': Object.values(data)[index] 
          }
        )
      }
      
      console.log('Final:', formData)

      dispatch(setAnswers(formData))
      dispatch(save_user_answers({ 'answers': formData}))
      .then((res) => {
        console.log('Res:', res.data);
        setloading(true);
        setVisible(false)
        Cookies.set('qna', formData)
      }, (err) => {
        console.log('Err:', err.data);
      })
		} catch (error) {
			console.log('An error occured, please try again')
		}		
  };

  const setIDs = (ask) => {
    let index = qnas.findIndex(el => el.ask.includes(ask))

    setfinalData(prev => {
			return [...prev, {
        [qnas[index].id]: ""
      }]
    })

    console.log('FinalData:', finalData)
  };

  const handleMerchantChange = (val) => {
    console.log('Merchant:', val) 
    dispatch(setFilters('merchant_ids[]', val))   
  };

  const handleDateChange = (date) => {
    let month_year = moment(date).format("MMM-YYYY").split('-');
    dispatch(setFilters('month', month_year[0].toString().toLowerCase()));
    dispatch(setFilters('year', month_year[1]));   
  };

  const fetchStats = () => {
    // Get dashboard stats data
    setloading(true)
    dispatch(getStats(filters))
    .then((response) => {
      console.log('Dashboard Stats:', response);
      setloading(false);
      // Map new values
      let newArr = info.slice();
      for (const prop in response) {
        console.log('Prop:', prop)
        newArr.forEach((item) => {
          if (item.id === prop) {
            // setInfo(prev => {
            //   return [...prev, { value: response[prop].count, percentage: response[prop].comparison_percentage }]
            // })
            item.value = response[prop].count
            item.percentage = response[prop].comparison_percentage
          }
        })
      }

      setInfo([...newArr])
    }, (error) => {
      console.log('Error:', error);
      setloading(false);
    });
  };

  const fetchMonthlySales = () => {
    // Get dashboard stats data
    setloading(true);
    dispatch(getMonthlySales(filters))
    .then((response) => {
      console.log('Monthly Purchase Stats:', response);
      const GraphData = response.totals?.map((data) => {
        data.month = data.month?.toString().charAt(0).toUpperCase() + data.month.slice(1)
        data.sale = data.total
        delete data.total
        data.color = 'Month'

        return data
      })

      console.log('Final GraphData:', GraphData);
      setpurchaseGraph(GraphData)
      setloading(false);
    }, (error) => {
      console.log('Error:', error);
      setloading(false);
    });
  };

  const fetchSalesOverview = () => {
    // Get dashboard stats data
    setloading(true);
    dispatch(getSalesOverview(filters))
    .then((response) => {
      console.log('Sales Overview:', response);
      setcardsSummary(response)
      setloading(false);
    }, (error) => {
      console.log('Error:', error);
      setloading(false);
    });
  };

  const fetchCardSalesOverview = () => {
    // Get dashboard stats data
    setloading(true);
    dispatch(getCardSalesOverview(filters))
    .then((response) => {
      setcardsPurchase(response)
      setloading(false);
    }, (error) => {
      console.log('Error:', error);
      setloading(false);
    });
  };

  const fetchAvailableFunds = () => {
    // Get dashboard stats data
    setloading(true);
    dispatch(getAvailableFunds(filters))
    .then((response) => {
      console.log('Available Funds:', response.data?.data?.available_funds);
      setAvailableFunds(response.data?.data?.available_funds)
      setloading(false);
    }, (error) => {
      console.log('Error:', error);
      setloading(false);
    });
  };

  const handleNextPrev = (type) => {
    switch (type) {
      case 'next':
        if (cardsPurchase.length >= parseInt(min + 10)) {
          setmin(parseInt(min+10))
          setmax(parseInt(max+10))
        }
        break;
      case 'prev':
        if (parseInt(min - 10) >= 0) {
          setmin(parseInt(min-10))
          setmax(parseInt(max-10))
        }        
        break;
      default:
        break;
    }
  };  

  useEffect(() => {
    // Get all cards
    setloadingCards(true)
    dispatch(getCard())
    .then(() => {
      setloadingCards(false)
    })

    // Get All Merchants
    dispatch(getMerchants({}))

    return () => {
      // console.log('UseEffect Clean up')
    }
  }, []);

  useEffect(() => {
    // cards.length <= 1 ?  : setSettings(prev => {return { ...prev, slidesToShow: cards.length }})
    if (cards.length > 5) {
      setslider(5) 
    } else {
      setslider(cards.length)
    }

    setSettings(prev => {return { ...prev, slidesToShow: slider }})
  }, [cards, slider]);  

  useEffect(() => {
    fetchStats()
    fetchMonthlySales()
    fetchSalesOverview()
    fetchCardSalesOverview()
    fetchAvailableFunds()

    return () => {}
  }, [filters]);
  
  useEffect(() => {
    console.log('User Details:', userInfo)

    // const isQnA = Cookies.get('qna')
    // if (Utils.empty(isQnA)) {
    //   setVisible(true);
    // } else {
    //   setVisible(false);
    // }

    // get all question
    // if (Utils.empty(isQnA)) {
      if (userInfo?.login_count < 1) {
      // get all question
        dispatch(get_questions())
        .then((res) => {
          setqnas(res.data.data.questions)
        })
        .catch((err) => {
          console.log('Error:', err)
        })
  
        setVisible(true);
      } else {
        setVisible(false);
      }
  }, [userInfo]);

  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 2000);
  }, [loading]);

  return (
    <>
      <Row className="mx-0 pb-4">
        <Col span={24} className="px-4 py-3 bg-cards rounded-large">
          <h1 className="uppercase font-bold mb-4 text-sm text-center text-primary">
            E-Gift Cards
          </h1>

          <CardList 
            loadingCards={loadingCards} 
            cards={cards} 
            loading_cards={loading_cards} 
            settings={settings} 
          />

          {cards.length < 1 && (
            <div className="py-4 w-full text-center text-primary">
              No Cards Created yet
            </div>
          )}
        </Col>
      </Row>

      <section className="py-4">
        {/* <h4 className="font-bold">Filter:</h4> */}
        <Form
          name="filter"
          className="flex flex-wrap w-full justify-end w-full font-normal filter-form pt-4"
          // onValuesChange={handleValueChange}
          // onFinish={onFinish}
        >
          <div className="w-1/3">
            <Form.Item name="merchants" label="Merchants" className="w-full pr-4">
              <Select placeholder="Select Merchant" allowClear onChange={handleMerchantChange}>
                {merchants.map((user) => (
                  <Option value={user.id}>{user.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="w-1/3">
            <Form.Item name="date" label="Month/Year" className="w-full pr-4">
              <DatePicker className="w-full bg-white" picker="month" onChange={handleDateChange} />
            </Form.Item>
          </div>

        </Form>
      </section>

      <Row gutter={16} className="dashboard mx-0">
        {info.map((item, index) => (
          <Col span={6} key={index} className="p-0 pr-2">
            <Card className="rounded-lg">
              <Skeleton paragraph={{ rows: 1 }} loading={loading} active>
                <Card.Grid style={gridStyle} className="p-4">
                  <div className="flex justify-between text-xs">
                    <div className="pb-4 uppercase leading-none text-left">
                      <h2 className="text-text">{item.title}</h2>
                      <div className="text-2xl font-bold">{item.value}</div>
                    </div>
                    <div className="pt-4 text-gray-400 text-right leading-tight">
                      <div className="text-base">{item.percentage}%</div>
                      <div>{item.period}</div>
                    </div>
                  </div>
                </Card.Grid>
              </Skeleton>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Graph */}
      <Row className="mx-0 my-8">
        <Col span={12} className="pr-4">
          <div className="flex flex-col">
            <div className="graph w-full bg-white p-4 rounded-lg">
              {/* Monthly Sales Overview */}
              <div className="flex justify-between border-b border-gray-200 mb-2">
                <div className="pb-4 leading-none text-left">
                  <h2 className="text-gray-500">OVERVIEW</h2>
                  <div className="font-bold">Monthly Total Sales</div>
                </div>
                <div>
                  <Button
                    type=""
                    className="mr-3 bg-blue-500 rounded text-white border-none"
                  >
                    Month
                  </Button>
                  {/* <Button
                    type=""
                    className="bg-red-500 rounded text-white border-none"
                  >
                    Weekly
                  </Button> */}
                </div>
              </div>
              <Overview 
                font={10}
                data={purchaseGraph}
              />
            </div>
            <div className="withdrawal mt-4 w-full bg-white p-4 rounded-lg">
              {/* withdrawal table goes here */}
              <div className="flex justify-between border-b border-gray-200 mb-2 pb-6">
                <div className="pb-4 leading-none text-left w-full">
                  <div className="font-bold">Available Funds</div>
                  <div className="py-2 leading-none text-left">
                    {/* <h4 className="text-gray-500 font-thin">
                      Transfer mobile into your linked Bank Account{" "}
                    </h4> */}
                    <div className="text-5xl font-bold text-center pt-8">
                      GHS {availableFunds}
                    </div>
                    {/* <div className="flex justify-center my-6 mx-auto">
                      <Button
                        type=""
                        className="bg-yellow-500 rounded-full text-black border border-black"
                      >
                        Transfer Funds
                      </Button>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* Table */}
              {/* <Table columns={columns} dataSource={data} onChange={onChange} /> */}
            </div>
          </div>
        </Col>

        <Col span={12} className="pr-4">
          <div className="flex flex-col p-3 bg-white rounded-lg">
            <div className="leading-none text-left border-b border-gray-200 w-full">
              <h2 className="text-gray-500">SALES OVERVIEW</h2>
              {/* <div className="font-bold">Monthly Total Sales</div> */}
              <div className="flex items-center justify-between w-full">
                <SalesOverview 
                  width={250} 
                  fit={true} 
                />

                <div className="flex flex-col justify-between w-2/5 pr-4 uppercase">
                  <div className="pb-8 leading-none text-left pb-6">
                    <h2 className="text-gray-500 text-xs">
                      Total sales for this year
                    </h2>
                    <div className="text-5xl font-bold">{cardsSummary.total_sales?.year.current || 0}</div>
                  </div>
                  <div className="pb-8 leading-none text-left">
                    <h2 className="text-gray-500 text-xs">Last year sales</h2>
                    <div className="text-3xl font-bold">{cardsSummary.total_sales?.year.previous || 0}</div>
                  </div>
                  <div className="pt-4 text-gray-400 text-right leading-tight">
                    <div className="text-4xl text-red-400">
                      {cardsSummary.total_sales?.year.comparison_percentage || 0}%
                    </div>
                    <div className="text-xs">
                      Lower
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-2 pr-4 pt-8">
              <div className="flex justify-between w-full">
                <div className="w-2/3 pt-2 text-right ml-10">
                  <h3 className="m-0 text-gray-500">Percentage</h3>
                </div>
                <div className="w-1/3 pt-2 text-right">
                  <h3 className="m-0 text-gray-500">Amount</h3>
                </div>
              </div>
              {cardsPurchase === null && 
                <div className="">
                  {loading_cards.map((shimmer, index) => (
                    <Skeleton loading={true} avatar shape="square" active key={index} />
                  ))}
                </div>
              }

              {cardsPurchase.cards?.map((item, index) => (
                <div key={index} className="flex items-center justify-between border-b border-gray-300 w-full">
                  <div className="my-2 w-2/3">
                    <div className="flex--basic">
                      <div className="mr-2">
                        <SavedCard 
                          custom="w-12 h-8 rounded"
                          logo="w-2"
                          fontSize="0.4rem"
                          padding="p-1"
                          card={item}
                        />
                      </div>
                      <Progress
                        percent={item.sales_overview.month.percentage}
                        strokeWidth={10}
                        strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
                      />
                    </div>
                  </div>
                  <div className="w-1/3 flex items-center justify-end font-bold text-lg">
                    <div className="text-right" style={{ minWidth: "4.5rem" }}>
                      <span>GH¢ </span> <span>{item.sales_overview.month.percentage}</span>
                    </div>
                  </div>
                </div>
              ))}
              <section className="w-full flex justify-end items-center">
                <div className="pt-4">
                  <Space size="large">
                    <LeftCircleOutlined className={`${min < 10 ? 'opacity-50 cursor-not-allowed':'cursor-pointer'} text-2xl`} onClick={() => { handleNextPrev('prev')}} />
                    <RightCircleOutlined className={`${max >= cardsPurchase?.length ? 'opacity-50 cursor-not-allowed':'cursor-pointer'} text-2xl`} onClick={() => { handleNextPrev('next')}} />
                  </Space>
                </div>
              </section>              
            </div>
          </div>
        </Col>

        {/* <Col span={6}>
            <div className="p-3 bg-white rounded-lg">
            <div className="flex flex-col justify-between">
              <div className="pb-8 uppercase leading-none text-left pb-6">
                <h2 className="text-gray-500 text-xs">Total sales for this year</h2>
                <div className="text-5xl font-bold">2,356</div>
              </div>
              <div className="pb-8 uppercase leading-none text-left">
                <h2 className="text-gray-500 text-xs">Total sales for this year</h2>
                <div className="text-3xl font-bold">2,356</div>
              </div>
              <div className="pt-4 text-gray-400 text-right leading-tight">
                <div className="text-4xl text-red-400">3.48%</div>
                <div className="text-xs">Since last month</div>
              </div>
            </div>              
            </div>
        </Col> */}
      </Row>
  
      <Modal
        title={<div className="w-full text-center">Security Questions</div>}
        visible={visible}
        // onCancel={() => { setVisible(false) }}
        maskStyle={{top: '10'}}
        wrapClassName="round-modal"
        closable={false}
        footer={false}
      >
            <Form
              name="signup"
              form={form}
              className="w-full signup text-center"
              layout="vertical"
              onFinish={onFinish}
            >
							<>
								<Form.Item  
									// name="question_1"
									// className="mb-1"
									rules={[
										{
											required: true,
											message: 'Please select a question',
										}								
									]}
								>
									<Select
										placeholder="Question 1"
										onChange={handleQuestionSelect}
									>
										<Option>Choose first question</Option>
										{filteredOptions.map((qna, index) => (
											<Option value={qna.ask} key={index}>{qna.ask}</Option>
										))}
									</Select>
								</Form.Item>
								<Form.Item  
									name="answer_1"
									rules={[
										{
											required: true,
											message: 'Please an answer for the selected question',
										}								
									]}									
									// className="mb-1"
								>
									<Input placeholder="Answer" />
								</Form.Item>								
								<Form.Item  
									// name="question_2" 
									// className="mb-1"
									rules={[
										{
											required: true,
											message: 'Please select a question',
										}								
									]}
								>
									<Select
										placeholder="Question 2"
										onChange={handleQuestionSelect}
									>
										<Option>Choose second question</Option>
										{filteredOptions.map((qna, index) => (
											<Option value={qna.ask} key={index}>{qna.ask}</Option>
										))}
									</Select>
								</Form.Item>
								<Form.Item  
									name="answer_2"
									rules={[
										{
											required: true,
											message: 'Please an answer for the selected question',
										}								
									]}									
									// className="mb-1"
								>
									<Input placeholder="Answer" />
								</Form.Item>								
								<Form.Item  
									// name="question_3" 
									// className="mb-1"
									rules={[
										{
											required: true,
											message: 'Please select a question',
										}								
									]}
								>
									<Select
										placeholder="Question 3"
										onChange={handleQuestionSelect}
									>
										<Option>Choose third question</Option>
										{filteredOptions.map((qna, index) => (
											<Option value={qna.ask} key={index}>{qna.ask}</Option>
										))}
									</Select>
								</Form.Item>
								<Form.Item  
									name="answer_3"
									rules={[
										{
											required: true,
											message: 'Please an answer for the selected question',
										}								
									]}									
									// className="mb-1"
								>
									<Input placeholder="Answer" />
								</Form.Item>
								
								<div className="py-4">
									<Button
										htmlType="submit"
										type="primary"
										className="bg-primary w-full uppercase font-semibold"
										shape="round"
										size="large"
										loading={loading}
									>
										Save
									</Button>
								</div>															
							</>
					</Form>
      </Modal>
    </>
  );
}

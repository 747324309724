import React from 'react';
import editIcon from '../assets/images/edit.png';
import deleteIcon from '../assets/images/delete.png';
import { Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';
import { setSetlectedTemplate, deleteCard } from "../store/cards/cards.actions";

const { confirm } = Modal;

const SavedCards = ({ 
    card,
    setcurrent=()=>{}
}) => {
    const dispatch = useDispatch();
    const { data: userInfo }  = useSelector(state => state.user);

    const getDate = (data) => {
        if (data) {
            const carray = data.toString().split(" ");
            return carray[0]
        }
    };

    const showDeleteConfirm = (id) => {
        confirm({
          title: 'Are you sure delete this card?',
          icon: <ExclamationCircleOutlined />,
          content: 'This will remove the card from the drafted list',
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onOk() {
            let ID = userInfo?.person?.companies[0]?.code;
            dispatch(deleteCard(id, ID))
            .then((res) => {
                console.log('Card Removed:', res);
            }, (err) => {
                console.log('Error removing card');
            })
          },
          onCancel() {
            console.log('Cancel');
          },
        });
    };

    const handleEdit = (card) => {
        card.selected = true;
        card.logo_position = card.web_design_props.logo_position
        card.is_update = true

        if (card.web_design_props.type) {
            card.type = card.web_design_props.type
            card.show_amount = false
        }

        dispatch(setSetlectedTemplate({}, card))
        .then(() => {
            setcurrent(1)
        })
    };

    return (
        <div className="relative w-full flex justify-between border-0 bg-transparent">
            <div className="w-1/2 h-24 flex flex-col items-center mx-auto relative">
                <img 
                    id="tempId" 
                    src={card.image} 
                    className="w-full h-full object-cover rounded shadow-lg" 
                    alt=""
                />

                <div className="absolute w-full h-full">
                    {card.show_amount &&
                        <div className="absolute top-0 right-0 p-2"
                            style={{
                                color: `${card.default_color}`,
                                font_weight: `${card.font_weight}`,
                                fontSize: '0.9rem'
                            }}>
                            ¢{card.amount}
                        </div>
                    }
                    {!card.type &&
                        <div className={` ${card.logo_position[0]}-0 ${card?.logo_position[1]?.includes("flex--basic", "center") ? 'inset-y-0 flex--basic' : `${card.logo_position[1]}-0`} absolute p-2`}>
                            {card.logo &&
                                <img src={card.logo} className="w-6 mx-auto" alt="" />
                                // :
                                // <div className="w-12 h-6 flex--basic"
                                //     style={{ 
                                //         color: `${card.default_color}`, 
                                //         borderColor: `${card.default_color}` 
                                //     }}
                                // >
                                //     Logo
                                // </div>
                            }
                        </div>
                    }
                </div>
            </div>
            <div className="w-1/2 flex justify-between">
                <div className="w-10/12 flex flex-col justify-between pl-2">
                    <div className="w-full">
                        <div className="font-bold w-full truncate">{card.title}</div>
                        <div className="font-normal pt-1 text-xs">
                            <div className="truncate">{card.promotion_start && getDate(card.promotion_start)}</div>
                                <div>-</div>
                            <div className="truncate">{card.expiry_date && getDate(card.expiry_date)}</div>
                        </div>
                    </div>
                    <div className="font-bold text-lg pt-1">¢{card.amount}</div>
                </div>
                <div className="w-2/12">
                    <div className="cursor-pointer"
                        onClick={() => { handleEdit(card) }}
                    >
                        <img src={editIcon} className="w-5 mx-auto" alt="" />
                        <div className="text-xs text-center"
                        >Edit</div>
                    </div>
                    <div className="pt-4 cursor-pointer" onClick={() => { showDeleteConfirm(card.id)}}>
                        <img src={deleteIcon} className="w-5 mx-auto" alt="" />
                        <div className="text-xs text-center">Delete</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SavedCards;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Space } from 'antd';

// Images
// import background from '../assets/images/signupStages/1.jpg';

// Components
import WelcomePages from "../components/WelcomePage";

// Redux Actions
import { useDispatch } from 'react-redux';
import { get_Ad_Images } from "../store/user.actions";

const Welcome = () => {
    const dispatch = useDispatch();
    // eslint-disable-next-line no-empty-pattern
    const current = 0;
    const [images, setimages] = useState([]);

    useEffect(() => {
        dispatch(get_Ad_Images({'ad_types[]': 'sign_in'}))
        .then((response) => {
            console.log('Images:', response.data.data.images)
            setimages(response.data.data.images)
        }, () => {
            console.log('Error occured fetching images:');
        })
    }, []);

    return (
            <WelcomePages 
                type="static" 
                single={images.length > 0 && images[0].url}
                current={current}
            >
                <div className="flex flex-col items-center justify-center w-full">
                    <div className="w-6/12 bg-white rounded-large px-4 py-8 text-center shadow--custom">
                        <Space direction="vertical">
                            <div className="font-bold uppercase text-lg pb-2">Welcome</div>
                            <p className="text-xs">Select what you want to do</p>
                        </Space>
                        <div className="flex flex-col items-center">
                            <Button className="bg-secondary text-white w-10/12 my-4 uppercase font-semibold border-0" shape="round" size="large">
                                <Link to="/signin">
                                    Sign In
                                </Link>
                            </Button>
                            <Button type="primary" className="bg-primary w-10/12 uppercase font-semibold" shape="round" size="large">
                                <Link to="/signup">
                                    Sign Up
                                </Link>
                            </Button>
                        </div>
                    </div>
                </div>
            </WelcomePages>
    )
};

export default Welcome;
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Card, Divider, Skeleton, Table, Tag, Tabs, Tooltip, Pagination, Modal, notification, Button } from "antd";
import { ArrowLeftOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import utils from "../../utils/services";

// Components
import SavedCard from "../../components/TemplateCard";
import AddEditCoupon from "src/components/AddEditCoupon";

import { useDispatch } from "react-redux";
import { get_coupons, remove_coupon_code } from "../../store/general/actions";

const { TabPane } = Tabs;
const { confirm } = Modal;

const ShowCoupon = () => {
  const dispatch = useDispatch();
  // const history = useHistory();

  // let saved_details = localStorage.getItem('details') ? JSON.parse(localStorage.getItem('details')) : {}

  const [loading, setloading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [checkAll, setcheckAll] = useState(false)
  const [show, setshow] = useState(false);
  const [saved_details, setsaved_details] = useState(JSON.parse(localStorage.getItem('details') || '{}'));

  const [meta, setmeta] = useState({
    current_page: 1,
    current_page_total: 2,
    total_filtered: 10,
    total_pages: 1,
    total_records: 10
  });
  const [filters, setfilters] = useState({
    limit: "20",
    page: 1,
  });
  const [details, setdetails] = useState();

  const columns = [
    {
      key: "code",
      dataIndex: "code",
      title: "Code",
      render: (text, record) => (
      <Tooltip title={record.shared ? "Redeemed" : ""}>
        <Tag
          className="rounded text-base"
          color={record.shared ? "#7CD838" : "#008000"}
        >
          {text}
        </Tag>
      </Tooltip>      
      ),
    },
    {
      key: "shared",
      dataIndex: "shared",
      title: "Redeemed",
      render: (text) => <div>{text ? 
        <b>Yes</b>  
      : 
        'No'
      }
      </div>,
    },
    {
      key: "duplicated",
      dataIndex: "duplicated",
      title: "Duplicated",
      render: (text) => <div>{text ? 'Yes' : 'No'}</div>,
    },
    {
      key: "limited_expiry",
      dataIndex: "limited_expiry",
      title: "Limited Expiry?",
      render: (text) => <div>{text ? 'Yes' : 'No'}</div>,
    },
    {
      key: "expire_date",
      dataIndex: "expire_date",
      title: "Expiry",
      render: (dateTime) => (
        <div>{dateTime ? moment(dateTime).format("MMMM Do YYYY, h:mm:ss a") : "N/A"}</div>
      )
    }
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (record) => (
    //     <Space size="middle">
    //       <Tooltip title="Edit">
    //         <EditOutlined 
    //           className="p-2 rounded-full hover:bg-gray-200 cursor-pointer"
    //           onClick={() => {
    //             setdetails(record)
    //             console.log('record:', record);
    //             history.push("/coupon/new?mode=edit")
    //           }}            
    //         />
    //       </Tooltip>

    //       {/* {!record.shared && (
    //         <Tooltip title="Delete">
    //           <DeleteOutlined 
    //             className="p-2 rounded-full hover:bg-gray-200 cursor-pointer"
    //             onClick={() => showDeleteConfirm(record) }
    //           />
    //         </Tooltip>
    //       )} */}
    //     </Space>
    //   ), 
    // }   
  ];

  const onChange = (e) => {
    console.log("Change:", e);
  };

  const onShowSizeChange = (current, pageSize) => {
    console.log(current, pageSize);
  };

  const pageChanger = (page, pageSize) => {
    setfilters({ ...filters, page: page, limit: pageSize });
  };

  const getCoupons = (id, cached=true) => {
    setloading(true);
    dispatch(get_coupons(id, cached))
    .then((response) => {
      setdetails(response.data?.data?.coupons)
      localStorage.setItem('details', JSON.stringify(response.data?.data?.coupons));
      setsaved_details(response.data?.data?.coupons);
      setmeta({
        total_filtered: response?.data?.data?.coupons?.total,
        current_page: 1,
        total_pages: 1,
        total_records: response?.data?.data?.coupons?.total        
      });
      setloading(false);
    }, (err) => {
      console.error('Error:', err);
      setloading(false);
    });
  };  

  const removeCoupon = () => {
    setloading(true);

    let params_codes = "";
    selectedRowKeys.forEach((el, index) => {
      params_codes += `${el}${index < selectedRowKeys.length -1 ? ',':''}`
    })

    dispatch(remove_coupon_code(saved_details?.tag_id, { codes: params_codes ?? null }))
    .then((response) => {

      switch (response.status) {
        case 200:
        case 201:
          notification.success({
            message: 'Coupon(s) Code Deleted!'
          });
          setcheckAll(false);
          setSelectedRowKeys([]);
          getCoupons(saved_details.tag_id, false);
          break;
        case 401:
          notification.error({
            message: response.error_msg,
          });
          break;
        case 400:
          notification.error({
            message: response.error_msg,
          });
          break;
        case 500:
          notification.error({
            message: response.error_msg,
          });
          break;

        default:
          break;
      }      
      setloading(false);
    }, () => {
      setloading(false);
      notification.success({
        message: 'Error deleting coupon, please try again'
      })
    })
  }  

  const showDeleteConfirm = () => {
    confirm({
      title: 'Are you sure you want to remove code?',
      icon: <ExclamationCircleOutlined />,
      content: 'Take note, this action is irreversible',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        removeCoupon()
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };  

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('newSelectedRowKeys:', newSelectedRowKeys);

    if (newSelectedRowKeys.length > 0) {
      setcheckAll(true);
    } else {
      setcheckAll(false);
    }
    
    setSelectedRowKeys(newSelectedRowKeys);
  }; 
  
  const handleUpdate = () => {
    setshow(false);
    getCoupons(saved_details.tag_id, false);
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  useEffect(() => {
    getCoupons(saved_details?.tag_id, false)
    
    if (utils.present(saved_details)) {
      setdetails(saved_details);
    }
  }, []);

  return (
    <>
      <Row className="my-6">
        <Col span={24}>
          <Card className="rounded-large shadow-lg min-h-half">
            <Link to="/coupons" className="flex items-center text-lg">
              <ArrowLeftOutlined className="text-lg" />
              <span className="pl-2">Back</span>
            </Link>

            <Divider className="my-2" />

            <Skeleton loading={loading} active>
              {details?.codes?.length <= 0 ? (
                <div className="flex--basic py-5 w-full text-center font-bold" style={{ minHeight: "40vh" }}>
                  <div>No Coupons Info</div>
                </div>
              ) : (
                <Tabs defaultActiveKey="1" onChange={onChange} 
                  tabBarExtraContent={
                    <div className="space-x-4">
                      <Button type="primary" size="middle" className="rounded text-base mb-2"
                        onClick={() => setshow(true)}
                      >
                        Add/Edit Coupon
                      </Button>
                    </div>
                  }
                >
                  <TabPane tab={<div className="text-base">Codes</div>} key="1">
                    <>
                      {checkAll ? 
                        <div className="pb-4 transition duration-500 ease-in-out">
                          <div className=""
                            onClick={() => showDeleteConfirm("Are you sure you want to delete these codes?")} 
                          >
                            <Button className="flex items-center p-2 hover:bg-gray-200 rounded cursor-pointer">
                              <DeleteOutlined className="text-lg pr-2" />
                              Delete
                            </Button>
                          </div> 
                        </div>
                      :''
                      } 

                      <Table
                        columns={columns}
                        className="cursor-pointer"
                        rowSelection={rowSelection}
                        dataSource={details?.codes?.map((el) => {
                          return { ...el, key: el?.code }
                        })}
                        pagination={false}
                        loading={loading}
                      />

                      <div className="pt-3 flex justify-end">
                        <Pagination
                          className="filter-form"
                          showSizeChanger
                          onChange={pageChanger}
                          onShowSizeChange={onShowSizeChange}
                          total={meta.total_records}
                          defaultPageSize={meta.current_page_total}
                          defaultCurrent={meta.current_page}
                        />
                      </div>
                    </>
                  </TabPane>
                  <TabPane tab={<div className="text-base">Info</div>} key="2">
                    <>
                      <div className="border-b border-gray-200 pb-2">
                        <h2 className="py-2 mb-0 text-base font-semibold">Expiry Date:</h2>

                        <div className="flex items-center">
                          <div className="bg-gray-100 rounded-lg p-2">
                            {details?.codes?.length > 0
                              ? details?.codes[0]?.expire_date
                                ? moment(
                                    details?.codes[0]?.expire_date
                                  ).format("MMMM Do YYYY, h:mm:ss a")
                                : "N/A"
                              : "N/A"}
                          </div>
                        </div>
                      </div>

                      <div className="border-b border-gray-200 pb-2">
                        <h2 className="py-2 mb-0 text-base font-semibold">Name</h2>

                        <div className="flex items-center">
                          <div className="bg-gray-100 rounded-lg p-2">
                            {details?.name}
                          </div>
                        </div>
                      </div>

                      <div className="border-b border-gray-200 pb-2">
                        <h2 className="py-2 mb-0 text-base font-semibold">
                          Description
                        </h2>

                        <div className="flex items-center">
                          <div className="bg-gray-100 rounded-lg p-2 capitalize">
                            {details?.description || "N/A"}
                          </div>
                        </div>
                      </div>

                      <div className="border-b border-gray-200 pb-2">
                        <h2 className="py-2 mb-0 text-base font-semibold">
                          Term and Conditions
                        </h2>

                        <div className="flex items-center">
                          <div className="bg-gray-100 rounded-lg p-2 capitalize">
                            {details?.terms_and_conditions || "N/A"}
                          </div>
                        </div>
                      </div>

                      <div className="border-b border-gray-200 pb-2">
                        <h2 className="py-2 mb-0 text-base font-semibold">
                          Date Created
                        </h2>

                        <div className="flex items-center">
                          <div className="bg-gray-100 rounded-lg p-2 capitalize">
                            {details?.created_at
                              ? moment(details?.created_at).format(
                                  "MMMM Do YYYY, h:mm:ss a"
                                )
                              : "N/A"}
                          </div>
                        </div>
                      </div>
                    </>
                  </TabPane>
                  <TabPane tab={<div className="text-base">Associated Cards</div>} key="3">
                    <section className="w-full flex items-stretch flex-wrap">
                      {details?.associated_cards?.length > 0 ?
                        details?.associated_cards.map((card, index) => (
                          <SavedCard 
                            key={index} 
                            card={card} 
                            custom="w-40 h-24 mb-4 mr-4"
                          />
                        ))
                      :
                        <div className="text-base pt-4">No Cards Linked Yet</div>
                      }
                    </section>
                  </TabPane>
                  <TabPane tab={<div className="text-base">Duplicated Codes</div>} key="4">
                    <div className="space-y-4"> 
                      {details?.duplicated_codes.map((record, index) => (
                        <Tag className="rounded text-base mr-4" color="#7CD838" key={index}>{record}</Tag>
                      ))}
                    </div>
                  </TabPane>                  
                </Tabs>
              )}
            </Skeleton>
          </Card>
        </Col>
      </Row>

      <Modal 
        title="Coupons Details" 
        visible={show}
        onCancel={() => setshow(false)}
        className="w-full sm:w-1/2"
        centered
        footer={null}
      >
        <AddEditCoupon 
          mode="EDIT" 
          disableFields={true}
          formStyle="w-full" 
          updateData={saved_details}
          setshow={setshow}
          handleUpdate={handleUpdate}
        />
      </Modal>
    </>
  );
};

export default ShowCoupon;

export const GET_USER = 'GET_USER';
export const SET_USER = 'SET_USER';
export const SIGN_IN = 'SIGN_IN';
export const LOGOUT = 'LOGOUT';
export const SIGN_UP = 'SIGN_UP';
export const RESET = 'RESET';
export const SET_TOKEN = 'SET_TOKEN';
export const GET_TOKEN = 'GET_TOKEN';
export const SET_QUESTION_N_ANSWERS = 'SET_QUESTION_N_ANSWERS';
export const ADD_SIGNUP_SUCCESS = 'ADD_SIGNUP_SUCCESS';
export const ADD_SIGNUP_STARTED = 'ADD_SIGNUP_STARTED';
export const ADD_SIGNUP_FAILURE = 'ADD_SIGNUP_FAILURE';
// export const AWS_BUCKET = 'presigned-prime-user';
export const AWS_BUCKET = 'primeegiftfiles';
export const ACCESS_KEY_ID = 'AKIAU46NLZAEM5CYZ5MR'
export const SECRET_ACCESS_KEY = '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'

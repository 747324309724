import React, { useState, useEffect } from "react";
import actioncable from "actioncable";
import { config } from '../../redux/config';
import { Form, Select, DatePicker, Button, Dropdown, message, notification } from "antd";

import { useDispatch, useSelector } from "react-redux";
import * as ReportActions from "../../store/reports/actions";

const { Option } = Select;
const { RangePicker } = DatePicker;

const ReportFilter = ({ 
    merchants = [],
    showType,
    setLocalFilters=()=>{}
}) => {
  const [form] = Form.useForm();
  const { data } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [filterData, setfilterData] = useState({});
  const [showFilter, setshowFilter] = useState(false);
  const [loading, setloading] = useState(false);
  const [cable, setcable] = useState();
  const [counter, setcounter] = useState(0);

  const filterDrop = [
    {
      name: "Card Redeemed",
      value: "card_redemption",
    },
    {
      name: "Card Sales ",
      value: "card_purchase",
    },
    {
      name: "Cash Outs",
      value: "kick_back",
    },
    {
      name: "Give Back",
      value: "give_back",
    },
    {
      name: "Deposits",
      value: "deposit",
    },
  ];

  const statuses = [
    {
      name: "Initiated",
      value: "initiated",
    },    
    {
      name: "Complete",
      value: "complete",
    },
    {
      name: "Pending",
      value: "pending",
    },
    {
      name: "Failed",
      value: "failed",
    },
  ];

  const filterTable = () => {
    let nonEmptyObj = {};
    for (const property in filterData) {
      console.log(`${property}: ${filterData[property]}`);
      if (
        filterData[property] !== undefined &&
        filterData[property] !== null &&
        filterData[property] !== ""
      ) {
        nonEmptyObj[property] = filterData[property];
      }
    }

    if (showType) {
        dispatch(ReportActions.setBulkFilters("SET_BULK_FILTERS", nonEmptyObj))
    } else {
        setLocalFilters(nonEmptyObj)
    }

    setshowFilter(false)
  };

  const handleGenerateReports = () => {
    OpenConnection()
    
    let nonEmptyObj = {};
    
    for (const property in filterData) {
      console.log(`${property}: ${filterData[property]}`);
      if (
        filterData[property] !== undefined &&
        filterData[property] !== null &&
        filterData[property] !== ""
      ) {
        nonEmptyObj[property] = filterData[property];
      }
    }

    if (!showType) {
      nonEmptyObj['transaction_types[]'] = 'deposit'
    }

    setloading(true);

    setTimeout(() => {
      dispatch(ReportActions.Generate_Report(nonEmptyObj))
      .then((response) => {
        setloading(false);
        console.log('Response:', response)
        notification.success({
          message: 'Report Generation started',
          description: <p></p>,
          onClick: () => { console.log('Notification Clicked!') }
        });      
  
        setshowFilter(false);
      })
      .catch((error) => {
        console.log('Catch error:', error);
        setloading(false);
        notification.error({
          message: 'Error generating report, please try again',
          description: <p>{error.error_msg}</p>,
          onClick: () => { console.log('Notification Clicked!') }
        });      
      })
    }, 1500);

  };  

  const handleSelectChange = (val, type) => {
    // dispatch(ReportActions.setFilters('merchant_ids[]', val))
    // setfilterData({'merchant_ids[]': val})
    switch (type) {
      case "merchant":
        setfilterData((prev) => {
          return { ...prev, "merchant_ids[]": val };
        });
        break;
      case "type":
        setfilterData((prev) => {
          return { ...prev, "transaction_types[]": val };
        });
        break;
      case "status":
        setfilterData((prev) => {
          return { ...prev, "statuses[]": val };
        });
        break;
      case "file_type":
        setfilterData((prev) => {
          return { ...prev, "file_type": val };
        });
        break;        

      default:
        break;
    }

    // setshowFilter(false)
  };

  const handleDateChange = (date, dateStrings) => {
    console.log("DateString:", dateStrings);
    if (dateStrings[0] !== "" && dateStrings[1] !== "") {
    } else {
      message.error("Please select a valid date range.");
    }

    setfilterData((prev) => {
      return { ...prev, from: dateStrings[0], to: dateStrings[1] };
    });
  };

  const handleFilterClear = () => {
    form.resetFields();
    setfilterData({});
  };

  const  handleVisibleChange = flag => {
    setshowFilter(flag)
};

  const FilterPane = () => {
    return (
      <div className="w-full pb-4 px-3 shadow--perfect rounded h-50v bg-white">
        <div className="w-full flex justify-between items-end">
          <h2 className="font-bold text-base pt-4">Filter</h2>
          <div
            className="text-xs mb-2 cursor-pointer font-bold"
            onClick={handleFilterClear}
          >
            Clear
          </div>
        </div>
        <Form
          form={form}
          name="filter"
          className="w-full filter-form"
          onFinish={filterTable}
        >
          <div className="flex items-center">
            <div className="w-56">
              <Form.Item name="status" label="" className="w-full pr-4 mb-0">
                <Select
                  placeholder="Status"
                  onChange={(e) => {
                    handleSelectChange(e, "status");
                  }}
                  allowClear
                >
                  {statuses.map((item) => (
                    <Option value={item.value}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="w-56">
              <Form.Item name="date" label="" className="w-full mb-0">
                <RangePicker onChange={handleDateChange} />
              </Form.Item>
            </div>
          </div>
          <div className="flex items-center pt-4">
            <div className="w-56">
              <Form.Item name="merchant" label="" className="w-full pr-4 mb-0">
                <Select
                  placeholder="Merchant"
                  onChange={(e) => {
                    handleSelectChange(e, "merchant");
                  }}
                  allowClear
                >
                  {merchants.map((item) => (
                    <Option value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            {showType ? (
              <div className="w-56">
                <Form.Item name="type" label="" className="w-full mb-0">
                  <Select
                    placeholder="Select type"
                    onChange={(e) => {
                      handleSelectChange(e, "type");
                    }}
                    allowClear
                  >
                    {filterDrop.map((item) => (
                      <Option value={item.value}>{item.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            ) : (
              <div className="w-56">
                <Form.Item name="file_type" label="" className="w-full mb-0">
                  <Select
                    placeholder="File Type (*For Reports)"
                    onChange={(e) => {
                      handleSelectChange(e, "file_type");
                    }}
                    allowClear
                  >
                    <Option value="csv">CSV</Option>
                    <Option value="pdf">PDF</Option>
                  </Select>
                </Form.Item>
              </div>
            )}
          </div>
          {showType && (
            <div className="flex items-center justify-end pt-4">
              <div className="w-56">
                <Form.Item name="file_type" label="" className="w-full mb-0">
                  <Select
                    placeholder="File Type (*For Reports)"
                    onChange={(e) => {
                      handleSelectChange(e, "file_type");
                    }}
                    allowClear
                  >
                    <Option value="csv">CSV</Option>
                    <Option value="pdf">PDF</Option>
                  </Select>
                </Form.Item>
              </div>
            </div>
          )}
          <div className="flex items-center justify-end mt-4 border-t border-primary pt-4">
            <Button
              htmlType="submit"
              type="primary"
              className="bg-primary rounded uppercase font-semibold"
              size="middle"
            >
              Search
            </Button>
            <Button
              type="default"
              className="text-primary rounded uppercase font-semibold ml-4"
              size="middle"
              onClick={handleGenerateReports}
              loading={loading}
            >
              Generate Report
            </Button>
          </div>
        </Form>
      </div>
    );
  };

  const DownloadType = () => {
    return (
      <div className="w-full flex--basic p-4 shadow--perfect rounded bg-white">
        <Button
          type="primary"
          className="bg-secondary rounded uppercase font-semibold w-20 mr-2 border-none"
          size="middle"
          onClick={() => { handleGenerateReports('CSV') }}
        >
          CSV
        </Button>
        <Button
          type="primary"
          className="bg-secondary rounded uppercase font-semibold w-20 ml-2 border-none"
          size="middle"
          onClick={() => { handleGenerateReports('PDF') }}
        >
          PDF
        </Button>                    
      </div>
    )
  };

  const exportcsv = (data, fileName) => {
    // let header = arrayHeader.join(delimiter) + '\n';
    // let csv = header;
    // arrayData.forEach( array => {
    //     csv += array.join(delimiter)+"\n";
    // });

    let csvData = new Blob([data], { type: 'text/csv' });  
    let csvUrl = URL.createObjectURL(csvData);

    let hiddenElement = document.createElement('a');
    hiddenElement.href = csvUrl;
    hiddenElement.target = '_blank';
    hiddenElement.download = fileName + '.csv';
    hiddenElement.click();
  };  

  const handleDownloadedReport = (report, reportInfo) => {
    if (filterData.file_type === 'csv') {
      exportcsv(report, `Reports_${reportInfo.id}`)
    } else {
      notification.info({
        message: 'Not Available',
        description: 'The selected type is not availabe at the moment'
      })
    }

    notification.close(key);
  };  

  const key = `open${Date.now()}`;  

  const OpenConnection = () => {
    cable.subscriptions.create({
      channel: `ReportsChannel`,
      user_id: data.id
    }, 
    {
      connected: () => {
        console.log("connected!");
      },
      disconnected: () => {
        console.log('Disconneted!')
      },
      received: data => {
        console.log('Received data from socket!')
        setcounter(counter+1);
        console.log('Received Data:', data);

        if (data.message.report.status === "generated") {
          if (counter === 0) {
            dispatch(ReportActions.Download_Report(data.message.report.id))
            .then((res) => {
              // console.log('Download link:', res)
              // exportcsv(res, `Reports_${data.message.report.id}`)

              notification.success({
                duration: 0,
                message: 'Report Generated',
                description: <p>Reported generation completed, kindly click to download report.</p>,
                btn: 
                  // <a href={`${config.apiUrl}/merchants/reports/${data.message.report.id}/download`} 
                  //   target="_blank" 
                  //   rel="noopener noreferrer" 
                  //   className="p-2 bg-primary rounded text-white"
                  //   download
                  // >
                  //   Download
                  // </a>
                  <Button 
                    type="primary" 
                    className="bg-primary rounded text-white"
                    onClick={() => {
                      handleDownloadedReport(res, data.message.report)
                    }}
                  >
                    Dowloand
                  </Button>,
                key
              })        
            })
            .catch(() => {
              notification.error({
                message: 'Error downloading report',
                description: 'There was an error downloading report file, please try again or choose an alternative file type'
              })
            })              
          }
        }
      }
    })    
  };

  useEffect(() => {
    // Mount cable
    setcable(actioncable.createConsumer(config.ws))
  }, [])  

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div className="tag bg-secondary rounded-r-full py-2 w-auto mr-2">
            <span className="text-white text-base px-4 w-full block">
              Detail Transactions
            </span>
          </div>
          <Dropdown
            overlay={FilterPane}
            overlayStyle={{ marginTop: "4rem" }}
            onVisibleChange={handleVisibleChange}
            visible={showFilter}
            trigger={["click"]}
            placement="bottomCenter"
            arrow
          >
            <img
              src={require("../../assets/images/filter.png")}
              className="w-6 h-full cursor-pointer"
              alt=""
              onClick={() => {
                setshowFilter(!showFilter);
              }}
            />
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default ReportFilter;

import React from 'react'
import { Form, Select, Button } from 'antd'
const { Option }  = Select;

const ChangeMerchantStatus = ({
    handleChange=()=>{},
    loading=false
}) => {
    const [form] = Form.useForm();
    const statusOptions = [
        {
            name: "Activate",
            value: "activate"
        },
        {
            name: "Deactivate",
            value: "suspend"
        },
        {
            name: "Under Review",
            value: "under_review"
        }
    ];

    const onFinish = (values) => {
        console.log('Form values:', values);
        if (form.getFieldValue(["status"])) {
            handleChange(form.getFieldValue(["status"]))
        }
    };

    return (
        <Form
            name="changestatus" 
            className="filter-form"
            form={form}
            layout="vertical"
            onFinish={onFinish} 
        >
            <Form.Item name="status" label="Status">
                <Select placeholder="Status">
                    {statusOptions.map((status, index) => (
                        <Option value={status.value} key={index}>{status.name}</Option>
                    ))}
                </Select>
            </Form.Item>        
            <div className="pt-4 flex--basic">
                <Button
                    htmlType="submit"
                    type="primary"
                    className="bg-primary w-1/2 h-8 flex--basic uppercase font-semibold"
                    shape="round"
                    size="small"
                    loading={loading}
                >
                    Change Status
                </Button>
            </div>  
        </Form>        
    )
}

export default ChangeMerchantStatus

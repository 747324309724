import React, { useState, useEffect } from 'react';
import { Card } from 'antd';

import clientLogo from '../assets/images/papaye.png'

const GiftCards = (props) => {
  const [back, setback] = useState('#018DF6');
  
  useEffect(() => {
    console.log('amount:', props.info.amount);

    switch (props.info.amount) {
      case '25':
        setback('#f55043')
        break;
      case '50':
        setback('#e5d625')
        break;
      case '100':
        setback('#40dbe8')
        break;
      case '150':
        setback('#fa7dec')
        break;
      case '200':
        setback('39ad39')
        break;
      case '250':
        setback('#5439ad')
        break;
      default:
        setback('#018DF6')
        break;
    }
  }, [props.info.amount])

    return (
      <div className="gift_cards">
        <Card className="flex justify-center mr-3 border-0 bg-transparent">
          <Card.Grid className="bg-cover bg-no-repeat w-40 h-auto p-0 rounded-lg" style={{background: back}}>
            <div className="m-1 pt-1 relative flex flex-col items-center">
              <img src={clientLogo} className="absolute top mb-8 h-10" alt=""/>
              <img src={props.info.img} className="mt-8 h-10" alt=""/>
              <div className="absolute bottom-0 right-0 font-semibold text-white">₵{props.info.amount}</div>
            </div>
          </Card.Grid>
        </Card>
        <div className="text-center text-white font-bold text-lg mt-4 mr-3">{props.info.id}</div>
      </div>
  )
};

export default GiftCards;
import React from 'react';

export default function name () {
    

    return (
        <>
            <div className="bg-white p-6 rounded">
                <h1>Notification</h1>
            </div>            
        </>
    )
}

import { SET_CARDS, SET_ALL_CARDS, GET_TEMPLATES,SET_CAMPAIGNS, SET_ALL_TEMPLATES, CHANGE_CARD_STATE,
    SET_CURRENT, RESET_SELECTED, SET_SELECTED_TEMPLATE, SET_TEMPLATE_INFO, SET_EDIT, REMOVE_CARD, EMPTY_E_CARDS
} from './cards.types';

const INITIAL_STATE = {
    current: 0,
    templates: [
        {
            cat_id: 1,
            category: "CHRISTMAS",
            gallery: [
                {
                    id: "ch_1",
                    amount: "0",
                    picture: require("../../assets/images/Templates/Christmas/1.png"),
                    selected: false,
                    logo: "",
                    default_color: '#fff',
                    font_weight: 'normal',
                    disable_image_upload: true,
                    logo_pos: ['bottom', 'left'],
                    is_promotion: null,
                    promo_type: null,
                    promotion_title: null,
                    promotion_start: null,
                    expiry_date: null,
                    promo_description: null,
                    promo_terms: null             
                },
                {
                    id: "ch_2",
                    amount: "0",
                    picture: require("../../assets/images/Templates/Christmas/2.png"),
                    selected: false,
                    logo: "",
                    default_color: '#fff',
                    font_weight: 'normal',
                    disable_image_upload: true,
                    logo_pos: ['bottom', 'left'],
                    is_promotion: null,
                    promo_type: null,
                    promotion_title: null,
                    promotion_start: null,
                    expiry_date: null,
                    promo_description: null,
                    promo_terms: null                    
                },
                {
                    id: "ch_3",
                    amount: "0",
                    picture: require("../../assets/images/Templates/Christmas/3.png"),
                    selected: false,
                    logo: "",
                    default_color: '#fff',
                    font_weight: 'normal',
                    disable_image_upload: true,
                    logo_pos: ['bottom', 'right']                    ,
                    is_promotion: null,
                    promo_type: null,
                    promotion_title: null,
                    promotion_start: null,
                    expiry_date: null,
                    promo_description: null,
                    promo_terms: null                    
                },
            ]
        },
        {
            cat_id: 2,
            category: "MUSLIM HOLIDAY",
            gallery: [
                {
                    id: "mus_1",
                    amount: "0",
                    picture: require("../../assets/images/Templates/Muslim Holidays/1.png"),
                    selected: false,
                    logo: "",
                    default_color: '#000',
                    font_weight: 'bold',
                    disable_image_upload: true,
                    logo_pos: ['bottom', 'left']                    ,
                    is_promotion: null,
                    promo_type: null,
                    promotion_title: null,
                    promotion_start: null,
                    expiry_date: null,
                    promo_description: null,
                    promo_terms: null                    
                },
                {
                    id: "mus_2",
                    amount: "0",
                    picture: require("../../assets/images/Templates/Muslim Holidays/2.png"),
                    selected: false,
                    logo: "",
                    default_color: '#fff',
                    font_weight: 'normal',
                    disable_image_upload: true,
                    logo_pos: ['top', 'left']                    ,
                    is_promotion: null,
                    promo_type: null,
                    promotion_title: null,
                    promotion_start: null,
                    expiry_date: null,
                    promo_description: null,
                    promo_terms: null                    
                },
                {
                    id: "mus_3",
                    amount: "0",
                    picture: require("../../assets/images/Templates/Muslim Holidays/3.png"),
                    selected: false,
                    logo: "",
                    default_color: '#000',
                    font_weight: 'normal',
                    disable_image_upload: true,
                    logo_pos: ['bottom', 'left']                    ,
                    is_promotion: null,
                    promo_type: null,
                    promotion_title: null,
                    promotion_start: null,
                    expiry_date: null,
                    promo_description: null,
                    promo_terms: null                    
                },
            ]
        },
        {
            cat_id: 3,
            category: "FATHERS DAY",
            gallery: [
                {
                    id: "fat_1",
                    amount: "0",
                    picture: require("../../assets/images/Templates/Fathers Day/1.png"),
                    selected: false,
                    logo: "",
                    default_color: '#000',
                    font_weight: 'bold',
                    disable_image_upload: true,
                    logo_pos: ['bottom', 'left']                    ,
                    is_promotion: null,
                    promo_type: null,
                    promotion_title: null,
                    promotion_start: null,
                    expiry_date: null,
                    promo_description: null,
                    promo_terms: null                    
                },
                {
                    id: "fat_2",
                    amount: "0",
                    picture: require("../../assets/images/Templates/Fathers Day/2.png"),                    selected: false,
                    logo: "",
                    default_color: '#fff',
                    font_weight: 'bold',
                    disable_image_upload: true,
                    logo_pos: ['top', 'left']                    ,
                    is_promotion: null,
                    promo_type: null,
                    promotion_title: null,
                    promotion_start: null,
                    expiry_date: null,
                    promo_description: null,
                    promo_terms: null                    
                },
                {
                    id: "fat_3",
                    amount: "0",
                    picture: require("../../assets/images/Templates/Fathers Day/3.png"),
                    selected: false,
                    logo: "",
                    default_color: '#fff',
                    font_weight: 'normal',
                    disable_image_upload: true,
                    logo_pos: ['bottom', 'left']                    ,
                    is_promotion: null,
                    promo_type: null,
                    promotion_title: null,
                    promotion_start: null,
                    expiry_date: null,
                    promo_description: null,
                    promo_terms: null                    
                },
            ]
        },
        {
            cat_id: 4,
            category: "MOTHERS DAY",
            gallery: [
                {
                    id: "mot_1",
                    amount: "0",
                    picture: require("../../assets/images/Templates/Mothers Day/1.png"),
                    selected: false,
                    logo: "",
                    default_color: '#fff',
                    font_weight: 'bold',
                    disable_image_upload: true,
                    logo_pos: ['bottom', 'left']                    ,
                    is_promotion: null,
                    promo_type: null,
                    promotion_title: null,
                    promotion_start: null,
                    expiry_date: null,
                    promo_description: null,
                    promo_terms: null                    
                },
                {
                    id: "mot_2",
                    amount: "0",
                    picture: require("../../assets/images/Templates/Mothers Day/2.png"),
                    selected: false,
                    logo: "",
                    default_color: '#fff',
                    font_weight: 'bold',
                    disable_image_upload: true,
                    logo_pos: ['bottom', 'left']                    ,
                    is_promotion: null,
                    promo_type: null,
                    promotion_title: null,
                    promotion_start: null,
                    expiry_date: null,
                    promo_description: null,
                    promo_terms: null                    
                },
                {
                    id: "mot_3",
                    amount: "0",
                    picture: require("../../assets/images/Templates/Mothers Day/3.png"),
                    selected: false,
                    logo: "",
                    default_color: '#000',
                    font_weight: 'normal',
                    disable_image_upload: true,
                    logo_pos: ['bottom', 'left']                    ,
                    is_promotion: null,
                    promo_type: null,
                    promotion_title: null,
                    promotion_start: null,
                    expiry_date: null,
                    promo_description: null,
                    promo_terms: null                    
                },
            ]
        },
        {
            cat_id: 5,
            category: "WEDDING",
            gallery: [
                {
                    id: "wed_1",
                    amount: "0",
                    picture: require("../../assets/images/Templates/Wedding/1.png"),
                    selected: false,
                    logo: "",
                    default_color: '#fff',
                    font_weight: 'bold',
                    disable_image_upload: true,
                    logo_pos: ['bottom', 'right']                    ,
                    is_promotion: null,
                    promo_type: null,
                    promotion_title: null,
                    promotion_start: null,
                    expiry_date: null,
                    promo_description: null,
                    promo_terms: null                    
                },
                {
                    id: "wed_2",
                    amount: "0",
                    picture: require("../../assets/images/Templates/Wedding/2.png"),
                    selected: false,
                    logo: "",
                    default_color: '#fff',
                    font_weight: 'normal',
                    disable_image_upload: true,
                    logo_pos: ['bottom', 'left']                    ,
                    is_promotion: null,
                    promo_type: null,
                    promotion_title: null,
                    promotion_start: null,
                    expiry_date: null,
                    promo_description: null,
                    promo_terms: null                    
                },
                {
                    id: "wed_3",
                    amount: "0",
                    picture: require("../../assets/images/Templates/Wedding/3.png"),
                    selected: false,
                    logo: "",
                    default_color: '#fff',
                    font_weight: 'normal',
                    disable_image_upload: true,
                    logo_pos: ['bottom', 'left']                    ,
                    is_promotion: null,
                    promo_type: null,
                    promotion_title: null,
                    promotion_start: null,
                    expiry_date: null,
                    promo_description: null,
                    promo_terms: null                    
                },
            ]
        },
        {
            cat_id: 8,
            category: "BABY SHOWER",
            gallery: [
                {
                    id: "bab_1",
                    amount: "0",
                    picture: require("../../assets/images/Templates/Baby Shower/1.png"),
                    selected: false,
                    logo: "",
                    default_color: '#fff',
                    font_weight: 'bold',
                    disable_image_upload: true,
                    logo_pos: ['right', 'h-full flex--basic ']                    ,
                    is_promotion: null,
                    promo_type: null,
                    promotion_title: null,
                    promotion_start: null,
                    expiry_date: null,
                    promo_description: null,
                    promo_terms: null                    
                },
                {
                    id: "bab_2",
                    amount: "0",
                    picture: require("../../assets/images/Templates/Baby Shower/2.png"),
                    selected: false,
                    logo: "",
                    default_color: '#fff',
                    font_weight: 'normal',
                    disable_image_upload: true,
                    logo_pos: ['bottom', 'left']                    ,
                    is_promotion: null,
                    promo_type: null,
                    promotion_title: null,
                    promotion_start: null,
                    expiry_date: null,
                    promo_description: null,
                    promo_terms: null                    
                },
                {
                    id: "bab_3",
                    amount: "0",
                    picture: require("../../assets/images/Templates/Baby Shower/3.png"),
                    selected: false,
                    logo: "",
                    default_color: '#0095dd',
                    font_weight: 'normal',
                    disable_image_upload: true,
                    logo_pos: ['bottom', 'center']                    ,
                    is_promotion: null,
                    promo_type: null,
                    promotion_title: null,
                    promotion_start: null,
                    expiry_date: null,
                    promo_description: null,
                    promo_terms: null                    
                },
            ]
        },
        {
            cat_id: 6,
            category: "BIRTHDAY",
            gallery: [
                {
                    id: "bir_1",
                    amount: "0",
                    picture: require("../../assets/images/Templates/Birthday/1.png"),
                    selected: false,
                    logo: "",
                    default_color: '#000',
                    font_weight: 'bold',
                    disable_image_upload: true,
                    logo_pos: ['bottom', 'center']                      ,
                    is_promotion: null,
                    promo_type: null,
                    promotion_title: null,
                    promotion_start: null,
                    expiry_date: null,
                    promo_description: null,
                    promo_terms: null                    
                },
                {
                    id: "bir_2",
                    amount: "0",
                    picture: require("../../assets/images/Templates/Birthday/2.png"),
                    selected: false,
                    logo: "",
                    default_color: '#fff',
                    font_weight: 'normal',
                    disable_image_upload: true,
                    logo_pos: ['bottom', 'left']                      ,
                    is_promotion: null,
                    promo_type: null,
                    promotion_title: null,
                    promotion_start: null,
                    expiry_date: null,
                    promo_description: null,
                    promo_terms: null                    
                },
                {
                    id: "bir_3",
                    amount: "0",
                    picture: require("../../assets/images/Templates/Birthday/3.png"),
                    selected: false,
                    logo: "",
                    default_color: '#000',
                    font_weight: 'bold',
                    disable_image_upload: true,
                    logo_pos: ['top', 'left']                      ,
                    is_promotion: null,
                    promo_type: null,
                    promotion_title: null,
                    promotion_start: null,
                    expiry_date: null,
                    promo_description: null,
                    promo_terms: null                    
                },
            ]
        },
        {
            cat_id: 7,
            category: "VALENTINES DAY",
            gallery: [
                {
                    id: "val_1",
                    amount: "0",
                    picture: require("../../assets/images/Templates/Valentines Day/1.png"),
                    selected: false,
                    logo: "",
                    default_color: '#000',
                    font_weight: 'bold',
                    disable_image_upload: true,
                    logo_pos: ['bottom', 'right']                      ,
                    is_promotion: null,
                    promo_type: null,
                    promotion_title: null,
                    promotion_start: null,
                    expiry_date: null,
                    promo_description: null,
                    promo_terms: null                    
                },
                {
                    id: "val_2",
                    amount: "0",
                    picture: require("../../assets/images/Templates/Valentines Day/2.png"),
                    selected: false,
                    logo: "",
                    default_color: '#000',
                    font_weight: 'normal',
                    disable_image_upload: true,
                    logo_pos: ['bottom', 'left']                      ,
                    is_promotion: null,
                    promo_type: null,
                    promotion_title: null,
                    promotion_start: null,
                    expiry_date: null,
                    promo_description: null,
                    promo_terms: null                    
                },
                {
                    id: "val_3",
                    amount: "0",
                    picture: require("../../assets/images/Templates/Valentines Day/3.png"),
                    selected: false,
                    logo: "",
                    default_color: '#000',
                    font_weight: 'normal',
                    disable_image_upload: true,
                    logo_pos: ['top', 'left']                      ,
                    is_promotion: null,
                    promo_type: null,
                    promotion_title: null,
                    promotion_start: null,
                    expiry_date: null,
                    promo_description: null,
                    promo_terms: null                    
                },
            ]
        }
    ],
    selected: null,
    eCards: [],
    cards: [],
    campaigns: [],
    cardCategories: [],
    edit: false
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_ALL_TEMPLATES:
            return {
                ...state, templates: action.payload
        }        
        case GET_TEMPLATES:
           return {
                ...state,
           };

        case SET_SELECTED_TEMPLATE:
            // console.log('Category :', action.payload.template)
            let newArray = [...state.templates];
            
            if (action.payload.template.category) {
                const elementsIndex = state.templates.findIndex(element => element.category === action.payload.template.category);
                let itemIndex;
    
                newArray[elementsIndex].gallery.forEach((element, index) => {
                    if(element.id === action.payload.category.id) {
                        itemIndex = index;
                        newArray[elementsIndex].gallery[itemIndex].selected = !newArray[elementsIndex].gallery[itemIndex].selected 
                    } else {
                        newArray[elementsIndex].gallery[index].selected = false
                    }
                });
            }

            let selectedCard = Object.assign({}, action.payload.category);
            selectedCard.category = action.payload.template.category

            // newArray[elementsIndex].gallery[itemIndex].selected = !newArray[elementsIndex].gallery[itemIndex].selected 

           return {
                ...state, 
                templates: newArray, 
                selected: selectedCard
           };

        case SET_CARDS:
            // 1. push currently selected card into eCards
                const SavedCards = state.eCards.slice(0);
                SavedCards.push(action.payload ? action.payload : state.selected);
            return {
               ...state, eCards: SavedCards
        }
        case SET_CURRENT:
            return {
               ...state, current: action.payload
            }
        case SET_EDIT:
            return {
               ...state, edit: action.payload
            }

        case RESET_SELECTED:
            return {
               ...state, selected: null
        }

        case SET_TEMPLATE_INFO:
            const updatedSelected = state.selected
            updatedSelected[action.payload.type] = action.payload.data

           return {
                ...state, selected: updatedSelected
           };

        case REMOVE_CARD:
           return {
                ...state, eCards: state.eCards.filter((el => el.id !== action.payload))
           };

        case EMPTY_E_CARDS:
           return {
                ...state, eCards: []
           };

        case SET_ALL_CARDS:
            console.log('Payload:', action.payload)
            return {
                ...state, cards: action.payload
        }         
        case SET_CAMPAIGNS:
            console.log('Campaigns Payload:', action.payload)
            return {
                ...state, campaigns: action.payload
        }         

        default: return state;
    }

};

export default reducer;


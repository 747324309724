import * as types from './types';
import { reportService } from '../../_services/reportService';

export const Reports_Stats = () => {
    return dispatch => {
        return reportService.getStats()
        .then(res => {
            console.log('Reports payload:', res);
            return res;
        }, err => {
          console.log('err', err);
            // dispatch(addSignupFailure(err.response.data));
            return err;
        });   
    };
};

export const Get_Transastions = (filters, save=false) => {
    return dispatch => {
        return reportService.getTransactions(filters)
        .then(res => {
            // console.log('Transactions:', res);
            if (save) {
                dispatch({
                    type: types.SET_TRANSACTION,
                    payload: res.data.data
                });
            }
            return res;
        }, err => {
          console.log('err', err);
            // dispatch(addSignupFailure(err.response.data));
            return err;
        });   
    };
};

export const Get_Deposit_Details = () => {
    return dispatch => {
        return reportService.getDepositDetails()
        .then(res => {
            // console.log('Merchants:', res.data);
            // dispatch({
            //     type: SET_MERCHANTS,
            //     payload: res.data.data
            // });
            return res.data.data;
        }, err => {
          console.log('err', err);
            return err;
        });   
    };
};

export const Generate_Report = (filter) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            reportService.generateReports(filter)
            .then(res => {
                console.log('Generating Report:', res.data);
                // return res.data;
                resolve(res.data)
            }
            // , err => {
            //     console.log('Then error', err);
            //     return err;
            // }
            )
            .catch((error) => {
                console.log('Catch error:', error);
                // return error;
                reject(error)
            })
        })
    };
};

export const Report_details = (ID) => {
    return dispatch => {
        return reportService.generateReports(ID)
        .then(res => {
            console.log('Report Details:', res.data);
            // dispatch({
            //     type: SET_MERCHANTS,
            //     payload: res.data.data
            // });
            return res.data;
        }, err => {
          console.log('err', err);
            return err;
        });   
    };
};

export const Download_Report = (ID) => {
    return dispatch => {
        return reportService.downloadGeneratedReport(ID)
        .then(res => {
            console.log('Download URL:', res.data);
            return res.data;
        }, err => {
          console.log('err', err);
            return err;
        })
    };
};

export const setFilters = (type, data) => {    
    return {
        type: types.SET_FILTERS,
        payload: { type, data }
    }
};
export const setBulkFilters = (type, data) => {    
    return {
        type: types.SET_BULK_FILTERS,
        payload: {type, data}
    }
};
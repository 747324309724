import React, {useState } from 'react';
import { Link,  useHistory } from 'react-router-dom';
import { Form, Input, Button, message } from 'antd';
// Images
import background from '../assets/images/background.png';
import logo from '../assets/images/logo.svg';
// import backIcon from '../assets/images/back.svg';

// Redux Actions
import { useDispatch, useSelector } from 'react-redux';
import { verification, verify_otp } from "../store/user.actions";

const Verification = () => {
	const [form] = Form.useForm();
	const history = useHistory();
	const [formData, setFormData] = useState({
        telephone: "",
        code: ""
	});
    const [loading, setloading] = useState(false);
    const [current, setcurrent] = useState(0)
	
	const dispatch = useDispatch();

	const onFinish = () => {
		setloading(true);

        form.validateFields()
        switch (current) {
            case 0:
                dispatch(verification({telephone: formData.telephone }))
                .then(
                    res => { 
                        setloading(false);
                        const { status } = res;
                        console.log('Status:', status);
                        
                        switch (status) {
                            case 200:
                                message.success("Please enter the code you just received")
                                setcurrent(1);
                                break;
                            case 401:
                                message.error(res.error_msg + '. Please provide accurate login data');
                                break;
                            case 400:
                                message.error(res.error_msg)
                                break;
                            case 404:
                                message.error(res.error_msg)
                                break;
                            default:
                                setcurrent(0);
                                break;
                        }              
                    }
                );                
                break;

            case 1:
                dispatch(verify_otp(formData.code, {telephone: formData.telephone }))
                .then(
                    res => { 
                        setloading(false);
                        const { status } = res;
                        console.log('Status:', status);
        
                        switch (status) {
                            case 201:
                                message.success("Phone number verified successfully");
                                setTimeout(() => {
                                    history.push('/signin');
                                }, 2000);
                                break;
                            case 401:
                                message.error(res.error_msg + '. Please provide accurate login data');
                                break;
                            case 400:
                                message.error(res.error_msg)
                                break;
                            case 404:
                                message.error(res.error_msg)
                                break;
                            default:
                                break;
                        }              
                    }
                );                
                break;
        
            default:
                break;
        }			
	};
	const handleInput = (val) => {
		setFormData({
			...formData,
			[Object.keys(val)[0]]: Object.values(val)[0]
		});
	};

	return (
        <div className="h-screen w-screen bg-white">
            <div className="h-8 sm:h-12 mb-3 bg-primary w-full" />
			<div className="flex flex-col items-center justify-center w-full">
				<div className="pb-4 flex items-end">
                    <Link to="/">
						<img src={logo} className="w-24" alt="prime_logo" />
					</Link>
					<span className="text-gray-500 font-bold font-sm pb-3 -ml-3">e-gift <br/>card</span>
				</div>
				<div className="py-4">
					<h1 className="text-lg m-0 text-primary font-thin uppercase tracking-wide leading-snug">Merchant Dashboard</h1>
					<div className="text-xs text-center text-primary pb-4">Your eGift Card Platform</div>
				</div>
				<div className="w-1/4 bg-white rounded-large px-8 py-6 text-center shadow-2xl">
					<div className="font-bold text-lg uppercase pb-6 relative">
						<span>Verify 
                            {current === 0 && ' Phone'}
                            {current === 1 && ' OTP Code'}
                        </span>
					</div>
					<Form
						name="custom"
						form={form}
						className="w-full signup"
						layout="vertical"
						onFinish={onFinish}
						onValuesChange={handleInput}
					>
                        {current === 0 &&
                            <>
                                <Form.Item 
                                    name="telephone"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a phone number to verify',
                                        }								
                                    ]}								
                                >
                                    <Input 
                                        placeholder="phone"
                                    />
                                </Form.Item>

                                {/* <Form.Item  
                                name="verify_option" 
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select how to get verified',
                                    }								
                                ]}
                                >
                                <Select
                                    placeholder="Select verification medium"
                                    allowClear
                                >
                                    <Option value="email">Email</Option>
                                    <Option value="sms">Phone</Option>
                                </Select>
                                </Form.Item>  */}
                            </>
                        }

                        {current === 1 &&
                            <Form.Item 
                                name="code"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter the verification code',
                                    }								
                                ]}								
                            >
                                <Input 
                                    placeholder="Verification code"
                                />
                            </Form.Item>
                        }
                        
						<Form.Item className="pt-6">
							<Button
								htmlType="submit"
								type="primary"
								className="bg-primary w-full uppercase font-semibold"
								shape="round"
								size="large"
								loading={loading}
							>
								Verify
                                {current === 0 && ' Phone'}
                                {current === 1 && ' OTP Code'}
							</Button>
						</Form.Item> 
					</Form>
					<div className="flex--basic pt-2">
						<Link to="/">
							<span className="px-2 hover:underline">Welcome</span>
						</Link>
						|
						<Link to="/signin">
							<span className="px-2 hover:underline">Signin</span>
						</Link>		
					</div>
				</div>
			</div>
		</div>
	)
};

export default Verification;
import { GET_MERCHANTS, SET_MERCHANTS, SET_FILTERS, ADD_SETUP_STARTED, ADD_SETUP_COMPLETED } from './types';

const INITIAL_STATE = {
    loading: false,
    merchants: [],
    meta: {},
    filters: {
        page: 1,
        limit: 20,
        // from: '',
        // to: '',
        // search_string: '',
        // branches: [],
        // statuses: []
    }    
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {    
        case GET_MERCHANTS:
           return {
                ...state, merchants: action.payload
           };
        case SET_MERCHANTS:
           return {
                ...state, 
                merchants: action.payload.merchants,
                meta: action.payload
            };
        case SET_FILTERS:
            let newFilters = Object.assign({}, state.filters);
            newFilters[action.payload.type] = action.payload.data
        return {
                ...state, 
                filters: newFilters
            };
        case ADD_SETUP_STARTED:
        return {
            ...state,
            loading: true
        };                
        case ADD_SETUP_COMPLETED:
        return {
            ...state,
            loading: false
        };                

        default: return state;
    }

};

export default reducer;


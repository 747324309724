import React from "react";
import AddEditCoupon from "src/components/AddEditCoupon";

const AddCoupon = () => {
  console.log('new page reloaded!!')
  return (
    <section className="w-full bg-white rounded p-5">
      <AddEditCoupon 
        mode="ADD" 
        formStyle="mx-auto w-full sm:w-1/2" 
      />
    </section>
  )
}

export default AddCoupon;

import React, { useState } from "react";
import { Modal, Form, Input, Button, message } from "antd";

import { useDispatch } from "react-redux";
import { request_funds } from "../store/general/actions";

const RequestFunds = ({ 
    show = false, 
    setShow = () => {},
    handleSentRequest=()=>{}
}) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [loading, setloading] = useState();

    const onFinish = () => {

        try {
            form.validateFields()

            setloading(true)
            let data = form.getFieldsValue();

            dispatch(request_funds(data))
            .then((res) => {
				const { status, error_msg } = res;
				console.log('Status:', status);
                switch (status) {
                    case 200:
                        message.success('Request for funds sent successfully')
                        handleSentRequest()                        
                        break;
                    case 400:
                        message.error(error_msg)
                        break;
                
                    default:
                        break;
                }
                setloading(false)
            }, (err) => {
                setloading(false)
                console.log('Error occured!!:', err);
                message.error("Error requesting funds, please try again")
            })
        } catch (error) {
            console.log('Error:', error);
        }
    };

    return (
        <Modal
            title={<div className="w-full text-center"><b>Funds Request</b></div>}
            visible={show}
            centered

            onCancel={() => { setShow(false) }}
            footer={null}
        >
            <Form
                form={form}
                name="requestFunds"
                layout="vertical"
                onFinish={onFinish}
            >
                <Form.Item
                    name="amount"
                    label="Amount"
                    className="w-full my-3"
                    rules={[{ required: true, message: "Please provide amount for the fund request" }]}
                >
                    <Input 
                        className="w-full rounded" 
                        placeholder="Amount" 
                    />
                </Form.Item>
                <div className="pt-4">
                    <Button 
                        htmlType="submit"
                        size="middle" 
                        shape="round" 
                        className="bg-primary text-white w-full"
                        loading={loading}
                    >
                        Request
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export default RequestFunds;

import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd';

const Card = ({
    card, 
    custom="w-40", 
    logo="w-6", 
    fontSize="0.7rem", 
    padding="p-2",
    selectable=false,
    handleCheck=()=>{},
    disabled=false,
    checked,
}) => { 
    const [localChecked, setlocalChecked] = useState(false);

    useEffect(() => {
      if (checked === true) handleCheck(card, true);
      if (checked === false) setlocalChecked(false);
    }, [checked]);

    useEffect(() => {
      if (card.isAssigned) setlocalChecked(true);
    }, [card]);
    
    return (
        <>
            <div 
                className={`${custom} flex flex-col items-center relative`}>
                <img 
                    id="tempId" 
                    src={card.image} 
                    className="w-full bg-gray-100 h-full rounded shadow-lg hover:shadow-xl" 
                    alt="" 
                />

                <div className="absolute w-full h-full">
                    {!card.web_design_props.type &&
                        <div className={`absolute top-0 right-0 ${padding}`}
                            style={{
                                color: `${card.default_color}`,
                                font_weight: `${card.font_weight}`,
                                fontSize: fontSize
                            }}>
                            ¢{card.amount}
                        </div>
                    }
                    
                    {card.web_design_props && (
                        <>
                            {card.web_design_props.logo_pos !== undefined && (
                                <div className={` ${card.web_design_props.logo_pos[0]}-0 ${card?.web_design_props?.logo_pos[1]?.includes("flex--basic", "center") ? 'inset-y-0 flex--basic' : `${card.web_design_props.logo_pos[1]}-0`} absolute ${padding}`}>
                                    {card.logo &&
                                        <img src={card.logo} className={`${logo} mx-auto`} alt="" />
                                        // :
                                        // <div className="w-12 border-dashed border h-6 flex--basic"
                                        //     style={{ 
                                        //         color: `${card.default_color}`, 
                                        //         borderColor: `${card.default_color}`
                                        //     }}
                                        // >
                                        //     Logo
                                        // </div>
                                    }
                                </div>
                            )}
                            {card.web_design_props.logo_position !== undefined && (
                                <div className={` ${card.web_design_props.logo_position[0]}-0 ${card.web_design_props.logo_position[1].includes("flex--basic", "center") ? 'inset-y-0 flex--basic' : `${card.web_design_props.logo_position[1]}-0`} absolute ${padding}`}>
                                    {card.logo &&
                                        <img src={card.logo} className={`${logo} mx-auto`} alt="" />
                                        // :
                                        // <div className="w-12 border-dashed border h-6 flex--basic"
                                        //     style={{ 
                                        //         color: `${card.default_color}`, 
                                        //         // borderColor: `${card.default_color}` 
                                        //     }}
                                        // >
                                        //     Logo
                                        // </div>
                                    }
                                </div>
                            )}
                        </>

                    )}
                </div>
            </div>

            {selectable &&
                <div className="pt-4">
                    <Checkbox 
                        checked={checked || localChecked} 
                        onChange={(e) => { 
                            handleCheck(card, e.target.checked);
                            setlocalChecked(e.target.checked)
                        }} 
                        disabled={disabled}
                    />
                </div>
            }            
        </>
    )
}

export default Card;

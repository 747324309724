import { SET_STATS, SET_FILTERS } from './types';
import { dashboardService } from '../../_services/dashboardService';

export const getStats = (filters) => {
    return dispatch => {
        return dashboardService.getMainStats(filters)
        .then(res => {
            // console.log('Merchants:', res.data);
            dispatch({
                type: SET_STATS,
                payload: res.data.data
            });
            return res.data.data;
        }, err => {
          console.log('err', err);
            return err;
        });   
    };
};

export const getMonthlySales = (filters) => {
    return dispatch => {
        return dashboardService.get_monthly_sales(filters)
        .then(res => {
            // dispatch({
            //     type: SET_MONTHLY_PURCHASE,
            //     payload: res.data.data
            // });
            return res.data.data;
        }, err => {
          console.log('err', err);
            return err;
        });   
    };
};

export const getSalesOverview = (filters) => {
    return dispatch => {
        return dashboardService.get_sales_overview(filters)
        .then(res => {
            console.log('Sales Overview:', res.data.data)
            // dispatch({
            //     type: SET_MONTHLY_PURCHASE,
            //     payload: res.data.data
            // });
            return res.data.data;
        }, err => {
          console.log('err', err);
            return err;
        });   
    };
};

export const getCardSalesOverview = (filters) => {
    return dispatch => {
        return dashboardService.get_card_sales_overview(filters)
        .then(res => {
            console.log('Card Sales Overview:', res.data.data)
            // dispatch({
            //     type: SET_MONTHLY_PURCHASE,
            //     payload: res.data.data
            // });
            return res.data.data;
        }, err => {
          console.log('err', err);
            return err;
        });   
    };
};

export const setFilters = (type, data) => {    
    return {
        type: SET_FILTERS,
        payload: { type, data }
    }
};

export const Initiate_sign_up = (info) => {
    return dispatch => {
        return dashboardService.Initiate_sign_up(info)
        .then(res => {
            console.log('Card payload:', res);
            return res;
        }, err => {
          console.log('err', err);
            // dispatch(addSignupFailure(err.response.data));
            return err;
        });   
    };
};

export const Complete_sign_up = (info) => {
    return dispatch => {
        return dashboardService.Complete_sign_up(info)
        .then(res => {
            console.log('Completed Card Payload:', res);
            return res;
        }, err => {
          console.log('err', err);
            // dispatch(addSignupFailure(err.response.data));
            return err;
        });   
    };
};
export const getAvailableFunds = (info) => {
    return dispatch => {
        return dashboardService.get_available_funds(info)
        .then(res => {
            console.log('Available Funds', res);
            return res;
        }, err => {
          console.log('err', err);
            return err;
        });   
    };
};



import React from 'react';
import { Modal } from 'antd';
import MyGoogleMap from "../components/map/MyGoogleMap"

const PickLocation = ({
    showMap=false,
    setshowMap=()=>{},
    handleChangeLocation=()=>{}
}) => {
    return (
        <Modal
            visible={showMap}
            centered
            onCancel={() => { setshowMap(false) }}
            closable={false}
            footer={false}
            className="customModal bg-white hideModalBody"
        >
            <div className="main-wrapper">
                <MyGoogleMap 
                    handleGo={handleChangeLocation}
                />
            </div>
        </Modal> 
    )
}

export default PickLocation;

import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Divider, Skeleton, Modal, Table, Tag, Space, Form, Select, Tooltip, message, Pagination, DatePicker, notification } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, StopOutlined, LoginOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";

// Components
import AddMerchant from "../components/misc/AddMerchant";
import ChangeMerchantStatus from "../components/misc/ChangeMerchantStatus";

import { useDispatch, useSelector } from "react-redux";
import { setFilters, getMerchants, change_merchant_status, Delete_Merchant } from "../store/merchants/actions";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { confirm } = Modal;

const Merchants = () => {
  const dispatch = useDispatch();
  const { filters: merchant_filters } = useSelector((state) => state.merchants);
  const [current, setcurrent] = useState();
  const [loading, setloading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [show, setshow] = useState(false);
  const [merchants, setmerchants] = useState([]);
  const [currentStatus, setcurrentStatus] = useState();
  const [mode, setmode] = useState("ADD");
  const [meta, setmeta] = useState({
    current_page: '',
    current_page_total: '',
    total_filtered: '',
    total_pages: ''        
  });
  const [filters, setfilters] = useState({
    limit: '20',
    page: 1
  });   

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <div>{text}</div>,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.name > b.name,
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email) => <>{email ? email : 'N/A'}</>,
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <div className="flex items-center">
          {status === "under_review" && <Tag color="#ffa500">Under Review</Tag>}
          {status === "approved" && <Tag color="#008000">Approved</Tag>}
          {status === "suspended" && <Tag color="#ff0000">Suspended</Tag>}
        </div>
      ),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => <div>{moment(date).format("MMM Do YY")}</div>,
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <EditOutlined 
              className="cursor-pointer"
              onClick={() => {
                setcurrent(record)
                setmode("EDIT")
                setVisible(true)
              }}            
            />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteOutlined 
              onClick={() => showDeleteConfirm(record.id) }
            />
          </Tooltip>
          <Tooltip color={setColor(record)} title={setTitle(record)}>
            <div onClick={() => { setcurrentStatus(record.status) }}>
              {record.status === "approved" ? (
                <StopOutlined
                  onClick={() => {
                    setcurrent(record)
                    setshow(true)
                    // ActivateSuspend(record, "suspend");
                  }}
                  className="cursor-pointer text-red-500"
                />
              ) : (
                <LoginOutlined
                  onClick={() => {
                    setshow(true)
                    setcurrent(record)
                    // ActivateSuspend(record, "activate");
                  }}
                  className="cursor-pointer text-primary"
                />
              )}
            </div>
            {/* {record.status === 'suspended' || record.status === 'under_review' && <LoginOutlined onClick={() => { ActivateSuspend(record, 'activate') }} className="cursor-pointer text-primary" />} */}
          </Tooltip>
        </Space>
      ),
    },
  ];

  const setColor = (record) => {
    if (record.status === "approved") return "red"
    if (record.status === "under_review") return "orange"
    if (record.status === "suspended") return "green"
  };

  const setTitle = (record) => {
    if (record.status === "approved") return "Disable/Suspend Account"
    if (record.status === "under_review") return "Activate/Approve Account"
    if (record.status === "suspended") return "Activate/Approve Account"
  };

  const onChange = (e, type) => {
    setFilters(dispatch(setFilters(type, e)));
  };

  const handleDateChange = (dates, dateStrings = "") => {
    if (dateStrings?.length > 0) {
      setFilters(dispatch(setFilters("from", dateStrings[0])));
      setFilters(dispatch(setFilters("to", dateStrings[1])));
      // console.log('From: ', dates[0], ', to: ', dates[1]);
      // console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
    }
  };

  const ActivateSuspend = (type) => {
    setloading(true);
    dispatch(change_merchant_status(current, type)).then((response) => {
      console.log('Response from change:', response);
      let actionMessage = "";
      let actionBody = "";

      switch (type) {
        case "suspend":
          actionMessage = "Merchant Suspended!";
          actionBody = "Merchant account has been suspended";
          break;
        case "activate":
          actionMessage = "Merchant approved/activated!";
          actionBody = "Merchant account has been approved/activated successfully";
          break;
        case "review":
          actionMessage = "Merchant under review!";
          actionBody = "Merchant account has been placed under review";
          break;
      
        default:
          break;
      }

      setloading(false);
      setshow(false)

      notification.success({
        message: actionMessage,
        description: <p>{actionBody}</p>
      });

      fetchMerchants();
    },
      () => {
        message.error("Error changing merchant status, please try again");
        setloading(false);
      }
    );
  };

  const fetchMerchants = (filters={}) => {
    setloading(true);

    dispatch(getMerchants(filters))
    .then((response) => {
      setloading(false);
      console.log('Merchant List response');
      setmeta(response);
      console.log('Merchants:', response.merchants);
      setmerchants(response.merchants ? response.merchants : []);
    });
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: 'Are you sure delete merchant data?',
      icon: <ExclamationCircleOutlined />,
      content: 'Take note, if merchant has transactions, the account will be archived.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        return new Promise((resolve, reject) => {
          console.log('Merchant ID:', id);
          dispatch(Delete_Merchant(id))
          .then((response) => {
            fetchMerchants(filters)
            notification.success({
              message: 'Merchant Account Deleted!'
            })
            resolve(response)
          }, (error) => {
            notification.error({
              message: "Merchant Account could not be deleted"
            })            
            reject(error)
          })          
        })
        .catch((err) => {
          console.log("Error:", err)
        });        
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const onShowSizeChange = (current, pageSize) => {
    console.log(current, pageSize);
  };

  const pageChanger = (page, pageSize) => {
    setfilters({...filters, page: page, limit: pageSize });
  };

  useEffect(() => {
    setfilters({ ...filters, ...merchant_filters });
  }, [merchant_filters])

  useEffect(() => {
    fetchMerchants(filters);
  }, [filters]);

  return (
    <>
      <Row className="my-6">
        <Col span={24} className="pl-4">
          <Card className="rounded-large shadow-lg">
            <h2 className="text-xl font-bold mb-0 text-center">Merchants</h2>
            <div className="flex justify-end">
              <Button
                type="primary"
                className="flex--basic text-white text-base px-4 shadow-none"
                shape="round"
                onClick={() => {
                  setmode("ADD");
                  setVisible(true);
                }}
              >
                <PlusOutlined />
                Add Merchant
              </Button>
            </div>
            <Divider />

            {/* <Skeleton.Image loading={loading} /> */}
            <Skeleton
              loading={loading}
              avatar={{ avatarShape: "Square" }}
              active
            >
              <section className="flex items-start">
                <h4 className="font-bold">Filter:</h4>

                <Form
                  name="filter"
                  className="flex flex-wrap w-full justify-end font-normal filter-form"
                >
                  <div className="w-1/4">
                    <Form.Item
                      name="branches"
                      label="Branches"
                      className="w-full pr-4"
                    >
                      <Select
                        placeholder="Select"
                        value={merchant_filters?.branch}
                        onChange={(e) => onChange(e, "branch")}
                      >
                        <Option value="accra">Accra</Option>
                        <Option value="kumasi">Kumasi</Option>
                        <Option value="tarkoradi">Tarkoradi</Option>
                        <Option value="ho">Ho</Option>
                        <Option value="cape_coast">Cape Coast</Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="w-1/3">
                    <Form.Item
                      name="date"
                      label="Date Range"
                      className="w-full pr-4"
                    >
                      <RangePicker
                        allowClear
                        ranges={{
                          Today: [moment(), moment()],
                          "This Month": [
                            moment().startOf("month"),
                            moment().endOf("month"),
                          ],
                        }}
                        className="w-full bg-white"
                        onChange={handleDateChange}
                      />
                    </Form.Item>
                  </div>

                  <div className="w-1/4">
                    <Form.Item
                      name="statuses"
                      label="Status"
                      className="w-full pr-4"
                    >
                      <Select
                        mode="multiple"
                        placeholder="Select"
                        value={merchant_filters?.statuses}
                        onChange={(e) => onChange(e, "statuses[]")}
                      >
                        <Option value="under_review">Under Review</Option>
                        <Option value="approved">Approved</Option>
                        <Option value="suspended">Suspended</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </Form>
              </section>
              {merchants.length <= 0 ? (
                <div className="flex--basic">
                  <div className="py-5 border-t border-gray-300 w-full text-center">
                    <div className="font-bold">No Merchants Available</div>
                  </div>
                </div>
              ) : (
                <>
                  <Table
                    columns={columns}
                    dataSource={merchants}
                    pagination={false}
                    loading={loading}
                  />
                  <div className="pt-3 flex justify-end">
                    <Pagination 
                      className="filter-form"
                      showSizeChanger
                      onChange={pageChanger}
                      onShowSizeChange={onShowSizeChange}
                      total={meta?.total_filtered} 
                      defaultPageSize={filters?.limit}
                      defaultCurrent={meta?.current_page}                                           
                    />
                  </div>
                </>
              )}
            </Skeleton>
          </Card>
        </Col>
      </Row>

      {/* Add merchant */}
      <Modal
        title={<b>{mode === 'ADD' ? 'Add Merchant' : 'Edit Merchant'}</b>}
        visible={visible}
        onCancel={() => { setVisible(false) }}
        maskStyle={{ top: "20" }}
        // closable={false}
        footer={false}
      >
        <AddMerchant 
          mode={mode}
          updateData={current}
          setVisible={setVisible} 
          fetchMerchants={fetchMerchants}
        />
      </Modal>

      {/* Show Change merchant status */}
      <Modal
        title={
          <div className="flex items-center">
            <b>Change Status</b>
            <div className="pl-10">
              {currentStatus === "under_review" && <Tag color="#ffa500">Under Review</Tag>}
              {currentStatus === "approved" && <Tag color="#008000">Approved</Tag>}
              {currentStatus === "suspended" && <Tag color="#ff0000">Suspended</Tag>}              
            </div>
          </div>
        }
        visible={show}
        maskStyle={{ top: "20" }}
        onCancel={() => { setshow(false) }}
        closable={false}
        footer={false}
      >
        <ChangeMerchantStatus 
          handleChange={ActivateSuspend}
          loading={loading}
        />
      </Modal> 
    </>
  );
};

export default Merchants;

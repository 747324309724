import { GET_USER, SET_USER, LOGOUT, SET_TOKEN, ADD_SIGNUP_SUCCESS, 
    ADD_SIGNUP_STARTED, ADD_SIGNUP_FAILURE, SET_QUESTION_N_ANSWERS
} from './user.types';

const INITIAL_STATE = {
    loading: false,
    data: {},
    token: localStorage.getItem('token'),
    state: null,
    error: null,
    qna: {}
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_USER:
            return {
                ...state, data: action.payload
            };

        case SET_USER:
            let userdata = Object.assign({}, state.data)
            let updatedData = {...userdata, ...action.payload}
            return {
                ...state, data: updatedData
            };

        case ADD_SIGNUP_STARTED:
            return {
                ...state,
                loading: true
            };

        case SET_TOKEN:
            return {
                ...state,
                token: action.payload
            };

        case ADD_SIGNUP_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                state: action.payload
            };

        case ADD_SIGNUP_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
            
        case SET_QUESTION_N_ANSWERS:
            return {
                ...state, 
                qna: action.payload
        }

        case LOGOUT:
            return {
                ...state, 
                data: {}
        }

        default: return state;
    }
};

export default reducer;


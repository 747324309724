import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { motion } from "framer-motion";

const BannerSlider = ({
  cards=[]
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000
  };

  return (
    <Slider {...settings} className="ads sign_in">
      {cards.map((card, index) => (
        <div key={index}>
          <div
            className="relative bg-cover bg-no-repeat w-full"
            style={{background: `url(${card.url})`, height: 'calc(100vh - 7rem)'}}
          >
            {/* {index === 0 &&
              <motion.div
                  initial={{x: -1000, opacity: 0}}
                  animate={{ x: 10, opacity: 1 }}
                  transition={{ delay: .5 }}
                  className="text-white text-2xl font-bold pl-10 pt-10 leading-normal"
              >
                Valentine’s <br/> Day is around
              </motion.div>
            } */}
          </div>

        </div>
      ))}
    </Slider>
  )
}

export default BannerSlider

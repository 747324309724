import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, Input, Upload, Button, message, notification, Divider, Checkbox, DatePicker } from "antd";
import { InboxOutlined, ArrowLeftOutlined } from "@ant-design/icons";

import filePlaceholder from "../assets/images/csv_icon.png";

import { useDispatch, useSelector } from "react-redux";
import { addCoupon } from "../store/general/actions";
import { getCard } from "../store/cards/cards.actions";

import CardList from "src/components/CardList";
import utils from "../utils/services";

const loading_cards = [{}, {}, {}];
const { Dragger } = Upload;
const { RangePicker } = DatePicker;

const AddEditCoupon = ({
  formStyle="mx-auto w-full sm:w-1/2",
  setVisible = () => {},
  mode = "ADD",
  disableFields=false,
  updateData = {},
  handleUpdate = () => {}
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();
  const checkAllRef = useRef();
  const { cards } = useSelector((state) => state.cards);

  const [file, setFile] = useState();
  const [card_ids, setcard_ids] = useState("");
  const [loading, setloading] = useState(false);
  const [formData, setformData] = useState({
    csv_codes: null,
    start_date: null,
    expire_date: null,
  });
  const [loadingCards, setloadingCards] = useState(false);
  const [slider, setslider] = useState(1);
  const [settings, setSettings] = useState({
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slider,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    horizontalScroll: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: cards.length < 4 ? cards.length : 4,
        },
      },
      {
        breakpoint: 433,
        settings: {
          slidesToShow: cards.length < 2 ? cards.length : 2,
        },
      },
    ],
  });
  const [filteredCards, setfilteredCards] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  const SetCardIds = useCallback((list = []) => {
    let newStr = "";
    list.forEach((el) => {
      newStr = newStr ? `${newStr},${el}` : el;
    });
    return newStr;
  }, []);

  const onCheckAllChange = (e) => {
    setCheckAll(e.target.checked);

    if (e.target.checked) {
      setcard_ids(SetCardIds(filteredCards.map((el) => el.id)));
    } else {
      setcard_ids("");
    }
  };

  const handleValueChange = (val) => {
    if (val?.string_codes && file) setFile(null);
  };

  const onFinish = async (values) => {
    console.log("Received values of form: ", values);

    try {
      await form.validateFields();

      setloading(true);
      let data = Object.assign({}, values);
      data = { ...data, ...formData };

      if (card_ids) data.card_ids = card_ids;
      if (data.date) delete data.date;
      if (data.csv) delete data.csv;
      if (data.csv_codes) delete data.csv_codes;

      Object.keys(data).forEach((value) => {
        if (!data[value] || data[value] === "") delete data[value];
      });

      if (!data.id) data.id = updateData?.tag_id || updateData?.id

      const formDt = new FormData();
      Object.keys(data).forEach((value) => {
        formDt.append(value, data[value]);
      });

      if (file) formDt.append("csv_codes", file);

      if (mode === "ADD") {
        dispatch(addCoupon(formDt, "Add")).then(
          (response) => {
            switch (response.status) {
              case 200:
              case 201:
                notification.success({
                  message: "Coupon Added",
                  description: "Coupons added successfully",
                });

                setVisible(false);
                history.push("/coupons");
                break;
              case 401:
                notification.error({
                  message: response.error_msg,
                });
                break;
              case 400:
                notification.error({
                  message: response.error_msg,
                });
                break;
              case 500:
                notification.error({
                  message: response.error_msg || 'Error',
                  description: 'An error occured. Please try again or contact support. Thank You'
                });
                break;

              default:
                break;
            }
            setloading(false);
          },
          (error) => {
            console.log("Error in then:", error);
            notification.error({
              message: "Error adding coupons",
              description: "An error occured addding coupons",
            });
            setloading(false);
          }
        );
      } else {
        dispatch(addCoupon(formDt, "Update", updateData?.id || updateData?.tag_id))
        .then((response) => {
            switch (response.status) {
              case 200:
              case 201:
                notification.success({
                  message: "Coupon Updated",
                  description: "Coupon codes updated successfully",
                });

                handleUpdate();
                setVisible(false);
                break;
              case 400:
                notification.error({
                  message: response.error_msg,
                });
                break;
              case 500:
                notification.error({
                  message: response.error_msg || 'Error',
                  description: 'An error occured. Please try again or contact support. Thank You'
                });
                break;

              default:
                break;
            }
            setloading(false);
          },
          (error) => {
            console.log("Error in then:", error);
            notification.error({
              message: "Error creating merchant",
              description: "An error occured",
            });
            setloading(false);
          }
        )
      }
    } catch (error) {
      console.log("Error:", error);
      notification.error({
        message: "Error creating merchant",
        description: "An error occured",
      });
      setloading(false);
    }
  };

  const props = {
    name: "file",
    multiple: false,
    action: null,
    showUploadList: false,
    beforeUpload(file) {
      if (file.type !== "text/csv") {
        notification.error({
          message: "Invalid Type",
          description: "Only accepting CSV or EXCEL files",
        });

        return false;
      }

      setFile(file);
      form.setFieldsValue({ string_codes: null });
      return false;
    },
    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
        console.log("File", info.file, "File List", info.fileList);
      }

      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    onRemove() {
      setformData((prev) => {
        return { ...prev, csv_codes: null };
      });
    },
  };

  const onChange = (dates, dateStrings) => {
    if (dates) {
      // console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
      if (dateStrings[0])
        setformData((prev) => {
          return { ...prev, start_date: dateStrings[0] };
        });

      if (dateStrings[1])
        setformData((prev) => {
          return { ...prev, expire_date: dateStrings[1] };
        });
    } else {
      console.log("Clear");
    }
  };

  const fetchAllCards = useCallback(() => {
    setloadingCards(true);
    dispatch(getCard()).then((response) => {
      setfilteredCards(response.data?.data?.cards);
      setloadingCards(false);
    });
  }, []);

  const handleSetCards = (id, checked) => {
    console.log('card_ids:', card_ids, 'checked', checked);

    if (checked) {
      card_ids ? setcard_ids(`${card_ids},${id}`) : setcard_ids(`${id}`);
    } else {
      let temp = card_ids?.split(",");
      temp = temp.filter((el) => el && el !== id?.toString());
      setcard_ids(SetCardIds(temp));
    }
  };

  useEffect(() => {
    if ((utils.present(updateData) || mode !== "ADD") && disableFields === false) {
      console.log('updateData:', updateData);
      updateData.string_codes = updateData.codes.map(el => el?.code)
      form.setFieldsValue(updateData);
    }

    return () => {
      // if (mode === 'ADD') form.resetFields();
    }
  }, [mode, updateData]);

  useEffect(() => {
    // cards.length <= 1 ?  : setSettings(prev => {return { ...prev, slidesToShow: cards.length }})
    if (cards.length > 3) {
      setslider(3);
    } else {
      setslider(cards.length);
    }

    setSettings((prev) => {
      return { ...prev, slidesToShow: slider };
    });

  }, [cards, slider]);

  useEffect(() => {
    fetchAllCards();
  }, []);

  return (
    <section className="">
      {mode === "ADD" && 
        <>
            <div className="pt-5 text-lg">
                <Link to="/coupons" className="w-full flex items-center ">
                <ArrowLeftOutlined className="text-lg" />
                <span className="pl-2">Back</span>
                </Link>
            </div>

            <Divider />
        </>
      }

      <Form
        name="addcoupons"
        className={`${formStyle}`}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={handleValueChange}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Coupons name is required",
            },
          ]}
        >
          <Input size="large" placeholder="Name" disabled={mode !== "ADD" && !!disableFields} />
        </Form.Item>

        <Form.Item name="date" label="Start/Expiry Date">
          <RangePicker
            placeholder={["State Date", "Expiry Date"]}
            size="large"
            className="w-full"
            onChange={onChange}
            showTime
            disabled={mode !== "ADD" && !!disableFields}
          />
        </Form.Item>

        <Form.Item label="CSV Codes" name="csv" className="mt-1">
          <section className="bg-gray-100 border border-dashed border-primary px-4 py-6">
            <Dragger {...props}>
              {file ? (
                <div className="w-full flex items-center justify-center">
                  <img
                    src={filePlaceholder}
                    className="h-20 w-20 fade object-contain"
                    alt=""
                  />
                  <span className="pl-4 capitalize">{file?.name}</span>
                </div>
              ) : (
                <>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag csv file to this area to upload
                  </p>
                </>
              )}

              <p className="ant-upload-hint pt-2">
                Download Template csv file and replace 'XXXX' with your code <br />
                <span>
                  <a
                    className="underline font-bold"
                    href="/template.csv"
                    download
                    onClick={(e) => e.stopPropagation()}
                  >
                    Coupons-Template.csv
                  </a>
                </span>
              </p>
            </Dragger>
          </section>
        </Form.Item>

        <Divider>OR</Divider>

        <Form.Item label="String Codes" name="string_codes" className="mt-1">
          <Input.TextArea
            rows={4}
            type="text"
            className=""
            placeholder="XXXX, XXXX, XXXX"
          />
        </Form.Item>

        <Form.Item
          label={
            <div className="w-full flex justify-between items-center">
              <span>Cards</span>
              <div>
                <Checkbox
                  onChange={onCheckAllChange}
                  checked={checkAll}
                  ref={checkAllRef}
                  disabled={mode !== "ADD" && !!disableFields}
                >
                  Select all
                </Checkbox>
              </div>
            </div>
          }
          name="card_ids"
          className="mt-1 filter-form"
        >
          <div className="w-full pb-4">
            <CardList
              loadingCards={loadingCards}
              cards={mode !== "ADD" ?
                filteredCards?.filter((el) => {
                  if (el?.associated_cards?.find((_el_) => _el_.id === el.id)) 
                    return { ...el, isAssigned: true }
                  return el;
                })
                :
                filteredCards?.filter((el) => !utils.present(el?.coupon_tag))
              }
              loading_cards={loading_cards}
              settings={settings}
              checked={checkAll}
              selectable
              getCards={handleSetCards}
              disabled={mode !== "ADD" && !!disableFields}
            />
          </div>
        </Form.Item>

        <Form.Item name="description" label="Description">
          <Input.TextArea rows={4} placeholder="Description" disabled={mode !== "ADD" && !!disableFields} />
        </Form.Item>

        <Form.Item name="terms_and_conditions" label="Terms & Conditions">
          <Input.TextArea rows={4} placeholder="Terms" disabled={mode !== "ADD" && !!disableFields} />
        </Form.Item>

        <div className="pb-6 pt-3 flex--basic">
          <Button
            htmlType="submit"
            type="primary"
            className="bg-primary w-full h-10 flex--basic uppercase font-semibold rounded"
            size="large"
            loading={loading}
          >
            {mode === "ADD" ? "Add Coupons" : "Update Coupons"}
          </Button>
        </div>
      </Form>
    </section>
  );
};

export default AddEditCoupon;

import React from 'react';
import { Route } from 'react-router-dom';
import { Layout } from 'antd';

// Components
const { Content } = Layout;

const SiteLayout = ({ children }) => (    
	<>
		{children}   
	</>                     
); 

const siteLayoutRoute = ({component: Component, ...rest}) => {
	return (
		<Layout className="overflow-x-hidden bg-white">
			<Content>
				<Route {...rest} render={matchProps => (  
					<SiteLayout>  
						<Component {...matchProps} />
					</SiteLayout>  
				)} /> 
			</Content>
		</Layout>
	)
}

export default siteLayoutRoute;

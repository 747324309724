import React, { useState, useEffect } from 'react';
import { Table, Tooltip } from 'antd';
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import moment from "moment";

// Components 
import Filter from "../../components/misc/ReportFilter";

import { useDispatch, useSelector } from "react-redux";
import * as ReportActions from '../../store/reports/actions';

const TransactionsReport = ({
    merchants=[]
}) => {
    const dispatch = useDispatch();
    const { transactions, filters } = useSelector(state => state.reports);
    const [loading, setloading] = useState(false);

    const columns = [
    {
        title: 'Customer',
        dataIndex: 'person',
        key: 'person',
        render: person => 
            <>{person.first_name || 'N/A'} {person.sur_name }</>
    },
    {
        title: 'Merchant',
        dataIndex: 'merchant',
        key: 'merchant',
        render: merchant => merchant || 'N/A'
    },
    {
        title: 'Type',
        dataIndex: 'transaction_type',
        key: 'transaction_type',
        render: transaction_type => {
            let formated;
            if (transaction_type) {
                formated = transaction_type.toString().replace('_', ' ');
            }
            return (
                <Tooltip title={formated}>
                    <p className="capitalize w-24 truncate">{formated || '-'}</p>
                </Tooltip>                
            )
        }
    },    
    // {
    //     title: 'Merchant',
    //     dataIndex: 'merchant',
    //     key: 'merchant',
    //     render: merchant => merchant || 'N/A'
    // },
    // {
    //     title: 'Location',
    //     dataIndex: 'location',
    //     key: 'location',
    //     render: location => location || 'N/A'
    // },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: amount => <p><b>GH¢</b> {amount || '-'}</p>
    },
    {
        title: 'Statues',
        dataIndex: 'status',
        key: 'status',
        render: status => status || 'N/A'
    }
    ];

    const fetchTransactions = () => {
        // Get dashboard stats data
        setloading(true)
        dispatch(ReportActions.Get_Transastions(filters, true))
        .then((response) => {
        //   console.log('Transactions:', response.data.data);
          setloading(false);
        }, (error) => {
          console.log('Error:', error);
          setloading(false);
        });
    };

    useEffect(() => {
        fetchTransactions()        
    }, [filters]);

    return (
        <section>
            <Filter merchants={merchants} showType={true} />
            <div className="pt-5 pr-4 reportTable transactions">
                <Table 
                    loading={loading}
                    columns={columns} 
                    dataSource={transactions}
                    // rowClassName={`${tableClass} text-white`}
                    rowClassName="bg-primary text-white"
                    rowKey={record => record.id}
                    expandable={{
                        expandedRowRender: record => 
                            <section className="w-full flex items-center py-2" style={{background: '#E5E5E5' }}>
                                <div className="w-10/12 text-xs">
                                    <section className="flex items-center w-full py-2"
                                    >
                                        <div className="w-1/6 h-16 text-center flex flex-col items-center justify-between">
                                            <div className="text-text">Card Type</div>
                                            <p className="font-bold">{record.type || 'N/A'}</p>
                                        </div>
                                        <div className="w-1/6 h-16 text-center flex flex-col items-center justify-between">
                                            <div className="text-text">Original Amount</div>
                                            <p className="font-bold">{`GH¢ ${record.amount}` || 'N/A'}</p>
                                        </div>
                                        <div className="w-1/6 h-16 text-center flex flex-col items-center justify-between">
                                            <div className="text-text">Product</div>
                                            <p className="font-bold">{record.name || 'N/A'}</p>
                                        </div>
                                        <div className="w-1/6 h-16 text-center flex flex-col items-center justify-between">
                                            <div className="text-text">Reason</div>
                                            <p className="font-bold">{record.reason || 'N/A'}</p>
                                        </div>
                                        <div className="w-1/6 h-16 text-center flex flex-col items-center justify-between">
                                            <div className="text-text">Date</div>
                                            <p className="font-bold">{moment(record.created_at).format("MMM Do, YY") || 'N/A'}</p> {/* Use moment to format date  */}
                                        </div>
                                        <div className="w-1/6 h-16 text-center flex flex-col items-center justify-between">
                                            <div className="text-text">Customer Contact</div>
                                            <p className="font-bold">{record.phone || 'N/A'}</p>
                                        </div>
                                    </section>
                                    <section className="flex items-center w-full py-2">
                                        <div className="w-1/6 h-16 text-center flex flex-col items-center justify-between">
                                            <div className="text-text">Card ID</div>
                                            <p className="font-bold">{record.id || 'N/A'}</p>
                                        </div>
                                        <div className="w-1/6 h-16 text-center flex flex-col items-center justify-between">
                                            <div className="text-text">Balance</div>
                                            <p className="font-bold">{record.balance || 'N/A'}</p>
                                        </div>
                                        <div className="w-1/6 h-16 text-center flex flex-col items-center justify-between">
                                            <div className="text-text">Amount</div>
                                            <p className="font-bold">GH¢ {record.amount || 'N/A'}</p>
                                        </div>
                                        <div className="w-1/6 h-16 text-center flex flex-col items-center justify-between">
                                            <div className="text-text"></div>
                                            <p className="font-bold"></p>
                                        </div>
                                        <div className="w-1/6 h-16 text-center flex flex-col items-center justify-between">
                                            <div className="text-text">Time</div>
                                            <p className="font-bold">{moment(record.created_at).format("h:mm:ss a") || 'N/A'}</p> {/* Use moment to format time  */}
                                        </div>
                                        <div className="w-1/6 h-16 text-center flex flex-col items-center justify-between">
                                            <div className="text-text">Merchant Contact</div>
                                            <p className="font-bold">{record.person.first_name || 'N/A'}</p>
                                        </div>
                                    </section>

                                </div>
                                <div className="w-2/12 flex--basic text-lg font-black text-center">
                                    {record.status || 'N/A'}
                                </div>
                            </section>,
                        rowExpandable: record => record != null,
                        expandIcon: ({ expanded, onExpand, record }) =>
                        expanded ? 
                            <UpOutlined onClick={e => onExpand(record, e)} />
                        :
                            <DownOutlined onClick={e => onExpand(record, e)} />
                        ,
                        checkStrictly: true,
                        // expandedRowClassName: () => "bg-secondary"
                    }}                    
                />
            </div>
        </section>
    )
}

export default TransactionsReport

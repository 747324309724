import React, { useEffect } from 'react'
import { Form, Input, Select } from 'antd';
const { Option } = Select;

export default () => {
    // Form
    const onFinish = values => {
        console.log('Received values of form: ', values);
    };

    // const onReset = () => {
    //     formRef.current.resetFields();
    // };

    useEffect(() => {

    })

    const formRef = React.createRef();

    return (
        <Form name="complex-form" ref={formRef} onFinish={onFinish} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Form.Item label="Name">
                <Form.Item
                    name="Name"
                    noStyle
                    rules={[{ required: true, message: 'Name is required' }]}
                >
                    <Input style={{ width: 160 }} placeholder="Please name" />
                </Form.Item>
            </Form.Item>
            <Form.Item label="Bank">
                <Input.Group compact>
                    <Form.Item
                        name={['bank', 'account manager']}
                        noStyle
                        rules={[{ required: true, message: 'Bank is required' }]}
                    >
                        <Select placeholder="Select bank">
                            <Option value="cbg">CBG</Option>
                            <Option value="gcb">Ghana Commecial Bank</Option>
                            <Option value="stanbic">Stanbic Bank</Option>
                            <Option value="ecobank">Ecobank Ghana Limited</Option>
                            <Option value="gtb">GT Bank</Option>
                            <Option value="access">Access Bank</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name={['bank', 'account number']}
                        noStyle
                        rules={[{ required: true, message: 'Account Number is required' }]}
                    >
                        <Input style={{ width: '50%' }} placeholder="Account Number" />
                    </Form.Item>
                </Input.Group>
            </Form.Item>
            <Form.Item label="Branch" style={{ marginBottom: 0 }}>
                <Form.Item
                    name="branch"
                    rules={[{ required: true }]}
                    style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                >
                    <Input placeholder="Branch" />
                </Form.Item>
            </Form.Item>
            {/* <Form.Item label="" colon={false}>
                <Button type="primary" htmlType="submit" className="mr-4">
                    Submit
                </Button>
                <Button htmlType="button" onClick={onReset}>
                    Reset
                </Button>
            </Form.Item> */}
        </Form>
    )
}
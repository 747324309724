import React from 'react';
import { Link } from 'react-router-dom';
// import { Button, Space } from 'antd';
// import { motion } from "framer-motion";

import Slider from "./misc/BannerSlider";

// Images
import logo from '../assets/images/logo-white.png';

// Component
// import SharePromo from '../components/misc/sharePromo';

const WelcomePages = ({
    children,
    type="static",
    single="",
    slider=[],
    current=""
}) => {   
    return (
        <div className="min-h-screen sm:h-screen w-screen relative">
            <div className="h-8 sm:h-12 bg-primary w-full flex--basic relative">
                <div className="hidden sm:block absolute z-50"
                    style={{bottom: '-4rem', left: '3rem'}}
                >
                    <div className="bg-primary pb-2 w-16 h-16 flex--basic">
                        <div className="flex--basic">
                            <Link to="/">
                                <img src={logo} className="h-full w-full" alt="prime_logo" />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="text-white text-center text-base tracking-widest">
                    FRANCHISE MANAGER E-GIFT CARD DASHBOARD
                </div>
            </div>
            
            <div className="h-3 bg-secondary w-full" />

            <section className="flex flex-wrap w-full pb-20 sm:pb-0"
                style={{ height: 'calc(100% - 7rem)' }}
            >
                <div className="w-full sm:w-5/12 relative bg-white flex justify-center sm:items-center pt-10 sm:pt-0 h-full">
                    <section className="w-full flex flex-col items-center">
                        {children}
                        <div className="text-sm pt-8 text-center leading-normal">
                            <i>"We only have what we give" by <b>Isabel Allende</b></i>
                        </div>
                    </section>
                </div>
                <div className="w-7/12 h-full bg-gray-200">
                    {type === 'static' ?
                        <div
                            className="bg-cover bg-no-repeat w-full h-full bg-gray-200" style={{background: `url(${single})`}}
                        >
                           {/* {current === 0 &&
                                <div className="pt-20">
                                    <motion.div
                                        initial={{x: 100, opacity: 0}}
                                        animate={{ x: 0, opacity: 1 }}
                                        transition={{ delay: .5 }}
                                        className="w-full h-32 flex--basic leading-normal"
                                    >
                                        <div className="pl-10 text-3xl font-bold w-full">
                                            Share the <br/>love of giving
                                        </div>                                        
                                    </motion.div>
                                </div>
                            }                            
                            {current === 1 &&
                                <div className="pt-20">
                                    <motion.div
                                        initial={{x: 100, opacity: 0}}
                                        animate={{ x: 0, opacity: 1 }}
                                        transition={{ delay: .5 }}
                                        className="text-3xl font-bold bg-opacity-75 bg-white w-1/3 h-32 flex--basic leading-normal"
                                    >
                                        Increase <br/> sales
                                    </motion.div>
                                </div>
                            }
                            {current === 2 &&
                                <div className="pt-20">
                                    <motion.div
                                        initial={{x: 100, opacity: 0}}
                                        animate={{ x: 0, opacity: 1 }}
                                        transition={{ delay: .5 }}
                                        className="text-3xl font-bold pl-10 leading-normal"
                                    >
                                        Build <br/> brand loyalty
                                    </motion.div>
                                </div>
                            }
                            {current === 3 &&
                                <div className="pt-20">
                                    <motion.div
                                        initial={{x: 100, opacity: 0}}
                                        animate={{ x: 0, opacity: 1 }}
                                        transition={{ delay: .5 }}
                                        className="text-white text-3xl font-bold pl-10 leading-normal"
                                    >
                                        Make it the <br/>
                                        Perfect gift
                                    </motion.div>
                                </div>
                            }
                            {current === 4 &&
                                <div className="pt-10 pl-10">
                                    <motion.div
                                        initial={{x: 100, opacity: 0}}
                                        animate={{ x: 0, opacity: 1 }}
                                        transition={{ delay: .5 }}
                                        className="text-3xl font-bold leading-normal"
                                    >
                                        Yes! You made it
                                    </motion.div>

                                    <motion.div
                                        initial={{x: 100, opacity: 0}}
                                        animate={{ x: 0, opacity: 1 }}
                                        transition={{ delay: 1 }}
                                        className="text-sm font-thin leading-normal"
                                    >
                                        Your new stream of income is about to start. <br/>
                                        Create your e-gift card with our template <br/> 
                                        and start selling.
                                    </motion.div>

                                    <div className="pt-20">
                                        <motion.h1
                                        initial={{x: 100, opacity: 0}}
                                        animate={{ x: 0, opacity: 1 }}
                                        transition={{ delay: 1.2 }}
                                            className="text-2xl font-bold"
                                        >
                                            Features
                                        </motion.h1>
                                        <ul class="list-outside text-sm">
                                            <li className="flex flex-col pt-2 leading-normal">
                                                <motion.div 
                                                initial={{x: -100, opacity: 0}} 
                                                animate={{ x: 0, opacity: 1 }}
                                                transition={{ delay: 1.5 }}
                                                className="font-bold text-primary"
                                                >
                                                    DESIGN
                                                </motion.div>
                                                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.7, duration: .5 }}>
                                                    Available Templates
                                                </motion.div>
                                                
                                                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.75, duration: .5 }}>
                                                    Your Design</motion.div>
                                            </li>
                                            <li className="flex flex-col pt-2 leading-normal">
                                                <motion.div 
                                                initial={{x: -100, opacity: 0}} 
                                                animate={{ x: 0, opacity: 1 }}
                                                transition={{ delay: 1.8 }}
                                                className="font-bold text-primary"
                                                >MARKETING TOOLS
                                                </motion.div>
                                                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.85, duration: .5 }}>
                                                    Special Promo</motion.div>
                                                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.9, duration: .5 }}>
                                                    Discount Promo</motion.div>
                                                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.95, duration: .5 }}>
                                                    Give Back Promo</motion.div>
                                            </li>
                                            <li className="flex flex-col pt-2 leading-normal">
                                                <motion.div 
                                                initial={{x: -100, opacity: 0}} 
                                                animate={{ x: 0, opacity: 1 }}
                                                transition={{ delay: 2 }}
                                                className="font-bold text-primary">
                                                    SOCIAL MEDIA
                                                </motion.div>
                                                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 2.05, duration: .5 }}>
                                                    Share your e-gift card on
                                                </motion.div>
                                                <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 2.1, duration: .5 }} 
                                                class="share pt-1">
                                                    <SharePromo />
                                                </motion.div>                                                
                                            </li>
                                        </ul>
                                        <div>
                                            
                                        </div>
                                    </div>
                                </div>
                            } */}
                        </div>
                    :
                        <Slider cards={slider} />
                    }
                </div>
            </section>

            <div className="absolute bottom-0 w-full">
                <div className="w-full py-5 bg-primary flext--basic">
                    <div className="text-sm text-center text-white">
                        © 2020 JackBuild Limited • www.primeegiftcard.com • support@20pesewas.com • +233 24 711 7448 
                    </div>
                </div>
            </div>
        </div>
    )
};

export default WelcomePages;
import React from "react";
import { Upload, message, Image } from "antd";
import { CameraOutlined, LoadingOutlined } from "@ant-design/icons";

const { Dragger } = Upload;

const Uploader = ({
  image,
  placeholder,
  uploadText = "",
  type = "",
  btnload,
  handleBefore = () => {},
}) => {
  const propValue = {
    name: "file",
    multiple: false,
    action: null,
    showUploadList: false,

    beforeUpload: (file) => {
      handleBefore(type, file);
      return false;
    },

    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
        console.log("uploading:", info.file);
      }

      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <>
      <Dragger {...propValue} className="pt-4 company_logo relative">
        {image ? (
          <div className=" w-full h-full mb-2">
            <img
              src={image}
              className={`${type === 'photo' ? 'h-24 w-24 rounded-full object-cover': 'w-full h-24 pr-3 object-contain'} mx-auto hover:shadow-lg`}
              alt=""
            />
          </div>
        ) : (
          <>
            {placeholder ? (
              <img src={placeholder} className="w-16 mx-auto" alt="" />
            ) : (
              <div
                className="font-bold mr-4 border border-dashed border-black rounded-full mb-4 flex items-center justify-center py-2"
                style={{ fontSize: "0.6rem" }}
              >
                {uploadText}
              </div>
            )}
          </>
        )}

        <div className="absolute hidden inset-0 z-50 flex--basic w-full h-full opacity-75">
          <div className="bg-white border border-primary rounded-full flex--basic w-10 h-10">
            <CameraOutlined className="text-primary" />
          </div>
        </div>

        {btnload && (
          <LoadingOutlined className="text-2xl text-white absolute inset-0 flex--basic" />
        )}
      </Dragger>
    </>
  );
};

export default Uploader;

import { GET_STATS, SET_STATS, SET_FILTERS } from './types';

const INITIAL_STATE = {
    dashboard: {},
    filters: {
        // month: '',
        // year: '',
        // merchant_ids: []
    }    
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {    
        case GET_STATS:
           return {
                ...state, merchants: action.payload
           };
        case SET_STATS:
           return {
                ...state, 
                merchants: action.payload.merchants,
                meta: action.payload
            };
        case SET_FILTERS:
            let newFilters = Object.assign({}, state.filters);
            console.log('Type:', action.payload.type);
            console.log('Data:', action.payload.data);
            newFilters[action.payload.type] = action.payload.data

           return {
                ...state, 
                filters: newFilters
            };

        default: return state;
    }

};

export default reducer;


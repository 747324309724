import { config } from '../redux/config';
import { authHeader } from '../_helpers/auth-header';
import axios from 'axios';
import utils from '../utils/services'

// const config = {
//     apiUrl: 'https://api.primeegiftcard.com/prime.sika/v1'
// }

export const reportService = {
    getStats,
    getTransactions,
    getDepositDetails,
    generateReports,
    generateReportDetails,
    downloadGeneratedReport
};

function getStats() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/managers/reports/general-stats`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}
function getTransactions(filters) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let query = utils.createQueryFromObject(filters);
    let url = `${config.apiUrl}/managers/reports/transactions`;
    if (query) url = url + `?${query}`    

    return new Promise((resolve, reject) => {
        axios(url, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}
function getDepositDetails() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/managers/reports/initiated-deposits`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}
function generateReports(filters) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    let query = utils.createQueryFromObject(filters);
    let url = `${config.apiUrl}/managers/reports/transactions/generate`;
    if (query) url = url + `?${query}` 

    return new Promise((resolve, reject) => {
        axios(url, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            console.log('Rejected:', err.response?.data)
            reject(err);
        })
    });
}
function generateReportDetails(ID) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/reports/${ID}`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            console.log('Rejected:', err.response.data)
            reject(err);
        })
    });
}
function downloadGeneratedReport(ID) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return new Promise((resolve, reject) => {
        axios(`${config.apiUrl}/merchants/reports/${ID}/download`, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            console.log('Rejected:', err.response.data)
            reject(err);
        })
    });
}

// https://api.primeegiftcard.com/prime.sika/v1/accounts/merchant/profile/logo?company_code=790331347249
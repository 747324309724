import React from 'react';
import { Route, Redirect } from 'react-router-dom';  
import { Layout } from 'antd';
import { useSelector } from 'react-redux';

// Components
import Head from '../header';
import Side from '../side';

const { Header, Sider, Content, Footer } = Layout;

const AppLayout = ({ children }) => (    
    <>
        {children}
    </>                     
);  

const AppLayoutRoute = ({component: Component, ...rest}) => {
    const { token } = useSelector(state => state.user);
    return (
        <Layout style={{minHeight: '100vh'}} className="px-4">
            <Header className="fixed py-2 h-auto bg-primary z-10 -ml-4 w-full" style={{width: 'calc(100% - 2rem)'}}>
                <Head />
            </Header>
            <Layout className="">
                <Sider theme="light" width={200} className="hidden sm:block max-h-screen h-screen fixed" style={{marginTop: '120px'}}>
                    <Side />
                </Sider>
                <Content 
                    className="app-content sm:px-4 h-full"
                >
                    <Route {...rest} render={matchProps => (  
                        <>
                                <AppLayout> 
                                    <Component {...matchProps} />
                                    <Footer>
                                        <div className="flex--basic font-bold text-xs tracking-tight">
                                            <span>© 2020 JackBuild Limited • <a href="https://primeegiftcard.com/" className="text-black text-xs hover:underline" target="_blank" rel="noopener noreferrer">www.primeegiftcard.com</a> • <a href="mailto:support@20pesewas.com" target="_blank" rel="noopener noreferrer" className="text-black hover:underline">support@20pesewas.com</a> • <a href="tel:+233247117448" className="text-black hover:underline">+233 24 711 7448</a> </span>
                                        </div>
                                    </Footer>
                                </AppLayout>  
                            {/* {token ? 
                                <div />
                                : 
                                <Redirect to={{ pathname: '/signin' }} />
                            } */}
                        </>
                    )} />                     
                </Content>
            </Layout>
        </Layout>
    )
}

export default AppLayoutRoute;

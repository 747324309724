import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';

import AutoComplete from './Autocomplete';
import Marker from './Marker';

const Wrapper = styled.main`
  width: 100%;
  height: 100%;
`;

class MyGoogleMap extends Component {
    state = {
        mapApiLoaded: false,
        mapInstance: null,
        mapApi: null,
        geoCoder: null,
        places: [],
        center: [],
        zoom: 12,
        bounds: {
            // nw: '11.175030, -3.260786',
            // ne: '11.175030, 1.199468',
            // sw: '4.738814,  -3.260786',
            // se: '4.738814, 1.199468'
        },
        fixBound: [
            {
                geometry: {
                    location: {
                        lat: "11.175030",
                        lng: "-3.260786"
                    }
                }
            },
            {
                geometry: {
                    location: {
                        lat: "4.738814",
                        lng: "-3.260786"
                    }
                }
            },            
            {
                geometry: {
                    location: {
                        lat: "11.175030",
                        lng: "1.199468"
                    }
                }
            },
            {
                geometry: {
                    location: {
                        lat: "4.738814",
                        lng: "1.199468"
                    }
                }
            }
        ],
        address: '',
        draggable: true,
        lat: null,
        lng: null
    };

    componentWillMount() {
        this.setCurrentLocation();
    }

    onMarkerInteraction = (childKey, childProps, mouse) => {
        // Also compute address info on marker interaction

        this.setState({
            draggable: false,
            lat: mouse.lat,
            lng: mouse.lng
        });
    }
    
    onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
        this.setState({ draggable: true });
        this._generateAddress();
    }

    _onChange = ({ center, zoom }) => {
        this.setState({
            center: center,
            zoom: zoom,
        });

    };

    // Return map bounds based on list of places
    getMapBounds = (map, maps) => {
        const bounds = new maps.LatLngBounds();
        this.state.fixBound.forEach((place) => {
            bounds.extend(new maps.LatLng(
                place.geometry.location.lat,
                place.geometry.location.lng,
            ));
        });

        return bounds;
    };   

    // Re-center map when resizing the window
    bindResizeListener = (map, maps, bounds) => {
        maps.event.addDomListenerOnce(map, 'idle', () => {
        maps.event.addDomListener(window, 'resize', () => {
            map.fitBounds(bounds);
        });
        });
    };    

    _onClick = (value) => {
        console.log('OnClick value:', value);
        this.setState({
            lat: value.lat,
            lng: value.lng
        });
        this._generateAddress();
    }

    _onBoundsChange = (center, zoom /* , bounds, marginBounds */) => {
        console.log('Center:', center, 'Zoom:', zoom);
        // this.props.onCenterChange(center);
        // this.props.onZoomChange(zoom);
    }    

    apiHasLoaded = (map, maps) => {
        this.setState({
            mapApiLoaded: true,
            mapInstance: map,
            mapApi: maps,
        });

        this._generateAddress();

        // // Get bounds by our places
        // const bounds = this.getMapBounds(map, maps, this.state.fixBound);
        // // Fit map to bounds
        // map.fitBounds(bounds);
        // // Bind the resize listener
        // this.bindResizeListener(map, maps, bounds);        
    };

    addPlace = (place) => {
        this.setState({
            places: [place],
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
        });
        this._generateAddress()
    };

    _generateAddress() {
        const { mapApi } = this.state;

        const geocoder = new mapApi.Geocoder();

        geocoder.geocode({ 'location': { lat: this.state.lat, lng: this.state.lng } }, (results, status) => {
            console.log(results);
            console.log(status);
            if (status === 'OK') {
                if (results[0]) {
                    this.zoom = 12;
                    this.setState({ address: results[0].formatted_address });
                } else {
                    window.alert('No results found');
                }
            } else {
                window.alert('Geocoder failed due to: ' + status);
            }

        });
    };

    // Get Current Location Coordinates
    setCurrentLocation() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState({
                    center: [position.coords.latitude, position.coords.longitude],
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                });
            });
        }
    };

    setUserLocation = () => {
        const { places, lat, lng, address } = this.state;
        this.props.handleGo({ places, lat, lng, address })
    };

    render() {
        const { mapApiLoaded, mapInstance, mapApi } = this.state;

        return (
            <Wrapper>
                {mapApiLoaded && (
                    <div>
                        <AutoComplete 
                            map={mapInstance} 
                            mapApi={mapApi} 
                            addplace={this.addPlace} 
                            handleGo={this.setUserLocation}
                        />
                    </div>
                )}
                <GoogleMapReact
                    center={this.state.center}
                    zoom={this.state.zoom}
                    bounds={this.state.bounds}
                    draggable={this.state.draggable}
                    onChange={this._onChange}
                    onBoundsChange={this._onBoundsChange}
                    onChildMouseDown={this.onMarkerInteraction}
                    onChildMouseUp={this.onMarkerInteractionMouseUp}
                    onChildMouseMove={this.onMarkerInteraction}
                    onChildClick={() => console.log('child click')}
                    onClick={this._onClick}
                    bootstrapURLKeys={{
                        key: 'AIzaSyBsnVLVZOQYt7P1IJKKZ_nAoBjlMn5wgjw',
                        libraries: ['places', 'geometry'],
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
                    // layerTypes={['TrafficLayer', 'TransitLayer']}
                >

                    <Marker
                        text={this.state.address}
                        lat={this.state.lat}
                        lng={this.state.lng}
                    />


                </GoogleMapReact>

                {/* <div className="info-wrapper hidden">
                    <div className="map-details">Latitude: <span>{this.state.lat}</span>, Longitude: <span>{this.state.lng}</span></div>
                    <div className="map-details">Zoom: <span>{this.state.zoom}</span></div>
                    <div className="map-details">Address: <span>{this.state.address}</span></div>
                </div> */}
            </Wrapper >
        );
    }
}

export default MyGoogleMap;
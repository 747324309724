import { GET_RESOURCES, SET_COUPONS } from './types';

const INITIAL_STATE = {
    resources: [],
    coupons: {
        list: [],
        meta: {}
    },
    filters: {}    
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {    
        case GET_RESOURCES:
           return {
                ...state, resources: action.payload
           };
        case SET_COUPONS:
            return {
                ...state, coupons: {
                    ...state.coupons,
                    list: action.payload?.coupons,
                    meta: action.payload
                }
            };
        
        default: return state;
    }

};

export default reducer;


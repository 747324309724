import React, { memo } from 'react'
import { Form, Button, Input, Modal } from 'antd'

const TwoFactor = ({ visible, handleProceed, submitting, resend }) => {
  const [form] = Form.useForm();

  const submit = (v) => {
    try {
      form.validateFields()

      handleProceed(v?.otp)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const Loader = ({ color = 'text-white' }) => (
    <svg
      className={`animate-spin -ml-1 mr-3 h-5 w-5 ${color}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  )

  return (
    <Modal 
      title={<h3 className="text-xl text-black font-bold">Two Factor Authentication</h3>}
      className="filter-form relative z-10"
      visible={visible}
      onCancel={false}
      centered
      closable={false}
      footer={false}      
      >
        <Form
          form={form}
          title="twofactor"
          className="w-full" 
          layout="vertical"
          onFinish={submit}
        >
          <Form.Item
            colon={false}
            name="otp"
            label={<div className="text-gray-500 text-sm">OTP Sent To Your Number</div>}
            className="w-full m-0"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input
              type="password"
              autoComplete="off"
              placeholder='123456'
              size="large"
              disabled={submitting}
              className="w-full px-4 mt-2 rounded-md h-12"
            />
          </Form.Item>

          <div className='flex justify-center mx-auto mt-4'>
            <Button
              type="text"
              className='text-xs mx-auto'
              size="small"
              disabled={submitting}
              onClick={() => resend()}
            >
              Didn&apos;t receive OTP? Resend
            </Button>
          </div>

          <Button
            size="large"
            type="default"
            htmlType="submit"
            className="relative w-full trans flex items-center justify-center mt-2 mb-5 bg-primary text-base rounded-md py-2 text-white h-12"
            disabled={submitting}
            loading={submitting}
          >
            {/* {submitting && <Loader />} */}
            SUBMIT
          </Button>
        </Form>
      </Modal>
  )
}

export default memo(TwoFactor);

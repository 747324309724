import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Menu,  Modal, Button, message } from 'antd';
import { HomeOutlined, FolderAddOutlined, UserOutlined, CreditCardOutlined, FundOutlined, DesktopOutlined, ContainerOutlined } from '@ant-design/icons';

import { EventEmitter } from '../events.js';
import ConnectBank from './connectBank';
import clientPhoto from '../assets/images/user.svg'

// Components
import LogoPhoto from "../components/misc/imageUploader";

import { useDispatch, useSelector } from 'react-redux';
import { userDetails, upload_pic, get_logo, updateProfile } from "../store/user.actions";

const { SubMenu } = Menu;


const Side = () => {
  const dispatch = useDispatch();
  const location = useHistory();
  const { data }  = useSelector(state => state.user);
  const [collapsed, setCollapsed] = useState(false);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [hasUploaded, sethasUploaded] = useState(false);
  const [companyLogo, setcompanyLogo] = useState(null);
  const [showPhoto, setshowLogo] = useState(null);
  const [btnload, setbtnload] = useState(false);

  // Methods
  const showModal = () => {
    setVisible(true)
  };  

  const handleOk = (values) => {
    setConfirmLoading(true)
    setTimeout(() => {
      console.log('Received values of form: ', values);
      setVisible(false)
      setConfirmLoading(false)
    }, 2000);
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setVisible(false)
  };

  const handleAction = (event) => {
    EventEmitter.dispatch('toggleTemplate', event)
  }

  const menus = [
    {
      name: 'Home',
      icon: <HomeOutlined />,
      link: '/dashboard',
      submenu: false
    },
    {
      name: 'Add Card',
      icon: <FolderAddOutlined />,
      link: '/add-card',
      submenu: false,
      links: [
        {
          name: 'My Design',
          route: '/add-card'
        },
        {
          name: 'Templates',
          route: '/templates',
          action: true
        }
      ]
    },
    {
      name: 'Card List',
      icon: <CreditCardOutlined />,
      link: '/cards',
      submenu: false,
      links: [
        {
          name: 'Social Media',
          route: '/promotions'
        },
        {
          name: 'Prime In-App',
          route: '/prime-in-app',
          action: true
        }
      ]
    },
    {
      name: 'Coupons',
      icon: <ContainerOutlined />,
      link: '/coupons',
      submenu: false
    },    
    {
      name: 'Merchants',
      icon: <DesktopOutlined />,
      link: '/merchants',
      submenu: false
    },
    {
      name: 'Reports',
      icon: <FundOutlined />,
      link: '/reports',
      submenu: false
    },
    // {
    //   name: 'Fund Request',
    //   icon: <TransactionOutlined />,
    //   link: '/fund-request',
    //   submenu: false
    // },
    {
      name: 'Profile',
      icon: <UserOutlined />,
      link: '/profile',
      submenu: false
    }
  ];

  const setLinkBackground = (link) => {
    if (location.location.pathname === link) return true;
    else return false;
  };

  const uploadPic = (type, file, code) => {
    dispatch(upload_pic(type, file, code))
    .then((res) => {
      switch (type) {
        case 'logo':
          sethasUploaded(!hasUploaded);
        break;
        case 'photo':
          const code = data.person.companies[0].code;
          setshowLogo(res.Location);
          dispatch(updateProfile({ profile_pic_url: res.Location }, code))
        break;
        default:
        break;
      }      

      setbtnload(false);
    })
    .catch(() => {
      setbtnload(false);
    }) 
  };

  const handleBefore = (type, file) => {
    setbtnload(true);
    const code = data.person.companies[0].code;
    uploadPic(type, file, code)
  };

  useEffect(() => {
    dispatch(userDetails())
    .then(res => {
      let { user_details } = res.data?.data;
      setshowLogo(user_details.person.profile_pic_url);
      dispatch(get_logo(user_details.person.companies[0].code))
      .then(res => {        
        setcompanyLogo(res.data?.data.logo_url);
      }, err => {
        console.log('error:', err);
      })
    }, err => {
      console.log('UserDetails Error:', err);
      
      location.push('/');
      message.error('User session expired, login again');
    })
  }, [hasUploaded]);

  return (
    <div className="side--scroll">
      <Menu
        mode="inline"
        theme="light"
        className="uppercase border-r-0 rounded-r-lg"
        inlineCollapsed={collapsed}
      >
        <LogoPhoto 
          type="logo"
          image={companyLogo}
          uploadText="Upload photo"
          btnload={btnload}
          handleBefore={(type, file, code) => {handleBefore(type, file, code)}}
        />  

        <LogoPhoto 
          type="photo"
          image={showPhoto}
          placeholder={clientPhoto}
          btnload={btnload}
          handleBefore={(type, file, code) => {handleBefore(type, file, code)}}
        />  

        <div className="text-center pb-4 w-full h-full">
          <div className="pt-2 pr-4 leading-none break-normal">
            <h2 className="break-words capitalize leading-normal">{data.username}</h2>
            <h3 className="text-xs font-bold break-normal">Manager</h3>
          </div>
        </div>
                
        {menus.map((menu, index) => (
          menu.submenu ?
            <SubMenu 
              className={`flex items-center uppercase subM ${location.location.pathname === menu.link ? 'bg-active' : ''}`}
              key={index + 1}
              title={menu.name}
              icon={menu.icon}
            >
              {menu.links.map((sub, index) => (
                <Menu.Item 
                  className="bg-active my-0 uppercase"
                  key={index + 1}>
                  {sub.action ?
                    <div onClick={() => {handleAction('template')}}>
                      {sub.name}
                    </div>
                  :
                    <Link to={sub.route} onClick={() => {handleAction('design')}}>
                      {sub.name}
                    </Link>
                  }
                </Menu.Item>
              ))}
            </SubMenu>
          
          :
            <Menu.Item 
              className={`flex items-center my-1 ${setLinkBackground(menu.link) ? 'bg-active' : ''}`}
              key={index + 1}
              icon={menu.icon}
            >
              <Link to={menu.link}>
                {menu.name}
              </Link>          
            </Menu.Item>          
        ))}

      {/* 
        <div className="pl-6 my-6">
          <Button type="" className="mr-3 bg-yellow-500 rounded-full text-black border border-black" onClick={showModal}>
            Link Bank
          </Button>
        </div> 
      */}
      </Menu>

      {/* Link to bank */}
      <Modal
          title="Link Bank Account"
          visible={visible}
          onOk={handleOk}
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
        >
          <ConnectBank  />
        </Modal>
    </div>
  )
};

export default Side;

import React, { Component } from 'react';
import { Button } from "antd";
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  display: flex,
  align-items: center;
  justify-content: between;
  width: 100%;
  padding-bottom: 20px;
  padding-top: 20px;
  text-align:center;
`;

class AutoComplete extends Component {
    constructor(props) {
        super(props);
        this.clearSearchBox = this.clearSearchBox.bind(this);
    }

    componentDidMount({ map, mapApi } = this.props) {
        const options = {
            // restrict your search to a specific type of result
            // types: ['address'],
            // restrict your search to a specific country, or an array of countries
            componentRestrictions: { country: ['gh'] },
        };
        this.autoComplete = new mapApi.places.Autocomplete(
            this.searchInput,
            options,
        );
        this.autoComplete.addListener('place_changed', this.onPlaceChanged);
        this.autoComplete.bindTo('bounds', map);
    }

    componentWillUnmount({ mapApi } = this.props) {
        mapApi.event.clearInstanceListeners(this.searchInput);
    }

    onPlaceChanged = ({ map, addplace } = this.props) => {
        const place = this.autoComplete.getPlace();
        console.log('Selected Place:', place);

        if (!place.geometry) return;
        if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
        } else {
            map.setCenter(place.geometry.location);
            map.setZoom(17);
        }

        addplace(place);
        this.searchInput.blur();
    };

    clearSearchBox() {
        this.searchInput.value = '';
    }

    handleGo = () => {
        this.props.handleGo()
    }

    render() {
        return (
            <Wrapper>
                <input
                    className="search-input px-2 py-1"
                    ref={(ref) => {
                        this.searchInput = ref;
                    }}
                    type="text"
                    onFocus={this.clearSearchBox}
                    placeholder="Name of Location/Branch"
                />
                <Button type="primary" size="large" className="bg-primary text-white text-base"
                    onClick={this.handleGo}
                >GO</Button>
            </Wrapper>
        );
    }
}

export default AutoComplete;
import React, { useState, useEffect } from 'react';
import { Link,  useHistory } from 'react-router-dom';
import { Form, Input, Button, message } from 'antd';

// Components
import WelcomePages from "../components/WelcomePage";

// Redux Actions
import { useDispatch } from 'react-redux';
import { signin, userDetails, get_Ad_Images } from "../store/user.actions";

const SignIn = () => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const history = useHistory();
	const [formData, setFormData] = useState({
		forname: "",
		password: ""
	});
	// const [type, setType] = useState("password");
	// const { token, error }  = useSelector(state => state.user);
	const [loading, setloading] = useState(false);
	const [Images, setImages] = useState([]);
	

	const onFinish = () => {
		setloading(true);

		form.validateFields()
		dispatch(signin(formData.forname, formData.password))
		.then(
			res => { 
				setloading(false);
				const { status } = res;
				console.log('Status:', status);

				switch (status) {
					case 200:
						if (res.data.success) {
							dispatch(userDetails())
							// .then(response => {
							// 	if (response.success) {
							// 		message.success("Login success");
							// 		// history.push('/dashboard');
							// 	} else {
							// 		message.error(response.error_msg);
							// 	}
							// }, err => {
							// 	message.error("Fetching user infor failed")
							// })

							// Route to dashboard page regardless.
							history.push('/dashboard');	
						} else {
							console.log('what is here?', res.data.error);
						}					
						break;
					case 401:
						message.error(res.error_msg + '. Please provide accurate login data');
						break;
					case 400:
						message.error(res.error_msg);
						break;
					case 404:
						message.error(res.error_msg)
						break;
					default:
						break;
				}
				// } else {
				// 	console.log('Error:', res.response.data);
				// 	message.error(res.response.data.error_msg);
				// 	setloading(false);						
				// }                
			}
		);			
	};

	const handleInput = (val) => {
		let strValue = Object.values(val)[0];
		setFormData({
			...formData,
			[Object.keys(val)[0]]: strValue.trim()
		});
	};
	
	useEffect(() => {
        dispatch(get_Ad_Images({'ad_types[]': 'sign_in'}))
        .then((response) => {
            console.log('Images:', response.data.data.images)
            setImages(response.data.data.images)
        }, () => {
            console.log('Error occured fetching images')
        })		
	}, [])

    useEffect(() => {
        dispatch(get_Ad_Images({'ad_types[]': 'sign_in'}))
        .then((response) => {
            console.log('Images:', response.data.data.images)
            setImages(response.data.data.images)
        }, () => {
            console.log('Error occured fetching images')
        })
    }, []);	

	return (
		<WelcomePages 
			type="slider" 
			slider={Images}
		>
			<div className="w-7/12 bg-white rounded-large px-8 py-6 text-center shadow-2xl">
				<div className="font-bold text-lg uppercase pb-6 relative">
					<span>Sign In</span>
				</div>
				<Form
					name="custom"
					form={form}
					className="w-full signup"
					layout="vertical"
					onFinish={onFinish}
					onValuesChange={handleInput}
				>
					<Form.Item 
						name="forname"
						rules={[
							{
								required: true,
								message: 'Please provide either username or phone',
							}								
						]}								
					>
						<Input 
							placeholder="Username/Phone"
						/>
					</Form.Item>
					<Form.Item 
						name="password"
						rules={[
							{
								required: true,
								message: 'Please provide password',
							}								
						]}								
					>
						<Input.Password
							className="p-0"
							placeholder="Password"
						/>
					</Form.Item>
					<div>
						<Link to="/forgot-password">
							<span>Forgot Password?</span>
						</Link>
					</div>
					<Form.Item className="pt-6">
						<Button
							htmlType="submit"
							type="primary"
							className="bg-primary w-full uppercase font-semibold"
							shape="round"
							size="large"
							loading={loading}
						>
							Enter
						</Button>
					</Form.Item> 
				</Form>

				<div className="flex--basic pt-2">
					<Link to="/">
						<span className="px-2 hover:underline">Welcome</span>
					</Link>
					|
					<Link to="/signup">
						<span className="px-2 hover:underline">Signup</span>
					</Link>		
				</div>					
			</div>

		</WelcomePages>
	)
};

export default SignIn;
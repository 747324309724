import React, { useEffect } from 'react';
import { Chart, registerShape } from '@antv/g2';

export default function Overview({ width, height, auto, force, font }) {
  useEffect(() => {
    const data = [
      { month: 'Jan', sale: 0 },
      { month: 'Feb', sale: 12 },
      { month: 'Mar', sale: 8 },
      { month: 'Apr', sale: 20 },
      { month: 'May', sale: 25 }
    ];

    const sliceNumber = 0.01;

    registerShape('interval', 'slice-shape', {
      draw(cfg, container) {
        const points = cfg.points;
        let path = [];
        path.push(['M', points[0].x, points[0].y]);
        path.push(['L', points[1].x, points[1].y - sliceNumber]);
        path.push(['L', points[2].x, points[2].y - sliceNumber]);
        path.push(['L', points[3].x, points[3].y]);
        path.push('Z');
        path = this.parsePath(path);
        return container.addShape('path', {
          attrs: {
            fill: cfg.color,
            path,
          },
        });
      },
    });

    // Step 1:
    const chart = new Chart({
      container: 't1',
      width: width || 300,
      autoFit: auto || false,
      forceFit: force || false,
      height: height || 300
    });

    // Step 2: 
    chart.data(data);

    chart.coordinate('theta', { radius: 0.6, innerRadius: 0.6, });
    chart.tooltip({ showTitle: false, showMarkers: false });
    chart.animate('wave-in');
    chart.tooltip({ showMarkers: false });
    chart.annotation()
      .text({
        position: ['50%', '50%'],
        content: 'Total Sales',
        style: {
          fontSize: font || 14,
          fill: '#003F2D',
          textAlign: 'center',
        },
        offsetY: -15,
      })
      .text({
        position: ['50%', '50%'],
        content: '0',
        style: {
          fontSize: 20,
          fill: '#003F2D',
          stroke: 5,
          textAlign: 'center',
        },
        // offsetX: -10,
        offsetY: 10,
      })

    chart
      .interval()
      .adjust('stack')
      .position('sale')
      .color('month')
      .shape('slice-shape');
    // Step 4:
    chart.render();
  }, [auto, font, height, force, width])

  return (
    <>
      <div id="t1"></div>
    </>
  )
};
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Card, Button, Divider, Skeleton, Modal, Table, Form, Select, message,
  Tooltip, Pagination, DatePicker, Space } from "antd";
import { PlusOutlined, DeleteOutlined, ExclamationCircleOutlined, EditOutlined } from '@ant-design/icons';
import moment from "moment";
import utils from "../utils/services";

import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "../store/merchants/actions";
import { get_coupons, remove_code } from "../store/general/actions";
import AddEditCoupon from "src/components/AddEditCoupon";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { confirm } = Modal;

const Coupons = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { filters: merchant_filters } = useSelector((state) => state.merchants);
  const { coupons } = useSelector((state) => state.general);

  const [loading, setloading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [checkAll, setcheckAll] = useState(false)

  const [meta, setmeta] = useState({
    current_page: 1,
    current_page_total: 2,
    total_pages: 1,
    total_filtered: 10,
    total_records: 10     
  });
  const [filters, setfilters] = useState({
    limit: '20',
    page: 1
  });
  const [details, setdetails] = useState({});

  const columns = [
    {
      key: "name",
      dataIndex: "name",
      title: "Name",
      render: (text) => <div>{text}</div>
    },
    {
      key: "description",
      dataIndex: "description",
      title: "Description",
      render: (text) => <div className="capitalize">{text || "N/A"}</div>
    },
    {
      key: "total",
      dataIndex: "total",
      title: "Total"
    },
    {
      key: "created_at",
      dataIndex: "created_at",
      title: "Created At",
      render: (date) => <div>{moment(date).format('MMMM Do YYYY, h:mm:ss a')}</div>
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <EditOutlined
              className="p-2 rounded-full hover:bg-gray-200 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setdetails(record);
                localStorage.setItem('details', JSON.stringify(record));
                setVisible(true);
              }}
            />
          </Tooltip>
        </Space>
      ),
    }      
  ];

  const onChange = (e, type) => {
    setFilters(dispatch(setFilters(type, e)));
  };

  const handleDateChange = (dates, dateStrings = "") => {
    if (dateStrings?.length > 0) {
      setFilters(dispatch(setFilters("from", dateStrings[0])));
      setFilters(dispatch(setFilters("to", dateStrings[1])));
    }
  };

  const onShowSizeChange = (current, pageSize) => {
    console.log(current, pageSize);
  };

  const pageChanger = (page, pageSize) => {
    setfilters({...filters, page: page, limit: pageSize });
  };

  const getCoupons = (id="", cached=true) => {
    setloading(true);
    dispatch(get_coupons(id, cached))
    .then((response) => {
      setmeta(response.data?.data);
      setloading(false);
    }, (err) => {
      console.error('Error:', err);
      setloading(false);
    });
  };

  const removeCoupon = () => {
    console.log('Checked Tags', selectedRowKeys);
    setloading(true);

    const promises = selectedRowKeys?.map(async item => {
      const coupon = await dispatch(remove_code(item));
      return coupon; 
    });
  
    return Promise.all(promises)
    .then((res) => {
      return new Promise((resolve) => {
        message.success('Coupon Tags have been deleted.');
        getCoupons("", false);
        setSelectedRowKeys([]);
        setcheckAll(false);
        resolve(res);
      })
    }, (err) => {
      setloading(false);
      message.error('Error deleting coupon, please try again');
      console.log('Error removing coupon', err);
    })
    .catch(() => console.log('Oops errors!'));      
  }

  const onSelectChange = (newSelectedRowKeys) => {
    if (newSelectedRowKeys.length > 0) {
      setcheckAll(true);
    } else {
      setcheckAll(false);
    }
    
    setSelectedRowKeys(newSelectedRowKeys);
  };  

  const showDeleteConfirm = (
    msg="Are you sure you want to delete this", 
    description="This action is irreversible"
  ) => {
    confirm({
      title: msg,
      icon: <ExclamationCircleOutlined />,
      content: description,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        removeCoupon()
        .then((res) => {
          getCoupons("", false)
        })
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };   

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  const handleUpdate = () => {
    setVisible(false);
    getCoupons("", false);
  }; 

  useEffect(() => {
    if (utils.present(coupons?.meta)) {
      setmeta({
        ...meta,
        ...coupons?.meta
      })
    }
  }, [coupons]);
  
  useEffect(() => {
    getCoupons("", false);
  }, []);

  return (
    <>
      <Row className="my-6">
        <Col span={24} className="pl-4">
          <Card className="rounded-large shadow-lg min-h-half">
            <h2 className="text-xl font-bold mb-0 text-center">Coupons</h2>

            <div className="flex justify-end">
              <Link to="/coupon/new">
                <Button
                  type="primary"
                  className="flex--basic text-white text-base px-4 shadow-none"
                  shape="round"
                >
                  <PlusOutlined />
                  Add Coupons
                </Button>
              </Link>              
            </div>

            <Divider />

            <Skeleton loading={loading} active>
              <section className="flex items-start hidden">
                <h4 className="font-bold">Filter:</h4>
                <Form
                  name="filter"
                  className="flex flex-wrap w-full justify-end font-normal filter-form"
                >
                  <div className="w-1/3">
                    <Form.Item
                      name="date"
                      label="Date Range"
                      className="w-full pr-4"
                    >
                      <RangePicker
                        allowClear
                        ranges={{
                          Today: [moment(), moment()],
                          "This Month": [
                            moment().startOf("month"),
                            moment().endOf("month"),
                          ],
                        }}
                        className="w-full bg-white"
                        onChange={handleDateChange}
                      />
                    </Form.Item>
                  </div>

                  <div className="w-1/4">
                    <Form.Item
                      name="statuses"
                      label="Status"
                      className="w-full pr-4"
                    >
                      <Select
                        mode="multiple"
                        placeholder="Select"
                        value={merchant_filters?.statuses}
                        onChange={(e) => onChange(e, "statuses[]")}
                      >
                        <Option value="active">Active</Option>
                        <Option value="archived">Archived</Option>
                        <Option value="used">Used</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </Form>
              </section>

              {coupons?.list?.length <= 0 ? (
                <div className="flex--basic" style={{ minHeight: '40vh'}}>
                  <div className="py-5 w-full text-center"> {/* border-t border-gray-300  */}
                    <div className="font-bold">No Coupons Available</div>
                  </div>
                </div>
              ) : (
                <>
                  {checkAll ? 
                    <div className="pb-4 transition duration-500 ease-in-out">
                      <div className=""
                        onClick={() => showDeleteConfirm("Are you sure you want to delete coupon(s)?")} 
                      >
                        <Button className="flex items-center p-2 hover:bg-gray-200 rounded cursor-pointer">
                          <DeleteOutlined className="text-lg pr-2" />
                          Delete
                        </Button>
                      </div> 
                    </div>
                  :''
                  }

                  <Table
                    columns={columns}
                    className="cursor-pointer"
                    onRow={(record) => {
                      return {
                        onClick: () => {
                          localStorage.setItem('details', JSON.stringify(record));
                          setdetails(record);

                          if (record?.name && localStorage.getItem('details')) {
                            history.push(`/coupon/${record?.name?.toLowerCase()?.replaceAll(" ", "-")?.toString()}`)
                          }
                        },
                      };
                    }}   
                    rowSelection={rowSelection}
                    dataSource={coupons?.list.map((el, index) => {
                      return { key: el?.tag_id || index, ...el }
                    })}
                    pagination={false}
                    loading={loading}
                  />

                  <div className="pt-3 flex justify-end">
                    <Pagination 
                      className="filter-form"
                      showSizeChanger
                      onChange={pageChanger}
                      onShowSizeChange={onShowSizeChange}
                      total={meta?.total_records} 
                      defaultPageSize={meta?.current_page_total}
                      defaultCurrent={meta?.current_page}                      
                    />
                  </div>
                </>
              )}
            </Skeleton>
          </Card>
        </Col>
      </Row>

      <Modal 
        title="Coupons Details" 
        visible={visible}
        onCancel={() => setVisible(false)}
        className="w-full sm:w-1/2"
        centered
        footer={null}
      >
        <AddEditCoupon 
          mode="EDIT"
          disableFields={false}
          formStyle="w-full"
          updateData={details}
          setshow={setVisible}
          handleUpdate={handleUpdate}
        />
      </Modal>      
    </>
  );
};

export default Coupons;

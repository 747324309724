import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Image, Button, Divider, Form, Input, notification, Upload, message, Select, Tooltip } from 'antd';
import { FormOutlined, RadiusUprightOutlined } from "@ant-design/icons";
import { sendToBucket } from '../_services/uploader';
import ImgCrop from 'antd-img-crop';
import Cookies from "js-cookie";
import utils from '../utils/services';

// Components
import PickLocation from "../components/PickLocation";
import TwoFactor from 'src/components/misc/TwoFactor';

// Images
import placeholder from "../assets/images/placeholder.png";

import { useDispatch, useSelector } from 'react-redux';
import { userDetails, get_profile_pic, updateProfile, changePassword, requestOTP } from "../store/user.actions";
import { getBankDetails, add_bank_account, getSupportedBanks, getBankBranch, getMoMoNetworks } from "../store/general/actions";

const { Dragger } = Upload;
const { Option } = Select;

const Profile = () => {
    const dispatch = useDispatch();
    const { data: userData }  = useSelector(state => state.user);
    const [form] = Form.useForm();
    const [passForm] = Form.useForm();
    const [bankForm] = Form.useForm();
    const [profilePic, setprofilePic] = useState('');
    const [loading, setloading] = useState(true);
    const [load, setload] = useState(false);
    const [BankDetails, setBankDetails] = useState({});
    const [loadaccount, setloadaccount] = useState(true);
    const [showMap, setshowMap] = useState(false);
    const [disbursementInfo, setdisbursementInfo] = useState({});
    const [supportedBanks, setsupportedBanks] = useState([]);
    const [bankBranches, setbankBranches] = useState([]);
    const [MomoNetworks, setMomoNetworks] = useState([]);
	const [step, setstep] = useState(0);
    const [visible, setvisible] = useState(false);
    const [submitting, setsubmitting] = useState(false)

    const [info, setinfo] = useState({
        address: "",
        latitude: "",
        longitude: ""
    });

    function handleChangeLocation (info){
        console.log('Map Info:', info);
        if (info.address) {
            form.setFieldsValue({ location: info.address })

            setinfo({
                address: info.address,
                latitude: `${info.lat}`,
                longitude: `${info.lng}`
            });
        }


        setshowMap(false);
    };

    const propValue = {
        name: "file",
        multiple: false,
        action: null,
        showUploadList: false,
    
        beforeUpload: (file) => {
            sendToBucket(file)
            .then((res) => {
                setprofilePic(res.Location);
                const code = userData.person.companies[0].code;
                dispatch(updateProfile({
                    user: {
                        profile_pic_url: res.Location
                    }
                 }, code))
                .then((res) => {
                    console.log('Response from profile update:', res.data);
                    message.success('Profile Photo updated successfully');
                    dispatch(userDetails())
                }, () => {
                    message.error('Error updating profile photo, please try again');
                })
            })
          return false;
        },
    
        onChange(info) {
          const { status } = info.file;

          if (status !== "uploading") {
            console.log("uploading:", info.file);
          }
    
          if (status === "done") {
            message.success(`${info.file.name} file uploaded successfully.`);
          } else if (status === "error") {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
    };    

	const onFinish = () => {
		setloading(true);

		try {
			form.validateFields()

            let data = form.getFieldsValue();
            console.log('Form Data:', data);

            data.user = {
                username: data.username,
                first_name: data.first_name,
                surname: data.surname,
                telephone: data.phone_number,
                // address: data.address,
                gender: data.gender,
                profile_pic_url: data.profile_pic_url
            };

            data.cordinates = info

            const code = userData.person.companies[0].code;
			dispatch(updateProfile(data, code))
			.then((response) => {
				// if (response.data?.success) {
				// 	notification.success({
				// 		message: 'Profile updated successfully'
				// 	});
				// } else {
				// 	switch (response.data?.status) {
				// 		case 400:
				// 			notification.error({
				// 				message: response.data.error_msg
				// 			});
				// 		break;
					
				// 		default:
				// 		break;
				// 	};
				// }


				setloading(false);
				const { status } = response;
				console.log('Status:', status);

				switch (status) {
					case 200:
						if (response.data.success) {
                            notification.success({
                                message: 'Profile updated successfully'
                            });                            
						} else {
							console.log('what is here?', response.data.error);
						}					
						break;
					case 400:
						// message.error(response.error_msg);
                        notification.error({
                            // message: response.data.error_msg
                            message: response.error_msg
                        });                        
						break;
					case 404:
						message.error(response.error_msg)
						break;
					default:
						break;
				}                
			})		
		} catch (error) {
            notification.error({
                message: 'Error updating profile'
            });
			setloading(false);
		}		
    };

    const resetPassword = () => {
        setload(true);

		try {
			passForm.validateFields()

            let data = passForm.getFieldsValue();
			dispatch(changePassword(data))
			.then((response) => {
				if (response.data?.success) {
					notification.success({
						message: 'Password reset successfully'
                    });
                    // passForm.resetFields()
				} else {
					switch (response.data?.status) {
						case 400:
							notification.error({
								message: response.data.error_msg
							});
						break;
					
						default:
						break;
					};
				}
				setload(false);
			})		
		} catch (error) {
            notification.error({
                message: 'Error resetting password'
            });
			setload(false);
		}	
    };

    const fetchBankAccount = () => {
        setloadaccount(true);
        dispatch(getBankDetails())
        .then((res) => {
            setloadaccount(false);
            console.log('Bank Details:', res.bank_account)
          setBankDetails(res.bank_account);
          setloading(false);
        }, () => {
            setloadaccount(false);
        });
    };  

    const fetchBankBraches = (bank) => {
        dispatch(getBankBranch(bank))
        .then((res) => {
            setloadaccount(false);
            console.log('Bank Branches:', res)
            setbankBranches(res?.branches)
          setloading(false);
        }, () => {
            setloadaccount(false);
        });
    };  
    
    const fetchBanksMomo = async () => {
        setloadaccount(true);
        // getSupportedBanks, getBankBranch, getMoMoNetworks
        dispatch(getSupportedBanks())
        .then((res) => {
            setloadaccount(false);
            console.log('Banks:', res)
            setsupportedBanks(res.banks)
          setloading(false);
        }, () => {
            setloadaccount(false);
        });  

        dispatch(getMoMoNetworks())
        .then((res) => {
            setloadaccount(false);
            console.log('Momo Wallets:', res)
            setMomoNetworks(res.providers)
          setloading(false);
        }, () => {
            setloadaccount(false);
        });        
    };

    const fetchProfileInfo = () => {
        dispatch(userDetails())
        .then(res => {
            let { user_details } = res.data?.data;
            let { person } = user_details;

            setprofilePic(user_details.person.profile_pic_url);

            let formField = {
                email: user_details.email,
                first_name: person.first_name,
                surname: person.surname,
                name: person.name,
                gender: person.gender_id,
                profile_pic_url: person.profile_pic_url,
                phone_number: user_details.phone_number || 'N/A',
                username: user_details.username || 'N/A',

                location: person.companies[0].cordinates?.address  || 'N/A',
                latitude: person.companies[0].cordinates?.latitude,
                longitude: person.companies[0].cordinates?.longitude,
                
                description: person.companies[0].description  || 'N/A',
                address: person.companies[0].address  || 'N/A',
                business_registration_number: person.companies[0].business_registration_number,
                tin_number: person.companies[0].tin_number,
                website_url: person.companies[0].website_url || 'N/A'
            };

            form.setFieldsValue(formField);
            setinfo(person.companies[0].cordinates);

            dispatch(get_profile_pic(user_details.person.companies[0].code))
            .then(res => {
                setprofilePic(res.data?.data.profile_pic_url);
            }, err => {
                message.error(err)
            })      
        }, err => {
          console.log('UserDetails Error:', err);     
        });        
    }

    const setBankAccount = () => {
        try {
            bankForm.validateFields();
    
            let data = bankForm.getFieldsValue();

            setloadaccount(true);
            dispatch(add_bank_account(data, utils.present(BankDetails) ? "Update" : "Add" ))
            .then((response) => {
                if (response.data?.success) {
                    notification.success({
                        message: 'Disbursment account added successfully'
                    });

                    fetchBankAccount();
                } else {
                    switch (response.data?.status) {
                        case 400:
                            notification.error({
                                message: response.data.error_msg
                            });
                        break;
                    
                        default:
                        break;
                    };
                }
                setloadaccount(false);
            })		

        } catch (error) {
            notification.error({
                message: 'Error setting banking details, please try again or contact support. Thank you'
            });
            setloadaccount(false);
        }

    };

    const handleProceed = (code="") => {
        // Handle twoFactor here!!!
        setsubmitting(true);
        dispatch(requestOTP({ "code": code}, 'POST'))
        .then((res) => {
            console.log('respons on final verification:', res)
            
            if (res.status) {
                switch (res.status) {
                    case 200:
                    case 201:
                        notification.success({
                            message: '2Factor Verification Completed',
                            description: res?.data?.data?.message
                        })
                        localStorage.setItem('twoFa', code);
                        setsubmitting(false);
                        setvisible(false)
                        break;

                    case 400:
                        notification.error({
                            message: 'Error',
                            description: res?.error_msg
                        })
                        setsubmitting(false);
                        break;
                
                    default:
                        notification.error({
                            message: 'Error',
                            description: res.error_msg || res.message
                        })
                        setsubmitting(false);                    
                        break;
                }
            }

            return;
        }, (err) => {
            console.error('Error:', err);
            notification.success({
                message: 'Error!',
                description: 'Error verifying with twoFactor'
            })
            setsubmitting(false);
        })        
    }

    const resend = useCallback(() => {
        setsubmitting(true);

        const params = {
            phone_number: userData?.phone_number,
        }

        dispatch(requestOTP(params, 'GET'))
        .then((res) => {
            notification.success({
                message: 'OTP SENT!',
                description: res.data.data.message
            })
            setsubmitting(false);
            return;
        }, (err) => {
            console.error('Error:', err);
            notification.success({
                message: 'Error!',
                description: 'Error sending OTP, kindly check your phone number or email and try again'
            })            
            setsubmitting(false);
        })
    }, [])

    useEffect(() => {
        if (utils.present(BankDetails)) {
            let bankInfo = { account_type: "bank", ...BankDetails }
            setdisbursementInfo(bankInfo)
            bankForm.setFieldsValue(bankInfo);

            if (bankInfo.bank) fetchBankBraches(bankInfo.bank);
        }
        return () => {}
    }, [BankDetails]);

    useEffect(() => {
        if (!localStorage.getItem('twoFa')) {
            setvisible(true);
            resend();
            // if (utils.present(userData)) {
            // }
            // notification.info({ message: 'Show Prompt'})
        }
    }, [localStorage.getItem('twoFa')])

    useEffect(() => {
        fetchProfileInfo()
        fetchBankAccount();
        fetchBanksMomo()

        return () => {}
    }, []);    

    return (
      <div className="relative">
        <Row className="mb-6">
          <Col span={12} className="px-4">
            <h2 className="text-base font-bold mb-0">User Profile</h2>
            <section className="bg-white rounded-lg shadow-lg mt-2">
                <div className="flex--basic px-10 pt-10 pb-3">
                    <div className="profile">
                        <Image
                            width={200}
                            height={200}
                            src={profilePic? profilePic : placeholder}
                            placeholder={
                            <img
                                width={204}
                                height={204}    
                                src={placeholder}
                                className="rounded-full"
                                alt="placeholder"
                            />
                            }
                        />

                        <div className="flex--basic pt-6">
                            <ImgCrop rotate>
                                <Dragger {...propValue} className="pt-4 company_logo relative">
                                    <Button loading={loading} type="primary" className="bg-primary rounded-full flex--basic shadow-lg hover:opacity-75">
                                        <FormOutlined className="mr-1" />
                                        <span>Update Photo</span>
                                    </Button>
                                </Dragger>
                            </ImgCrop>
                        </div>
                    </div>
                </div>
                <Divider />
                <div className="flex--basic px-10">
                    <Form
                        name="profile-custom"
                        form={form}
                        className="w-full signup profile-custom"
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <div className="w-full flex">
                            <div className="w-1/2">
                                <Form.Item 
                                    name="first_name"
                                    label="Firstname"
                                    className="mr-2"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please provide your full name',
                                        }
                                    ]}
                                >
                                    <Input className="h-8" />
                                </Form.Item>
                            </div>
                            <div className="w-1/2">
                                <Form.Item 
                                    name="surname"
                                    label="Surname"
                                    className="ml-2"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please provide your full name',
                                        }
                                    ]}
                                >
                                    <Input className="h-8" />
                                </Form.Item>
                            </div>
                        </div>                      
                        <div className="w-full flex">
                            <div className="w-1/2">
                                <Form.Item 
                                    name="username"
                                    label="Username"
                                    className="mr-2"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please provide a username',
                                        }
                                    ]}
                                >
                                    <Input className="h-8" />
                                </Form.Item>
                            </div>
                            <div className="w-1/2">
                                <Form.Item 
                                    name="gender"
                                    label="Gender"
                                    className="ml-2"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select your gender',
                                        }
                                    ]}
                                >
                                    <Select className="h-8 w-full text-left">
                                        <Option value="male">Male</Option>
                                        <Option value="female">Female</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>                      
                        <div className="w-full flex">
                            <div className="w-1/2">
                                <Form.Item 
                                    name="phone_number"
                                    className="mr-2"
                                    label="Phone Number"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please provide your phone number',
                                        }
                                    ]}
                                >
                                    <Input className="h-8" />
                                </Form.Item>                                
                            </div>
                            <div className="w-1/2">
                                <Form.Item 
                                    name="email"
                                    className="ml-2"
                                    label="Email"
                                    rules={[
                                        {
                                            type: 'email',
                                            required: true,
                                            message: 'Please enter your email',
                                        }
                                    ]}
                                >
                                    <Input className="h-8" />
                                </Form.Item>                                
                            </div>
                            </div>  

                        <div className="w-full flex items-end">
                            <div className="w-1/2">
                                <Form.Item 
                                    name="description"
                                    className="mr-2"
                                    label="Business Description"
                                >
                                    <Input.TextArea className="h-min-8" />
                                </Form.Item>                                
                            </div>                            
                            <div className="w-1/2">
                                <Form.Item 
                                    name="website_url"
                                    className="ml-2"
                                    label="Website"
                                >
                                    <Input className="h-8" />
                                </Form.Item>
                            </div>
                        </div>                        
                        <div className="w-full flex">
                            <div className="w-1/2 cursor-pointer" onClick={() => { setshowMap(true) }}>
                                <Form.Item 
                                    name="location"
                                    className="mr-2"
                                    label="Location/branch"
                                    required
                                >
                                    {/* <Input className="h-8" /> */}
                                    <Input className="h-8 mr-4 px-0 w-full read" 
                                        suffix={
                                            <Tooltip title="Show Map">
                                                <RadiusUprightOutlined className="bg-white pl-2 cursor-pointer border-0" />
                                            </Tooltip>
                                        } 
                                        prefix={
                                            <RadiusUprightOutlined className="cursor-pointer border-0 hidden" />
                                        } 
                                        readOnly
                                    />                                    
                                </Form.Item>                                
                            </div>
                            <div className="w-1/2">
                                <Form.Item 
                                    name="address"
                                    className="ml-2"
                                    label="Address"
                                >
                                    <Input className="h-8" />
                                </Form.Item>                                
                            </div>
                        </div>
                        <div className="w-full flex">
                            <div className="w-1/2">
                                <Form.Item 
                                    name="business_registration_number"
                                    className="mr-2"
                                    label="Business Registration No."
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please provide your business registration',
                                        }
                                    ]}
                                >
                                    <Input className="h-8" />
                                </Form.Item>
                            </div>
                            <div className="w-1/2">
                                <Form.Item 
                                    name="tin_number"
                                    className="ml-2"
                                    label="Tin Number"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please provide tin number',
                                        }
                                    ]}
                                >
                                    <Input className="h-8" />
                                </Form.Item>
                            </div>
                        </div>                        
                        <Form.Item className="py-6 m-0">
                            <div className="flex--basic">
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    className="bg-primary px-12 uppercase font-semibold"
                                    shape="round"
                                    // size="large"
                                    loading={loading}
                                >
                                    Update
                                </Button>
                            </div>
                        </Form.Item> 
                    </Form>                
                </div>              
            </section>
          </Col>
          <Col span={12} className="pl-6">
                <div>
                    <h2 className="text-base font-bold mb-0">Password</h2>
                    <section className="bg-white rounded-lg shadow-lg mt-2">
                        <div className="flex--basic px-10 pt-10 pb-3">
                            <Form
                                name="profile-custom"
                                form={passForm}
                                className="w-full signup profile-custom"
                                layout="vertical"
                                onFinish={resetPassword}
                            >
                                <Form.Item 
                                    name="current_password"
                                    label="Current Password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please provide your current password',
                                        }
                                    ]}
                                >
                                    <Input.Password className="h-8" />
                                </Form.Item>
                                <Form.Item 
                                    name="new_password"
                                    label="New Password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please provide your new password',
                                        }
                                    ]}
                                >
                                    <Input.Password className="h-8" />
                                </Form.Item>
                                <Form.Item 
                                    name="new_password_confirmation"
                                    label="Confirm New Password"
                                    rules={[
										{
											required: true,
											message: 'Please confirm your new password',
										},
										({ getFieldValue }) => ({
											validator(rule, value) {
											if (!value || getFieldValue('new_password') === value) {
												return Promise.resolve();
											}
											return Promise.reject('The new password do not match with the confirm password');
											},
										})															
									]}
                                >
                                    <Input.Password className="h-8" />
                                </Form.Item>
                                <Form.Item className="py-6 m-0">
                                    <div className="flex--basic">
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                            className="bg-primary px-10 uppercase font-semibold"
                                            shape="round"
                                            loading={load}
                                        >
                                            Reset Password
                                        </Button>
                                    </div>
                                </Form.Item> 
                            </Form>                
                        </div>  
                    </section>
                </div>

                <div className="pt-8">
                    <h2 className="text-base font-bold mb-1">Set-Up Disbursement Account</h2>
                    <section className="bg-white rounded-lg shadow-lg mt-2">
                        <div className="flex--basic px-10 pt-10 pb-3">
                            <Form
                                name="profile-custom"
                                form={bankForm}
                                className="w-full signup profile-custom"
                                layout="vertical"
                                onFinish={setBankAccount}
                                onValuesChange={(e) => { 
                                    setdisbursementInfo({
                                        ...disbursementInfo,
                                        [Object.keys(e)[0]]: Object.values(e)[0]
                                    });	                                    
                                }}
                            >
                                <div className="font-semibold text-base pb-2"></div>
                                <Form.Item 
                                    name="account_type"
                                    label="Select type of account (Bank/ Momo)"
                                    className="ml-2"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Select type of account (Bank/ Momo)',
                                        }
                                    ]}
                                >
                                    <Select className="h-8 w-full" placeholder="">
                                        <Option value="momo">Mobile Money</Option>
                                        <Option value="bank">Bank</Option>
                                    </Select>
                                </Form.Item>

                                {disbursementInfo?.account_type === "momo" && 
                                    <>
                                        <Form.Item 
                                            name="bank"
                                            label="Select network"
                                            className="ml-2"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select network',
                                                }
                                            ]}
                                        >
                                            <Select className="h-8 w-full">
                                                {MomoNetworks?.map((el, index) =>  <Option value={el.code} key={index}>{el.name}</Option>)}
                                                 {/* <Option value="Vodafone">Vodafone</Option>
                                                <Option value="AirtelTigo">AirtelTigo</Option>
                                                <Option value="other">Other</Option> */}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item 
                                            name="number"
                                            label="Phone Number"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please provide your phone number',
                                                }
                                            ]}
                                        >
                                            <Input className="h-8" />
                                        </Form.Item>
                                        <Form.Item 
                                            name="name"
                                            label="Wallet Name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please provide your wallet name',
                                                }
                                            ]}
                                        >
                                            <Input className="h-8" />
                                        </Form.Item>
                                    </>
                                }
                                
                                {disbursementInfo?.account_type === "bank" && 
                                    <>
                                        <Form.Item 
                                            name="bank"
                                            label="Bank Name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please provide your bank name',
                                                }
                                            ]}
                                        >
                                            {/* <Input className="h-8" /> */}
                                            <Select 
                                                className="h-8 w-full"
                                                onChange={(e)=> {
                                                    bankForm.setFieldsValue({ branch: null })
                                                    fetchBankBraches(e)
                                                }}
                                            >
                                                {supportedBanks?.map((el, index) =>  <Option value={el.code} key={index}>{el.name}</Option>)}
                                            </Select>                                            
                                        </Form.Item>
                                        <Form.Item 
                                            name="branch"
                                            label="Branch"
                                            className="text-left"
                                        >
                                            {/* <Input className="h-8" /> */}
                                            <Select 
                                                className="h-8 w-full text-left"
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => {
                                                  if (option.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 ) {
                                                    return option
                                                  }
                                                }}
                                                showArrow                                                
                                            >
                                                {bankBranches?.map((el, index) =>  <Option value={el.code} key={index}>{el.name}</Option>)}
                                            </Select>                                              
                                        </Form.Item>
                                        <Form.Item 
                                            name="name"
                                            label="Account Name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please provide your account name',
                                                }
                                            ]}
                                        >
                                            <Input className="h-8" />
                                        </Form.Item>
                                        <Form.Item 
                                            name="number"
                                            label="Account Number"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please provide your account number',
                                                }
                                            ]}
                                        >
                                            <Input className="h-8" />
                                        </Form.Item>
                                    </>
                                }

                                <Form.Item className="py-6 m-0">
                                    <div className="flex--basic">
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="bg-primary px-10 uppercase font-semibold text-white"
                                            shape="round"
                                            loading={loadaccount}
                                            // onClick={() => { setBankAccount() }}
                                            disabled={!disbursementInfo?.account_type}
                                        >
                                            {BankDetails ? 'Update' : 'Set Account'}
                                        </Button>
                                    </div>
                                </Form.Item> 
                            </Form>                
                        </div>  
                    </section>
                </div>
          </Col>
        </Row>

        <Row className="mb-6 hidden">
            <Col span={24} className="px-4">
                <h2 className="text-base font-bold mb-0">General Advertisement</h2>
                <section className="bg-white rounded-lg shadow-lg mt-2">
                    <div className="flex--basic px-10 pt-10 pb-3">
                        <div className="">
                            <Image
                                width={800}
                                height={400}
                                style={{ objectFit: "cover"}}
                                src={profilePic? profilePic : placeholder}
                                className="rounded-none object-cover"
                                placeholder={
                                    <img
                                        width={800}
                                        height={400}  
                                        style={{ objectFit: "cover"}}
                                        src={placeholder}
                                        className="w-full"
                                        alt="placeholder"
                                    />
                                }
                            />

                            <div className="flex--basic pt-6">
                                <ImgCrop rotate>
                                    <Dragger {...propValue} className="pt-4 company_logo relative">
                                        <Button loading={loading} type="primary" className="bg-primary flex--basic shadow-lg hover:opacity-75">
                                            <FormOutlined className="mr-1" />
                                            <span>Upload photo</span>
                                        </Button>
                                    </Dragger>
                                </ImgCrop>
                            </div>
                        </div>
                    </div>
                </section>        
            </Col>
        </Row>

        <PickLocation 
            showMap={showMap} 
            setshowMap={setshowMap} 
            handleChangeLocation={handleChangeLocation} 
        />

        <TwoFactor 
            visible={visible}  
            handleProceed={handleProceed} 
            submitting={submitting}
            resend={() => resend()}
        />
    </div>
    );
}

export default Profile;
import {
  AWS_BUCKET, ACCESS_KEY_ID, SECRET_ACCESS_KEY
} from '../store/user.types';
import Utils from '../utils/services';
const S3 = require('aws-sdk/clients/s3');


export function sendToBucket(file) {
    // function getFileExtension (filename) {
    //   return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined
    // }

    // var fileExtension = getFileExtension(file.name)

    var BucketName = AWS_BUCKET
    var accessKeyId = ACCESS_KEY_ID
    var SecretAccessKey = SECRET_ACCESS_KEY

    var s3 = new S3({
      apiVersion: '2006-03-01',
      region: 'us-east-2',
      accessKeyId: accessKeyId,
      secretAccessKey: SecretAccessKey,
      params: {Bucket: BucketName}
    })
    // console.log('UPLOADING FILE!!', file)
    var albumFileKey = encodeURIComponent('prime-images') + '/'
    var fileKey = albumFileKey + Utils.randomString2(3) + '_' + file.name 
    // batchUploadsChannel.subscribe(fileKey, _this.onFileProcessed)
    var params = {Bucket: BucketName, Key: fileKey, Body: file}
    return new Promise((resolve, reject) => {
      s3.upload(params, function (err, data) {
        if (err) {
          // console.log('err', err)
        //   commit(SET_FILE_STATE, 'ERROR')
          reject(err)
          return
        }
        // console.log('Success:', data);
        resolve(data);
      })
    })    
};

import { GET_RESOURCES, SET_COUPONS } from './types';
import { generalService } from '../../_services/generalService';

export const getResources = (filters) => {
    return dispatch => {
        return generalService.get_resources(filters)
        .then(res => {
            // console.log('Merchants:', res.data);
            dispatch({
                type: GET_RESOURCES,
                payload: res.data.data
            });
            return res.data.data;
        }, err => {
          console.log('err', err);
            return err;
        });   
    };
};
export const getFundRequestList = (filters) => {
    return dispatch => {
        return generalService.get_fund_request_list(filters)
        .then(res => {
            console.log('Merchants:', res.data.data);
            // dispatch({
            //     type: GET_RESOURCES,
            //     payload: res.data.data
            // });
            return res.data.data;
        }, err => {
          console.log('err', err);
            return err;
        });   
    };
};
export const request_funds = (params) => {
    return dispatch => {
        return generalService.request_fund_request(params)
        .then(res => {
            console.log('Funds:', res)
            return res;
        }, err => {
            console.log('err', err);
            return err;
        });   
    };
};
export const getBankDetails = () => {
    return dispatch => {
        return generalService.get_bank_details()
        .then(res => {
            console.log('Bank Details:', res.data);
            return res.data.data;
        }, err => {
          console.log('err', err);
            return err;
        });   
    };
};
export const getSupportedBanks = () => {
    return dispatch => {
        return generalService.get_supported_banks()
        .then(res => {
            console.log('Bank List:', res.data);
            return res.data.data;
        }, err => {
          console.log('err', err);
            return err;
        });   
    };
};
export const getMoMoNetworks = () => {
    return dispatch => {
        return generalService.get_supported_momo_networks()
        .then(res => {
            console.log('Momo List:', res.data);
            return res.data.data;
        }, err => {
          console.log('err', err);
            return err;
        });   
    };
};
export const getBankBranch = (branch) => {
    return dispatch => {
        return generalService.get_bank_branch(branch)
        .then(res => {
            console.log('Bank Branch:', res.data);
            return res.data.data;
        }, err => {
          console.log('err', err);
            return err;
        });   
    };
};
export const add_bank_account = (params, type="Add") => {
    return dispatch => {
        return generalService.add_bank_account(params, type)
        .then(res => {
            console.log('Funds:', res)
            return res;
        }, err => {
            console.log('err', err);
            return err;
        });   
    };
};
export const get_coupons = (id, cached=true) => {
    return (dispatch, getState) => {

        // Cached state
        if (cached && getState()?.general?.coupons?.list?.length > 0) {
            return Promise.resolve(getState()?.general?.coupons);
        }

        return generalService.get_coupons(id)
        .then(response => {
            if (!id) {
                dispatch({
                    type: SET_COUPONS,
                    payload: response.data?.data
                });
            }
            return response;
        }, (err) => {
            console.log('err', err);
            return err;
        });
    };
};
export const remove_code = (tag) => {
    return (dispatch) => {
        return generalService.remove_coupon(tag)
        .then(response => {
            return response;
        }, (err) => {
            console.log('err', err);
            return err;
        });
    };
};
export const remove_coupon_code = (tag, params) => {
    return (dispatch) => {
        return generalService.remove_coupon_code(tag, params)
        .then(response => {
            return response;
        }, (err) => {
            console.log('err', err);
            return err;
        });
    };
};
export const addCoupon = (params, type="Add", id) => {
    return (dispatch) => {
        return generalService.add_coupon(params, type, id)
        .then(res => {
            console.log('Funds:', res);
            return res;
        }, err => {
            console.log('err', err);
            return err;
        });   
    };
};

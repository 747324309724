import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, notification } from 'antd';
import background from '../assets/images/success.jpg';
import logo from '../assets/images/logo1.png';

import { useDispatch } from 'react-redux';
import { resent_email_code } from "../store/user.actions";

const Success = () => {
    const dispatch = useDispatch();
    const [loading, setloading] = useState(false);

    const resendCode = () => {
        setloading(true);

        dispatch(resent_email_code())
        .then((res) => {
            setloading(false);
            const { status } = res;

            if (status === 200 || status === 201) {
                notification.success({
                    message: 'Email sent',
                    description: <p>Verification Code email sent successfully</p>,
                    onClick: () => {
                        console.log('Notification Clicked!');
                    }
                });
            }

            if (status === 400 || status === 401 ) {
                notification.error({
                    message: 'Email failed',
                    description: <p>Sending your verification email failed to send, please try again</p>,
                    onClick: () => {
                        console.log('Notification Clicked!');
                    }
                }); 
            }           
        })
    }

    return (
        <section className="w-screen h-screen  bg-contain bg-bottom bg-no-repeat">
            <div className="h-full w-full flex flex-wrap items-center">
                <section className="w-full sm:w-1/3">
                    <div className="pb-20 flex items-end justify-center w-full">
                        <Link to="/">
                            <div className="flex items-end">
                                <img src={logo} className="w-40" alt="prime_logo" />
                                <span className="text-secondary text-3xl font-semibold pb-6 -ml-2">e-gift</span>
                            </div>
                        </Link>
                    </div>
                    <div className="text-primary">
                        <h1 className="text-3xl font-semibold text-center pb-4 text-primary">Congratulations!</h1>
                        <p className="text-sm text-center font-semibold">You are all set to start your e-gift card business</p>
                        <div className="mb-10 w-full flex--basic pt-6">  {/* absolute inset-x-0 bottom-0  */}
                            <Button 
                                type="primary" 
                                size="large"
                                className="w-2/3" 
                                block
                            >
                                <Link to="/signin">
                                    CLICK HERE TO LOGIN
                                </Link>                        
                            </Button>                    
                        </div>
                        {/* <div className="pt-5">
                            <p className="pb-1 text-center text-sm leading-normal sm:px-4">
                                If you haven't received your verification code email, 
                                kindly click below to resend.
                            </p>

                            <div className="flex--basic w-full">
                                <Button
                                    type="default" 
                                    className="w-auto text-sm" 
                                    onClick={resendCode}
                                    loading={loading}
                                >
                                    Resend Verification Code                     
                                </Button>
                            </div>
                        </div> */}
                    </div>
                </section>
                <div className="w-full sm:w-2/3">
                    <img src={background} className="w-10/12 mx-auto sm:ml-0" alt=""/>
                </div>

            </div>
        </section>
    )
}

export default Success;
export const CREATE_CARD = 'CREATE_CARD';
export const GET_CARDS = 'GET_CARDS';
export const SET_CARDS = 'SET_CARDS';
export const REMOVE_CARD = 'REMOVE_CARD';
export const EMPTY_E_CARDS = 'EMPTY_E_CARDS';
export const SET_CURRENT = 'SET_CURRENT';
export const SET_EDIT = 'SET_EDIT';
export const SET_ALL_CARDS = 'SET_ALL_CARDS';
export const RESET_SELECTED = 'RESET_SELECTED';
export const GET_TEMPLATES = 'GET_TEMPLATES';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_ALL_TEMPLATES = 'SET_ALL_TEMPLATES';
export const SET_SELECTED_TEMPLATE = 'SET_SELECTED_TEMPLATE';
export const SET_TEMPLATE_INFO = 'SET_TEMPLATE_INFO';
export const SET_CAMPAIGNS = 'SET_CAMPAIGNS';
export const CHANGE_CARD_STATE = 'CHANGE_CARD_STATE';
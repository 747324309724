import React, { useState, useEffect } from "react";
import moment from "moment";
import { Button, Space, Table, Tag, Tooltip } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { useDispatch } from "react-redux";
import { getFundRequestList } from "../store/general/actions";
import { getAvailableFunds } from "../store/dashboard/actions";

// Components
// import Filter from "../../components/misc/ReportFilter";
import RequestFunds from "../components/RequestFunds";

const FundRequest = () => {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [filters, setfilters] = useState({});
  const [show, setShow] = useState(false);
  const [availableFunds, setAvailableFunds] = useState();
  const [FundRequestList, setFundRequestList] = useState([]);

  const columns = [
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      // render: (record) => <div className="truncate">{record}</div>,
      // width: 100,
      ellipsis: true,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Receipt",
      dataIndex: "receipt",
      key: "receipt",
    },
    {
      title: "Cheque #",
      dataIndex: "cheque_number",
      key: "cheque_number",
    },
    {
      title: "Processed By",
      dataIndex: "processed_by",
      key: "processed_by",
    },
    {
      title: "Processed Method",
      dataIndex: "processing_method",
      key: "processing_method",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      render: (record) => (
        <Tag color="#137359" className="text-xs rounded-full">
          {record}
        </Tag>
      ),
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      render: (record) => (
        <div>{moment(record.created_at).format("MMM Do, YY") || "N/A"}</div>
      ),
    },
    {
      title: "",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <EditOutlined className="cursor-pointer" onClick={() => {}} />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteOutlined onClick={() => {}} />
          </Tooltip>
        </Space>
      ),
      width: 60,
      ellipsis: true,
    },
  ];

  const fetchFundRequest = () => {
    let fundFilters = {
      page: 1,
      limit: 20,
    };

    dispatch(getFundRequestList(fundFilters)).then((res) => {
      console.log("All placed funds request", res?.fund_requests);
      setFundRequestList(res?.fund_requests);
      setloading(false);
    });
  };

  const fetchAvailableFunds = () => {
    // Get dashboard stats data
    setloading(true);
    dispatch(getAvailableFunds(filters)).then(
      (response) => {
        console.log("Available Funds:", response.data.data.available_funds);
        setAvailableFunds(response.data.data.available_funds);
        setloading(false);
      },
      (error) => {
        console.log("Error:", error);
        setloading(false);
      }
    );
  };

  const onChange = () => {};

  const handleSentRequest = () => {
    setShow(false);
    fetchFundRequest();
  };

  useEffect(() => {
    // Fetch all fund request
    fetchFundRequest();
    fetchAvailableFunds();
    return () => {};
  }, []);

  return (
    <section className="w-full">
      {/* <Filter 
                title="Reports"
                showType={false}
                setLocalFilters={setTransactionFilters}
            /> */}
      <div className="withdrawal mt-4 w-full bg-white p-4 rounded-lg">
        {/* withdrawal table goes here */}
        <div className="flex justify-between border-b border-gray-200 mb-2">
          <div className="pb-4 leading-none text-left w-full">
            <div className="font-bold flex--between">
              <h4>
                <b>Available Funds</b>
              </h4>
              <Button
                type="primary"
                shape="round"
                className="bg-primary text-white"
                onClick={() => {
                  setShow(true);
                }}
              >
                Request Funds
              </Button>
            </div>
            <div className="pt-4 leading-none text-left">
              <div className="text-5xl font-bold text-center">
                GHS {availableFunds}
              </div>
            </div>
          </div>
        </div>
        {/* Table */}
        <section>
          <div className="py-4">
            <h4>
              <b>Funds Request History</b>
            </h4>
          </div>
          <Table
            columns={columns}
            dataSource={FundRequestList}
            onChange={onChange}
            loading={loading}
          />
        </section>
      </div>

      {/* Request Funds */}
      <RequestFunds
        show={show}
        setShow={setShow}
        handleSentRequest={handleSentRequest}
      />
    </section>
  );
};

export default FundRequest;

import React, { useState, useEffect } from 'react';
import { EventEmitter } from '../../events.js';
import { Col, Card, Divider, Form, Input, Space, Select, Image, Spin, Switch, Skeleton } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Uploader from '../../components/misc/uploader';
import { HuePicker, 
    // CirclePicker 
} from 'react-color';
// import FontPicker from 'font-picker-react';
import Draggable from 'react-draggable';

// import { connect } from "react-redux";
import { useDispatch, useSelector } from 'react-redux';
import { setTemplateInfo, setcurrent } from "../../store/cards/cards.actions";
import { sendToBucket } from '../../_services/uploader';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
// const placeholder = require('../../assets/images/placeholder.png')
const { Option } = Select;

const EditTemplate = ({
    next=()=>{},
    ...props
}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { templates, selected, current } = useSelector(state => state.cards);
    const [textColor, setTextColor] = useState('#0089ff');
    const [picker, setpicker] = useState('font');
    
    const Ball = ({ color, size, target, onClick, disabled }) => {
        return (
            <div className={`${size ? `w-${size} h-${size}`: 'w-10 h-10'} rounded-full border border-black shadow-lg cursor-pointer flex--basic`}
                style={{
                    backgroundColor: `${target === picker ? '#e7f8da' : color}`
                }}
                onClick={onClick}
                disabled={disabled}
            >
                {target === picker &&
                    <div className="rounded-full w-4 h-4 bg-primary" />
                }
            </div>                
        )
    };

    const PointerHead = () => {
        return (
            <div className="w-4 h-12 border-2 border-white rounded-full"
                style={{ backgroundColor: `${textColor}` }}
            ></div>            
        )
    }
                                       
    const handleInput = (val) => {
        console.log('Form Change', val);
        if (Object.values(val)[0]  === null || Object.values(val)[0] === "other") {
            return;
        } else {
            dispatch(setTemplateInfo(Object.keys(val)[0], Object.values(val)[0]));
        }
    };

    const handleChangeComplete = (color) => {
        setTextColor(color.hex);
        
        switch (picker) {
            case 'background':
            dispatch(setTemplateInfo('background', color.hex));
                break;
            case 'font':
            dispatch(setTemplateInfo('default_color', color.hex));
                break;
        
            default:
            dispatch(setTemplateInfo('default_color', '#fff'));
                break;
        }
    };

    const handlePickerTartet = (target) => {
        console.log('hitting select', target)
        switch (target) {
            case 'background':
            setpicker('background');
                break;
            case 'font':
            setpicker('font');
                break;
        
            default:
            // setpicker('font');
                break;
        }
    };

    const onFinish = async () => {
        try {
            await form.validateFields();

            dispatch(setcurrent(current + 1));
        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        } 
    };  

    const getUploadedImage = (e, type) => {
        console.log('File', e)

        // Option to push file to s3
        sendToBucket(e.file)
        .then((response) => {
            // console.log('Response from upload:', response.Location);
            switch (type) {
                case 'logo':
                    dispatch(setTemplateInfo('logo', response.Location));
                    form.setFieldsValue({ logo: response.Location })
                    break;
                case 'background':
                    dispatch(setTemplateInfo('image', response.Location));
                    form.setFieldsValue({ background: response.Location })
                    break;
            
                default:
                    break;
            }
            
        })
        .catch(err => {
            console.log('Error:', err);
        })
    };

    const handleCategorySelection = (e) => {
        console.log('tags:', e);
        if (e === null) {
            dispatch(setTemplateInfo("category", "custom"));
        }
    };

    // useEffect(() => {
    //     EventEmitter.subscribe('setLogo', (uploadedLogo) => {
    //         dispatch(setTemplateInfo('logo', uploadedLogo));
    //     });
    // }, [dispatch])  
    
    useEffect(() => {
        if (selected.type) dispatch(setTemplateInfo('show_amount', false));
        else dispatch(setTemplateInfo('show_amount', true));

        // Listen to event
        EventEmitter.subscribe('checkEditTemplate', () => {
            console.log('beforming checks for edit template');
            onFinish()
        })

        // dispatch(setTemplateInfo('logo', 'https://primeegiftfiles.s3.us-east-2.amazonaws.com/prime-images/ae0_qrcode_localhost.png'));
        // form.setFieldsValue({ logo: "https://primeegiftfiles.s3.us-east-2.amazonaws.com/prime-images/ae0_qrcode_localhost.png" })        
    }, []);

    useEffect(() => {
        console.log('Selected info:', selected);
        form.setFieldsValue(selected);
        if (selected.image) {
            form.setFieldsValue({ background: selected.image})
        }
        form.setFieldsValue({ category: selected.category})
        console.log('Form Now!!', form.getFieldsValue());
    }, [form, selected]);

    return (
        <>
            <Col span={24} className="pt-4">
                <Card className="rounded-large shadow-lg pb-6">
                    <h2 className="text-xl font-bold text-center text-primary">Edit Template</h2>
                    <div className="flex flex-col items-center w-full mx-auto">
                        <div className="relative w-1/2 h-auto">
                            {/* <Image
                                preview={false}
                                className="w-full rounded-lg"
                                src={selected.image}
                                placeholder={<Skeleton.Avatar className="w-full h-full" active={true} size="large" shape="square" />}
                                style={{maxHeight: '20rem'}}
                            /> */}
                            {selected.image ? 
                                <img 
                                    src={selected.image}
                                    className="w-full rounded-lg object-cover shadow--perfect"
                                    style={{maxHeight: '20rem', minHeight: '20rem'}}
                                    alt=""
                                />                            
                            :
                                <Skeleton.Avatar className="w-full h-full" active={true} size="large" shape="square" />
                            }

                            <div className="absolute top-0 w-full h-full">
                                {selected.show_amount && 
                                    <Draggable>
                                        <div className="absolute top-0 right-0 p-6" 
                                            style={{
                                                color: `${selected.default_color}`,
                                                font_weight: `${selected.font_weight}`,
                                                fontSize: '2rem'
                                            }}>
                                                ¢{selected.amount}
                                        </div>
                                    </Draggable>
                                }

                                {!selected.type &&
                                    <div className={` ${selected.logo_position[0]}-0 ${selected?.logo_position[1].includes("flex--basic", "center") ? 'inset-y-0 flex--basic' : `${selected.logo_position[1]}-0`} absolute p-8`}>
                                        {selected.logo?
                                            // <Spin indicator={antIcon} />
                                            // <img src={selected.logo} className="w-20 mx-auto" alt="" />
                                            <Image
                                                preview={false}
                                                className="w-20 mx-auto flex--basic"
                                                src={selected.logo}
                                                placeholder={
                                                    <Spin indicator={antIcon} />
                                                }
                                            />                                              
                                        :
                                            <div className="w-32 border-dashed border h-12 flex--basic" 
                                                style={{color: `${selected.default_color}`, borderColor: `${selected.default_color}`}}
                                                >
                                                Logo
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>


                        {/* 
                            <div className="bg-cover bg-center bg-top bg-no-repeat w-1/2" 
                                style={{
                                    backgroundImage: `url(${props.selected.img})`,
                                    // width: '38.5%'
                                    height: '20.7rem'
                                }}
                            >
                            </div>  
                        */}

                        <Form
                            name="amount"
                            form={form}
                            layout="vertical" 
                            className="w-full"
                            onValuesChange={handleInput}
                        >
                            <div className="flex items-start justify-between w-full pt-8">
                                <div className="w-1/3 flex items-center justify-end w-full pr-6">
                                
                                    {/* <div className="flex">
                                        <div className="font-bold text-lg">Color Picker</div>
                                        <div className="font-bold text-lg">Solid Color</div>
                                    </div> */}

                                    <div className="w-full text-center">
                                            <div className="font-bold text-primary text-lg pb-4">Move the picker to desired color</div>
                                            <div className="flex--basic w-full pt-3">
                                                <Space size="middle">
                                                    <Ball color="black" onClick={() => {handleChangeComplete({hex: 'black'})}} size="10"  />
                                                    <HuePicker 
                                                        height="30px"
                                                        color={textColor}
                                                        // onChangeComplete={handleChangeComplete}
                                                        onChange={handleChangeComplete}
                                                        pointer={ PointerHead }
                                                    />
                                                    <Ball color="white" onClick={() => {handleChangeComplete({hex: 'white'})}} size="10"  />
                                                </Space>
                                            </div>
                                    </div>
                                </div>

                                <div className="w-1/3 flex items-center w-full text-center ml-6">

                                        <Form.Item 
                                            name="title" 
                                            label={<div className="font-bold text-primary text-lg">Card Title</div>} 
                                            className="font-bold text-sm text-center w-full m-0"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please provide a title for the card",
                                                }
                                            ]}                                            
                                        >
                                            <Input 
                                                size="large" 
                                                className="rounded-lg mt-4" 
                                                placeholder="title"
                                            ></Input>
                                        </Form.Item>
                                                                
                                </div>
                                <div className="w-1/3 flex items-center w-full text-center ml-6">

                                        <Form.Item name="amount" 
                                            label={
                                                <div className="flex--basic font-bold text-primary text-lg">
                                                    Amount
                                                    <Switch 
                                                        onChange={() => {dispatch(setTemplateInfo('show_amount', !selected.show_amount))}} 
                                                        className="ml-4 text-xs" 
                                                        checkedChildren="Hide" 
                                                        unCheckedChildren="Show"  
                                                        checked={selected.show_amount}
                                                    />              
                                                </div>
                                            } 
                                            className="font-bold text-sm text-center w-4/5 m-0"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please provide an amount for the card",
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(rule, value) {
                                                    if (value >= 25) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject('Minimum card price should be GHS 25.00');
                                                    },
                                                })                                                
                                            ]}                                            
                                            >
                                            <Input 
                                                size="large" 
                                                className="rounded-lg mt-4" 
                                                placeholder="¢0.00"
                                            ></Input>

                                        </Form.Item>
                                </div>
                            </div>
        
                            <Divider className="border-black" />

                            <div className="flex items-start w-full">
                                <div className="w-full sm:w-1/2 flex items-start justify-center pr-6">
                                    <div className="flex flex-col items-stretch text-center mr-8">
                                        <div className="font-bold text-lg text-primary">Background</div>
                                        <div className="flex--basic pt-12">
                                            <Ball 
                                                disabled={selected.disable_image_upload}
                                                color="white" 
                                                target="background"
                                                onClick={() => { handlePickerTartet('background')}} 
                                                size="10"  
                                            />
                                        </div>
                                        <div className="font-bold text-lg pt-4">Solid</div>
                                    </div>
                                    <div className="flex flex-col items-stretch text-center mr-8">
                                        <div className="font-bold text-lg text-primary">Font</div>
                                        <div className="flex--basic pt-12">
                                            <Ball color="white" size="10"
                                                target="font"
                                                onClick={() => { handlePickerTartet('font')}}                                             
                                            />
                                        </div>
                                        <div className="font-bold text-lg pt-4">Font</div>
                                    </div>
                                    
                                    {selected.type && 
                                        <div className="flex flex-col items-stretch filter-form">
                                            <div className="font-bold text-lg text-center">Category</div>
                                            <Form.Item name="card_category_id" label="" className="flex--basic pt-12 mb-2 category"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Kindly select category for the card",
                                                    }
                                                ]}                                            
                                            >
                                                <Select
                                                    // mode="tags"
                                                    placeholder="Birthday, Christmas, Valentine, ..."
                                                    className="rounded bg-white w-56 py-1"
                                                    onChange={handleCategorySelection}
                                                >
                                                    {templates.map((template) => (
                                                        <Option value={template.cat_id ? template.cat_id : template.category} key={template.cat_id}>{template.category}</Option>
                                                    ))}
                                                    {/* <Option value="9999">Other</Option> */}
                                                </Select>
                                            </Form.Item>

                                            {form.getFieldValue("card_category_id") === 30 && (
                                                <Form.Item name="category" label="" className="flex--basic mb-2 category"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Kindly provide your category name",
                                                        }
                                                    ]}                                                
                                                >
                                                    <Input
                                                        size="medium" 
                                                        className="rounded w-56" 
                                                        placeholder="Enter your category name"
                                                        onChange={(e) => { 
                                                            dispatch(setTemplateInfo("category", e.target.value));
                                                        }}
                                                    ></Input>
                                                </Form.Item>
                                            )}
                                            {/* <span className="text-xs text-center">Select at most one category or if option not availabe, enter your category</span> */}
                                        </div>
                                    }
                                </div>
                                <div className="w-full sm:w-1/2 flex items-start text-center ml-6">
                                    <div className="flex flex-col items-stretch mr-4">
                                        <div className="font-bold text-lg text-primary">Logo</div>
                                        <div className="flex--basic flex-col pt-2">
                                            <Form.Item name="logo" label="" className="font-bold"
                                                // rules={[
                                                //     {
                                                //         required: selected.type ? false : true,
                                                //         // required: false,
                                                //         message: "Please upload a logo for this card",
                                                //     }
                                                // ]}                                            
                                            >
                                                <Uploader 
                                                    disabled={selected.type}
                                                    uploadText="Upload Logo"
                                                    uploadImage={(e) => { getUploadedImage(e, 'logo') }}
                                                    classStyle={selected.logo && "my-1 bg-cover bg-no-repeat bg-contain bg-center h-32"}
                                                    bg={selected.logo}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>                                  
                                    <div className={`flex flex-col items-stretch`}>
                                        <div className="font-bold text-lg text-primary">Image</div>
                                        <div className="flex--basic pt-2">
                                            <Form.Item name="background" label=""
                                                rules={[
                                                    {
                                                        required: selected.disable_image_upload ? false : true,
                                                        message: "Please upload a background image for this card",
                                                    }
                                                ]}                                            
                                            >
                                                <Uploader
                                                    disabled={selected.disable_image_upload}
                                                    uploadImage={(e) => { getUploadedImage(e, 'background') }}                                        
                                                    uploadText="Upload Card Background"
                                                    classStyle={!selected.disable_image_upload && "my-1 bg-cover bg-no-repeat bg-center h-36 w-60"}
                                                    bg={!selected.disable_image_upload && selected.image}                                            
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>                                  
                                </div>
                            </div>

                            {/* <Divider className="border-black" />

                            <div className="text-center pb-6">
                                <div className="flex--basic pb-4">
                                    <Space direction="vertical" size="small">
                                        <h1>Colors on Card</h1>
                                        <p>Select color to change color picker</p>
                                    </Space>
                                </div>
                                <div className="flex--basic">
                                    <Space>
                                        <CirclePicker colors={colors} onChange={(color) => {handleChangeComplete(color)}} />
                                    </Space>
                                </div>
                            </div>

                            <Divider className="border-black" /> */}
                            
                            {/* <div className="w-full pt-4">
                                <Form 
                                    name="amount" 
                                    layout="inline" 
                                    // onValuesChange={handleOnAmountChange}
                                    onFinish={onFinish}>
                                    <Form.Item name="amount" label="Amount" className="w-2/5" valuePropName="amount">
                                        <Input size="large" className="rounded-lg" placeholder="¢0.00"
                                            onChange={(e) => {handleOnAmountChange(e.target.value)}}
                                        ></Input>
                                    </Form.Item>
                                </Form>                            
                            </div> */}

                        </Form>  
                    </div>
                </Card>
            </Col>
        </>        
    )
};


export default EditTemplate;
import React, {useState } from 'react';
import { Link,  useHistory } from 'react-router-dom';
import { Form, Input, Button, message, notification } from 'antd';

// Images
import logo from '../assets/images/logo.svg';

// Redux Actions
import { useDispatch } from 'react-redux';
import { reset_password } from "../store/user.actions";

const ResetPassword = () => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const history = useHistory();
	const [formData, setFormData] = useState({
        email: "",
        password: "",
        confirmed_password: ""
	});
    const [loading, setloading] = useState(false);	

	const onFinish = () => {
		setloading(true);

		try {
			form.validateFields()
			
			let data = {
				password: formData.password,
				token: getQueryParams().token
			};

			dispatch(reset_password(data))
			.then((response) => {
				if (response.data?.success) {
					notification.success({
						message: 'Password reset successfully'
					});
					history.push('/signin');
				} else {
					switch (response.data?.status) {
						case 400:
							notification.error({
								message: response.data.error_msg
							});
						break;
					
						default:
						break;
					};
				}

				setloading(false);
			})		
		} catch (error) {
			message.error('Error setting password')
			setloading(false);
		}		
    };
    
	const handleInput = (val) => {
		setFormData({
			...formData,
			[Object.keys(val)[0]]: Object.values(val)[0]
		});
	};

	// eslint-disable-next-line no-sequences
	const getQueryParams = () => window.location.search.replace('?', '').split('&').reduce((r,e) => (r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1]), r), {});

	return (
        <div className="h-screen w-screen bg-white">
            <div className="h-8 sm:h-12 mb-3 bg-primary w-full" />
			<div className="flex flex-col items-center justify-center w-full">
				<div className="pb-4 flex items-end">
                    <Link to="/">
						<img src={logo} className="w-24" alt="prime_logo" />
					</Link>
					<span className="text-gray-500 font-bold font-sm pb-3 -ml-3">e-gift <br/>card</span>
				</div>
				<div className="py-4">
					<h1 className="text-lg m-0 text-primary font-thin uppercase tracking-wide leading-snug">Merchant Dashboard</h1>
					<div className="text-xs text-center text-primary pb-4">Your eGift Card Platform</div>
				</div>
				<div className="w-1/4 bg-white rounded-large px-8 py-6 text-center shadow-2xl">
					<div className="text-base pb-8 relative leading-normal">
                       <span>
                            Reset Password
                        </span>
					</div>
					<Form
						name="custom"
						form={form}
						className="w-full signup"
						layout="vertical"
						onFinish={onFinish}
						onValuesChange={handleInput}
					>
                            <>
								<Form.Item  
									name="password"
									rules={[
										{
											required: true,
											message: 'Please input your new password',
										}								
									]}
								>
									<Input.Password
										placeholder="New Password"
										type="password"
										className="tracking-wider"
									/>
								</Form.Item>
								<Form.Item  
									name="confirmed_password" 
									dependencies={["password"]}
									rules={[
										{
											required: true,
											message: 'Please confirm your password',
										},
										({ getFieldValue }) => ({
											validator(rule, value) {
											if (!value || getFieldValue('password') === value) {
												return Promise.resolve();
											}
											return Promise.reject('The two passwords do not match!');
											},
										})															
									]}
								>
									<Input.Password
										placeholder="Confirm Password"
										type="password"
									/>
								</Form.Item>
                            </>
                        
						<Form.Item className="pt-6">
							<Button
								htmlType="submit"
								type="primary"
								className="bg-primary w-full uppercase font-semibold"
								shape="round"
								size="large"
								loading={loading}
							>
                                Reset Password
							</Button>
						</Form.Item> 
					</Form>
					<div className="flex--basic pt-2">
						<Link to="/">
							<span className="px-2 hover:underline">Welcome</span>
						</Link>
						|
						<Link to="/signin">
							<span className="px-2 hover:underline">Signin</span>
						</Link>		
					</div>
				</div>
			</div>
		</div>
	)
};

export default ResetPassword;
import { config } from '../redux/config';
import { authHeader } from '../_helpers/auth-header';
import axios from 'axios';
import utils from '../utils/services'

export const dashboardService = {
    getMainStats,
    get_monthly_sales,
    get_sales_overview,
    get_card_sales_overview,
    get_available_funds
};

function getMainStats(filters) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let query = utils.createQueryFromObject(filters);
    let url = `${config.apiUrl}/merchants/dashboard/general-stats`;
    if (query) url = url + `?${query}`

    return new Promise((resolve, reject) => {
        axios(url, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}
function get_monthly_sales(filters) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let query = utils.createQueryFromObject(filters);
    let url = `${config.apiUrl}/merchants/dashboard/monthly-totals?for=card_purchase`;
    if (query) url = url + `&${query}`

    return new Promise((resolve, reject) => {
        axios(url, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}
function get_sales_overview(filters) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let query = utils.createQueryFromObject(filters);
    let url = `${config.apiUrl}/merchants/dashboard/sales-overview/summary`;
    if (query) url = url + `?${query}`

    return new Promise((resolve, reject) => {
        axios(url, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}
function get_card_sales_overview(filters) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let query = utils.createQueryFromObject(filters);
    let url = `${config.apiUrl}/merchants/dashboard/sales-overview/cards`;
    if (query) url = url + `?${query}`

    return new Promise((resolve, reject) => {
        axios(url, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}
function get_available_funds(filters) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let query = utils.createQueryFromObject(filters);
    let url = `${config.apiUrl}/merchants/dashboard/available-funds`;
    if (query) url = url + `?${query}`

    return new Promise((resolve, reject) => {
        axios(url, requestOptions)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
}

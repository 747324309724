import { SET_CARDS, SET_CATEGORIES, SET_CURRENT, SET_CAMPAIGNS, SET_ALL_CARDS, SET_ALL_TEMPLATES, CHANGE_CARD_STATE,
RESET_SELECTED, GET_TEMPLATES, SET_SELECTED_TEMPLATE, SET_TEMPLATE_INFO, SET_EDIT, REMOVE_CARD, EMPTY_E_CARDS
} from './cards.types';
import { cardService } from '../../_services/cardService';

export const getTemplates = () => {
    return {
        type: GET_TEMPLATES
    };
};

export const createCard = (info, mode) => {
    return dispatch => {
        return cardService.createCard(info, mode)
        .then(res => {
            console.log('Card payload:', res);
            // dispatch(setCards(res.response.data))
            return res;
        }, err => {
          console.log('err', err.response.data);
            // dispatch(addSignupFailure(err.response.data));
            return err.response.data;
        });   
    };
};
export const publishCardList = (CardList, mode, ID) => {
    return dispatch => {
        return cardService.publishMultipleCards(CardList, mode, ID)
        .then(res => {
            console.log('Card payload:', res);
            return res;
        }, err => {
          console.log('err', err.response?.data);
            return err.response?.data;
        });   
    };
};

export const getCard = (filters) => {
    return dispatch => {
        return cardService.getCards(filters)
        .then(res => {
            console.log('All Cards:', res.data.data.cards);
            dispatch(setCreatedCards(res.data.data.cards))
            return res;
        }, err => {
        //   console.log('err', err.response.data);
            // dispatch(addSignupFailure(err.response.data));
            if (err.response !== undefined) {
                return err.response.data;
            } else return err;
        });   
    };
};

export const listCardCategories = () => {
    return dispatch => {
        return cardService.listCategories()
        .then(res => {
            console.log('All Categories:', res.data.data.cards);
            return res;
        }, err => {
            if (err.response !== undefined) {
                return err.response.data;
            } else return err;
        });
    };
};

export const getGiveBackCampaigns = () => {
    return dispatch => {
        return cardService.getGiveBackCampaigns()
        .then(res => {
            console.log('All Campaigns:', res.data.data.campaigns);
            dispatch({
                type: SET_CAMPAIGNS,
                payload: res.data.data.campaigns
            });            
            return res;
        }, err => {
            if (err.response !== undefined) {
                return err.response.data;
            } else return err;
        });   
    };
};

export const fetchAllTemplates = () => {
    return dispatch => {
        return cardService.fetchAllTemplates()
        .then(res => {            
            const templates = [
                {
                    // cat_id: 9999,
                    category: "Custom",
                    gallery: [
                        {
                            id: 1,
                            amount: "0",
                            picture: "",
                            selected: false,
                            logo: "",
                            default_color: '#fff',
                            font_weight: 'normal',
                            disable_image_upload: true,
                            logo_position: ['bottom', 'right'],
                            is_promotion: null,
                            promo_type: null,
                            promotion_title: null,
                            promotion_start: null,
                            expiry_date: null,
                            promo_description: null,
                            promo_terms: null             
                        }
                    ]        
                }                
            ];

            res.data.data.templates.forEach(temp => {
                templates.push(temp);
            });

            dispatch(setAllTemplates(templates))
            // dispatch(setAllTemplates(res.data.data.templates))

            return res;
        }, err => {
          console.log('err', err);
            return err;
        });   
    };
};

export const setCreatedCards = (info) => {
    return {
        type: SET_ALL_CARDS,
        payload: info
    }
};

export const setAllTemplates = (templates) => {
    return {
        type: SET_ALL_TEMPLATES,
        payload: templates
    }
};

export const setCards = (info) => {
    return dispatch => {
        return new Promise((resolve) => {
            dispatch({
                type: SET_CARDS,
                payload: info
            })
            resolve(info)
        })        
    }    
};

export const deleteCard = (id, ID) => {
    console.log('Card ID:', id);
    return dispatch => {
        return cardService.deleteCard(id, ID)
        .then((res) => {
            console.log('Res:', res);
            dispatch({
                type: REMOVE_CARD,
                payload: id
            })            
        }, (err) => {
            console.log('Error:', err);
            dispatch({
                type: REMOVE_CARD,
                payload: id
            })            
        })
    }    
};

export const changeCardState = (id, ID, cardState) => {
    console.log('Card ID:', id);
    return dispatch => {
        return cardService.changeChangeCardStateardState(id, ID, cardState)
        .then((res) => {
            console.log('Res:', res);
            dispatch({
                type: CHANGE_CARD_STATE,
                payload: id
            })            
        }, (err) => {
            console.log('Error:', err);
            dispatch({
                type: CHANGE_CARD_STATE,
                payload: id
            })            
        })
    }    
};

export const emtpySavedCards = () => {
    return dispatch => {
        return new Promise((resolve) => {
            dispatch({
                type: EMPTY_E_CARDS,
            })
            resolve(true)
        })        
    }
}

export const resetSelected = () => {
    return {
        type: RESET_SELECTED
    }
};

export const setSetlectedTemplate = (template, category) => {
    return dispatch => {
        return new Promise((resolve) => {
            dispatch({
                type: SET_SELECTED_TEMPLATE,
                payload: { template, category }
            })
            resolve(true)
        })        
    }
};

export const setTemplateInfo = (type, data) => {    
    return {
        type: SET_TEMPLATE_INFO,
        payload: { type, data }
    }
};

export const setcurrent = (data) => { 
    return dispatch => {
        dispatch({
            type: SET_CURRENT,
            payload: data
        })
    }
}

export const forceCardEdit = (data) => { 
    return dispatch => {
        return new Promise((resolve) => {
            dispatch({
                type: SET_EDIT,
                payload: data
            })
            resolve(true)
        })
    }
}

export const getAllCategories = (type, data) => {    
    return {
        type: SET_CATEGORIES,
        payload: { type, data }
    }
};

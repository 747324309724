import React, { useState, useEffect } from 'react';
import { Chart } from '@antv/g2';

export default function Overview({ data=[] }) {
    const [chartIns, setchartIns] = useState();

    const plotGraph = (chart) => {
        // Step 1:
        chart.clear();
        // Step 2: 
        chart.data(data);
    
        // Step 3：
        chart.line().position('month*sale').tooltip('month*sale').color('color').shape('smooth');
        
        // chart.legend({ position: 'top'});
        chart.animate('wave-in');
    
        // Step 4:
        chart.render();        
    };

    useEffect(() => {
        const chart = new Chart({
          container: 'c1',
          width: 500,
          height: 300, 
        });

        setchartIns(chart)
        plotGraph(chart)
    }, []);

    useEffect(() => {
        if (data.length > 0) {
            plotGraph(chartIns)
        }
    }, [chartIns, data]);

    return(
        <>
            <div id="c1" 
                style={{
                    width: 500,
                    height: 300, 
                }}
            />
        </>
    )
};